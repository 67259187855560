import * as React from "react";
import styled, { css } from "styled-components";
import { Theme } from "..";
import fonts from "../../styles/theme/fonts";
import { customStyle } from "../../styles/theme/themeHelper";

export const AppLabelStyle = (props: Props) => css`
  opacity: ${props.theme.logo ? 1 : 0};
  transition: opacity 0.5s ease-in-out;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin: 0;
  padding: 0;
  display: block;
  font-size: ${props.fontSize + "px"};
  line-height: ${props.lineHeight + "px"};
  color: ${props.color};
  font-weight: ${props.fontWeight};
  text-overflow: ellipsis;
  white-space: nowrap;
  ${customStyle(props.theme)}
`;

export const allowedOverflowLabel: React.CSSProperties = {
  overflow: "unset",
  overflowWrap: "break-word",
  textOverflow: "unset",
  whiteSpace: "pre-wrap",
};

export interface AppLabelProps extends React.ParamHTMLAttributes<any> {
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: number;
}

type Props = AppLabelProps & { theme: Theme };

export const AppLabel = styled.label<Props>`
  ${(props) =>
    AppLabelStyle({
      color: props.color || props.theme.body.font.secondaryTextColor,
      fontSize: fonts.paragraph2.size,
      fontWeight: fonts.paragraph2.weight,
      lineHeight: fonts.paragraph2.height,
      ...props,
    })};
`;
