import { useState } from "react";
import { i18n } from "../../i18n/i18n";

type SetPreferredLanguageFn = (
  availableLanguages: string[],
  currentLanguage?: string,
  queryParamLang?: string,
  callback?: (lang?: string, showDropdown?: boolean) => void
) => void;

export interface UseLanguage {
  language?: string;
  forceLanguage?: boolean;
  setLanguage?: (lang: string) => void;
  setPreferredLanguage?: SetPreferredLanguageFn;
}

export const useLanguage = (): UseLanguage => {
  const [language, setLanguage] = useState<string>();
  const [forceLanguage, setForceLanguage] = useState<boolean>(false);

  const setPreferredLanguage: SetPreferredLanguageFn = (
    availableLanguages,
    currentLanguage,
    queryParamLang,
    callback
  ) => {
    let preselectedLanguage;

    if (queryParamLang && queryParamLang.length > 0) {
      preselectedLanguage = availableLanguages.find(
        (availableLang) =>
          availableLang.toLowerCase() === queryParamLang.toLowerCase()
      );
      if (preselectedLanguage) {
        if (preselectedLanguage !== currentLanguage) {
          setLanguage(preselectedLanguage);
          if (callback) {
            callback(preselectedLanguage);
            setForceLanguage(true);
          }
        }

        if (callback) {
          callback(undefined, false);
        }
      }
    }

    if (!preselectedLanguage) {
      const defaultLanguage = i18n.getDefaultLanguage(availableLanguages);
      if (defaultLanguage !== currentLanguage) {
        if (callback) {
          setLanguage(defaultLanguage);
          callback(defaultLanguage);
        }
      }
    }

    if (callback && availableLanguages.length === 1) {
      callback(undefined, false);
    }
  };

  return { language, forceLanguage, setPreferredLanguage, setLanguage };
};
