import * as React from "react";
import { Input, Label } from "reactstrap";
import { styled } from "../../styles/theme/themeHelper";
import { getHtmlValue } from "../../utils/getHtmlValue";
import { isKey, KeyboardKeys } from "../../utils/keyChecker";
import { AppLink } from "./appLink";
import { allowedOverflow, AppParagraph2, AppSpan2 } from "./appParagraph";

const CheckMark = styled.span`
  &:after {
    content: "";
    position: absolute;
    display: none;
  }
`;

const StyledLabel = styled(Label)`
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked {
      ~ .checkmark {
        background-color: ${(props) =>
          props.theme.body.buttons.mainBackgroundColor};
        border-radius: 5px;

        &:after {
          display: block;
        }
      }
    }
  }

  p {
    line-height: 24px;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid #979494;
    border-radius: 5px;

    &:after {
      left: 8px;
      top: 3px;
      width: 7px;
      height: 12px;
      border: solid ${(props) => props.theme.body.buttons.mainTextColor};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &.is-invalid {
    color: #dc3545;
  }
`;

export interface CheckboxInputProps {
  id: string;
  name: string;
  optionText: string;
  optionUrl?: string;
  value?: string;
  checked?: boolean;
  isValid: boolean;
  onClick: (event: any) => void;
  htmlParse?: boolean;
  disabled?: boolean;
  disableAriaLabelledBy?: boolean;
  isRequired?: boolean;
}

type Props = React.HTMLAttributes<HTMLLabelElement> & CheckboxInputProps;

export const AppCheckboxInput: React.FunctionComponent<Props> = ({
  id,
  name,
  optionText,
  optionUrl,
  value,
  checked,
  isValid,
  onClick,
  className,
  htmlParse,
  disabled = false,
  disableAriaLabelledBy,
  isRequired = false,
  ...props
}: Props) => {
  const inputRef = React.useRef<HTMLSpanElement>(null);
  const text = getHtmlValue(optionText, htmlParse);

  const onKeyPress = (e: KeyboardEvent) => {
    if (isKey(e, KeyboardKeys.SPACE)) {
      e.preventDefault();
      (inputRef.current as HTMLSpanElement).click();
    }
  };

  const renderWithoutUrl = () => {
    return isValid ? (
      <AppSpan2 style={allowedOverflow} id={`${id}_label`}>
        {text}
      </AppSpan2>
    ) : (
      <AppParagraph2
        style={allowedOverflow}
        color={"#dc3545"}
        id={`${id}_label`}
      >
        {text}
      </AppParagraph2>
    );
  };

  const renderWithUrl = () => {
    return isValid ? (
      <AppLink
        href={optionUrl}
        fontSize={14}
        target={"_blank"}
        tabIndex={0}
        role="link"
        id={`${id}_label`}
      >
        {text}
      </AppLink>
    ) : (
      <AppLink
        href={optionUrl}
        fontSize={14}
        target={"_blank"}
        color={"#dc3545"}
        tabIndex={0}
        role="link"
        id={`${id}_label`}
      >
        {text}
      </AppLink>
    );
  };

  return (
    <StyledLabel
      check
      className={`${className || ""} container ${!isValid ? "is-invalid" : ""}`}
      tabIndex={0}
      for={id}
      onKeyPress={(e: any) => onKeyPress(e)}
      disabled={disabled}
      {...(!disableAriaLabelledBy ? { "aria-labelledby": id } : {})}
      {...props}
    >
      <Input
        id={id}
        name={name}
        value={value}
        type={"checkbox"}
        defaultChecked={!!checked}
        onClick={onClick}
        tabIndex={-1}
        disabled={disabled}
        aria-required={isRequired}
      />
      {optionUrl ? renderWithUrl() : renderWithoutUrl()}
      <CheckMark
        ref={inputRef}
        className={"checkmark"}
        tabIndex={-1}
      ></CheckMark>
    </StyledLabel>
  );
};
