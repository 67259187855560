import * as styledComponents from "styled-components";
import { Theme } from "../../shared";
import chroma from "chroma-js";
import colors from "./colors";
import { fontMediumWeight, fontRegularWeight } from "./fonts";

export const getDefaultColor = (color = "null", defaultColor: string) => {
  if (color !== "null") {
    return color;
  }
  return defaultColor;
};

export const customStyle = (theme: Theme) => `&.color-text1 {
  color: ${theme.body.font.mainTextColor};
}
&.color-text2 {
  color: ${theme.body.font.secondaryTextColor};
}
&.color-text3 {
  color: ${getColorContrast(theme.body.font.secondaryTextColor, 0.5)};
}
&.color-text4 {
  color: ${getDefaultColor(
    theme.body.buttons.productIconTextColor,
    theme.body.font.mainTextColor
  )};
}
&.color-text5 {
  color: ${getColorContrast(
    getDefaultColor(
      theme.body.buttons.productIconTextColor,
      theme.body.font.mainTextColor
    )
  )};
}
&.color-text6 {
  color: ${getColorContrast(
    getDefaultColor(
      theme.body.buttons.productSecondaryTextColor,
      theme.body.font.mainTextColor
    )
  )};
}
&.error {
  color: ${colors.danger};
}
&.font-regular {
  font-weight: ${fontRegularWeight};
}
&.font-medium {
  font-weight: ${fontMediumWeight};
}`;

export const getColorContrast = (
  color = "null",
  alpha?: number,
  strength?: number
) => {
  const colorToUse = !color || color === "null" ? colors.background1 : color;

  const chromaColor =
    chroma(colorToUse).get("lab.l") < 80
      ? chroma(colorToUse).brighten(strength || 1)
      : chroma(colorToUse).darken(strength || 1);

  if (alpha !== undefined) {
    return chromaColor.alpha(alpha).hex();
  }
  return chromaColor.hex();
};

// Unfortunately the default styled typings are returning the type <any> for child fields and below
// The themed version of the styled class solves these typings issues when declaring styled components
// Other Themed interfaces can be exported later if required
export const {
  default: styled,
  createGlobalStyle,
  css,
  withTheme,
} = styledComponents as unknown as styledComponents.ThemedStyledComponentsModule<Theme>;
