import {
  AnswerType,
  MultipleChoiceAnswer,
  Questions,
  QuestionsResponse,
  Reservation,
  TranslatedQuestions,
} from "../../shared";
import { ApplicationState } from "../../store";
import { getCurrentLanguage } from "../layout/selectors";
import { DeleteStatus, SelectedFilesCount, UploadStatus } from "./types";

const getQuestions = (state: ApplicationState) => {
  return state.api.get("QUESTIONS");
};

const getBooking = (state: ApplicationState) => {
  return state.api.get("BOOKING");
};

export const isLoadingQuestions = (
  state: ApplicationState,
  defaultValue?: boolean
) => {
  if (!getQuestions(state)) {
    return defaultValue !== undefined ? defaultValue : !getBooking(state);
  }

  return getQuestions(state).get("isLoading");
};

export const getResponse = (state: ApplicationState) => {
  if (!getQuestions(state) || !getQuestions(state).get("payload")) {
    return;
  }

  return getQuestions(state).getIn(["payload", "response", "questions"]);
};

export const getSelectedProductId = (state: ApplicationState) => {
  return state.api.getIn(["SINGLE_PRODUCT", "payload", "response", "id"]);
};

export const getSelectedVenueId = (state: ApplicationState) => {
  return state.api.getIn(["SINGLE_VENUE", "payload", "response", "id"]);
};

export const getBookingResponse = (
  state: ApplicationState
): Reservation | void => {
  if (!getBooking(state) || !getBooking(state).get("payload")) {
    return;
  }

  return getBooking(state).getIn(["payload", "response"]);
};

const getQuestionTranslations = (
  questionResponse: QuestionsResponse,
  language: string
): string[] =>
  questionResponse.questionTranslations
    .filter((qTranslation) => qTranslation.languageCode === language)
    .map((question) => question.translation);

const getAnswerTranslations = (
  multipleChoiceAnswer: MultipleChoiceAnswer,
  language: string
): string[] =>
  multipleChoiceAnswer.answerTranslations
    .filter((aTranslation) => aTranslation.languageCode === language)
    .map((answer) => answer.translation);

export const getTranslatedQuestionsAndAnswers = (
  questionsResponse: QuestionsResponse[],
  language: string
): TranslatedQuestions[] => {
  return questionsResponse.reduce(
    (accumulatedQuestions: Questions[], currentQuestion: QuestionsResponse) => {
      const questionTranslations: string[] =
        currentQuestion.questionTranslations === null
          ? []
          : getQuestionTranslations(currentQuestion, language);

      if (questionTranslations.length === 0) {
        questionTranslations.push(currentQuestion.questionText);
      }

      const multipleChoiceAnswers: any[] =
        currentQuestion.multipleChoiceAnswers.reduce(
          (accumulatedAnswers: any[], currentAnswer: MultipleChoiceAnswer) => {
            const answerTranslations: string[] =
              currentAnswer.answerTranslations === null
                ? []
                : getAnswerTranslations(currentAnswer, language);

            if (answerTranslations.length === 0) {
              answerTranslations.push(currentAnswer.text);
            }

            if (currentQuestion.answerType === AnswerType.CheckBox) {
              return [
                ...accumulatedAnswers,
                {
                  name: answerTranslations[0],
                  fileUrl: currentAnswer.fileUrl || "",
                  thumbnailUrl: currentAnswer.thumbnailUrl || "",
                },
              ];
            } else {
              return [...accumulatedAnswers, ...answerTranslations];
            }
          },
          []
        );

      return [
        ...accumulatedQuestions,
        {
          ...currentQuestion,
          questionOptions: multipleChoiceAnswers,
          questionText: questionTranslations[0],
          checkBoxOptions:
            currentQuestion.answerType === AnswerType.CheckBox
              ? multipleChoiceAnswers
              : undefined,
        },
      ];
    },
    []
  );
};

export const getTranslatedQuestions = (
  state: ApplicationState
): Questions[] => {
  const language = getCurrentLanguage(state);
  const questionsResponse: QuestionsResponse[] = isLoadingQuestions(state)
    ? []
    : getResponse(state);
  return getTranslatedQuestionsAndAnswers(questionsResponse, language);
};

export const getSelectedFilesCount = (
  state: ApplicationState
): SelectedFilesCount[] => {
  if (state.upload && state.upload.questions) {
    return Object.keys(state.upload.questions).reduce(
      (acc, current: string) => {
        const successfullyUploadedCount = state.upload.questions[
          current
        ].filter((file) => file.uploadStatus === UploadStatus.SUCCESS).length;
        return [...acc, { id: current, count: successfullyUploadedCount }];
      },
      [] as SelectedFilesCount[]
    );
  }
  return [];
};

export const getFileId = (
  state: ApplicationState,
  questionId: string,
  index: number
) => {
  const question = state.upload.questions[questionId];
  const attachment = question && question[index];
  return (
    attachment &&
    ((attachment.presignedUrl && attachment.presignedUrl.id) ||
      attachment.file.id)
  );
};

export const getInProgressArray = (state: ApplicationState): number[] => {
  if (state.upload && state.upload.questions) {
    return Object.keys(state.upload.questions).reduce(
      (acc, current: string) => {
        const inProgressCount = state.upload.questions[current].filter(
          (file) =>
            file.uploadStatus === UploadStatus.IN_PROGRESS ||
            file.deleteStatus === DeleteStatus.IN_PROGRESS
        ).length;
        return inProgressCount > 0 ? [...acc, inProgressCount] : acc;
      },
      [] as number[]
    );
  }
  return [];
};

export const getLoading = (state: ApplicationState): boolean => {
  return state.upload.loading || getInProgressArray(state).length > 0;
};
