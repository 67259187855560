import React from "react";
import { Advisor, AnyAdvisorIcon } from "../../../shared";
import colors from "../../../styles/theme/colors";
import Icons from "../../../styles/theme/icons";
import { styled } from "../../../styles/theme/themeHelper";

export interface AdvisorPortraitProps {
  id?: string;
  advisor: Advisor;
  size: number;
}

const StyledPortrait = styled.div<{
  showBorder?: boolean;
  size: number;
  overFlow?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.size}px;
  width: ${(props) => props.size}px;
  border-radius: 50%;

  ${(props) => props.overFlow && `overflow: hidden;`}

  ${(props) =>
    props.showBorder &&
    `
    border: solid 1px ${colors.text3};
  `}

  > img {
    background-size: cover;
    background-position: center center;
    height: ${(props) => props.size}px;
  }

  > object {
    width: 100%;
  }
`;

type Props = AdvisorPortraitProps;

export const AdvisorPortrait: React.FunctionComponent<Props> = ({
  id,
  advisor,
  size,
}: Props) => {
  const advisorHasPhoto = advisor.photo !== null;

  const noPreferenceIcon = React.useMemo(() => {
    return (
      <StyledPortrait size={size}>
        <AnyAdvisorIcon aria-hidden={true} size={size} />
      </StyledPortrait>
    );
  }, [advisor.id]);

  if (advisor.id === "??") {
    return noPreferenceIcon;
  }

  return (
    <StyledPortrait
      size={size}
      showBorder={advisorHasPhoto}
      overFlow={advisorHasPhoto}
    >
      {advisor.photo ? (
        <img id={id} src={`data:image;base64, ${advisor.photo}`} alt="" />
      ) : (
        <img id={id} src={Icons.advisor} alt="" />
      )}
    </StyledPortrait>
  );
};
