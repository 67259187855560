import { Map } from "immutable";
import { Reducer } from "redux";
import { CLEAR_COORDINATES } from "../layout/types";
import { SET_COORDINATES } from "./types";

export const initialState = Map({
  coordinates: Map({})
});

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COORDINATES:
      state = state.set("coordinates", Map(action.coordinates));
      return state;
    case CLEAR_COORDINATES:
      return initialState;
    default: {
      return state;
    }
  }
};

export { reducer as coordinatesReducer };
