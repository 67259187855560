import {
  API_REQUEST_INIT,
  GET_RESPONSE,
  HTTP_ERROR,
  REQUEST_ENDS,
  RESTART_PAGINATION,
} from "./constants";
import { RequestData } from "./epics/apiRequest";

export const makeRequest = (
  requestData: RequestData,
  name: string,
  paginationType?: string,
  resetData?: boolean
) => ({
  name,
  paginationType,
  requestData,
  resetData,
  type: API_REQUEST_INIT,
});

export const restartPagination = (name: string) => ({
  name,
  type: RESTART_PAGINATION,
});

export const getResponse = (
  payload: object,
  name: string,
  paginationType?: string,
  resetData?: boolean
) => ({
  name,
  paginationType,
  payload,
  resetData,
  type: GET_RESPONSE,
});

export const requestEnds = (name: string) => ({
  name,
  isLoading: false,
  type: REQUEST_ENDS,
});

export const handleError = (error: any, name: string) => ({
  error,
  name,
  type: HTTP_ERROR,
});
