import { Map } from "immutable";
import { Reducer } from "redux";
import { CLEAR_ALL, CLEAR_RESERVATION } from "../layout/types";
import {
  SAVE_TOAST_ID,
  SET_ADVISOR_SELECTED,
  SET_CAPTCHA,
  SET_DATE,
} from "./types";

export const initialState = Map({});

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ALL:
    case CLEAR_RESERVATION:
      return initialState;

    case SET_DATE:
      state = state.set("date", action.payload.date);
      state = state.set("venueId", action.payload.venueId);

      return state;
    case SET_CAPTCHA:
      state = state.set("captcha", action.captcha);

      return state;
    case SET_ADVISOR_SELECTED:
      state = state.set("isNoPreferenceAdvisor", action.isNoPreferenceAdvisor);

      return state;
    case SAVE_TOAST_ID:
      state = state.set("toastId", action.id);

      return state;
    default: {
      return state;
    }
  }
};

export { reducer as slotsReducer };
