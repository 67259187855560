import React from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { styled } from "../../styles/theme/themeHelper";
import { useCurrencies } from "../../utils/useCurrenciesHook";
import { useCurrency } from "../../utils/useCurrencyHook";
import { ArrowDownIcon } from "./appIcon";

const StyledDropdownToggle = styled(
  ({ backgroundColor, fontColor, outline, ...props }) => (
    <DropdownToggle {...props} />
  )
)<AppCurrencySelectorStyleProps>`
  align-items: center;
  border-color: transparent;
  display: flex;
  justify-content: space-around;
  float: right;
  font-size: 14px;
  padding: 0.2rem;
  min-width: 75px;
  text-transform: uppercase;

  &,
  &:hover,
  &:active {
    background-color: ${(props) => props.backgroundColor} !important;
    border-color: ${(props) =>
      props.outline ? props.fontColor : "transparent"} !important;
    color: ${(props) => props.fontColor} !important;
  }

  & > div {
    top: -5px;
    margin-right: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 40px;
    ${(props) => (props.theme.isMobile ? "width: 40px;" : "")}
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)<{ backgroundColor: string }>`
  min-width: 75px;
  overflow: hidden;
  background: ${(props) => props.backgroundColor};
  padding: 0px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26);
  border: none;
`;

const StyledDropdownItem = styled(
  ({ outline, backgroundColor, fontColor, ...props }) => (
    <DropdownItem {...props} />
  )
)<AppCurrencySelectorStyleProps>`
  text-align: center;
  padding: 0.25rem 1.2rem;
  text-transform: uppercase;
  color: ${(props) => props.fontColor};

  &:active,
  &.active,
  &:hover,
  &.hover {
    // Invert colors when showing with outline
    background-color: ${(props) =>
      props.outline ? props.fontColor : props.backgroundColor} !important;
    color: ${(props) =>
      props.outline
        ? props.backgroundColor
        : props.theme.body.font.secondaryTextColor};
  }
`;

export interface AppCurrencySelectorStyleProps {
  backgroundColor: string;
  fontColor: string;
  outline?: boolean;
  hide?: boolean;
}

export const AppCurrencySelector: React.FunctionComponent<
  AppCurrencySelectorStyleProps
> = ({
  backgroundColor,
  fontColor,
  outline,
}: AppCurrencySelectorStyleProps) => {
  const { t } = useTranslation();
  const { currencies } = useCurrencies();
  const { currency, setCurrency } = useCurrency();

  return (currencies || []).length > 1 ? (
    <UncontrolledDropdown
      id="currency-selector"
      aria-label={t("ariaLabelCurrencySelector", {
        defaultValue: "Currency selector dropdown",
      })}
    >
      <StyledDropdownToggle
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        outline={!!outline}
      >
        <span>&nbsp;{currency}&nbsp;</span>
        <ArrowDownIcon color={fontColor} size={12} />
      </StyledDropdownToggle>

      <StyledDropdownMenu backgroundColor={backgroundColor} role="listbox">
        {currencies?.map((cur) =>
          currency !== cur.code ? (
            <StyledDropdownItem
              id={`${cur.code}`}
              outline={!!outline}
              onClick={() => setCurrency?.(cur.code)}
              backgroundColor={backgroundColor}
              fontColor={fontColor}
              key={`${cur.code}`}
            >
              {cur.code}
            </StyledDropdownItem>
          ) : (
            <></>
          )
        )}
      </StyledDropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <></>
  );
};
