// Config file for i18n.
// To be changed to .ts when the project moves to Next.js 9

const getBWID = require("../src/utils/getBWID_ES5").getBWID;

const i18n = require("i18next/dist/cjs/i18next");
const ReactI18next = require("react-i18next");
const XHR = require("i18next-xhr-backend");

const config = require("../config");

const parse = require("html-react-parser").default;

const bwID = getBWID();
const backendUrl = `${config.baseApiUrl}/${bwID}/`;

const FALLBACK_LNG = "en";

const getLanguageMatch = (availableLanguages, languages) => {
  for (var x = 0; x < languages.length; x++) {
    for (var y = 0; y < availableLanguages.length; y++) {
      if (
        languages[x]
          .toLowerCase()
          .startsWith(availableLanguages[y].toLowerCase())
      ) {
        return availableLanguages[y];
      }
    }
  }
  return undefined;
};

const getDefaultLanguage = availableLanguages => {
  let defaultLanguage = undefined;

  if (availableLanguages && !defaultLanguage && process.browser) {
    const browserLanguages = window.navigator.languages;

    if (browserLanguages) {
      defaultLanguage = getLanguageMatch(availableLanguages, browserLanguages);
    }

    if (!defaultLanguage && !browserLanguages) {
      const browserDefaultLanguage =
        window.navigator.userLanguage || window.navigator.language;
      if (browserDefaultLanguage) {
        defaultLanguage = getLanguageMatch(availableLanguages, [
          browserDefaultLanguage
        ]);
      }
    }
  }

  return defaultLanguage || FALLBACK_LNG;
};

const options = {
  // debug: process && process.env.NODE_ENV === "development",
  defaultNS: "translations",
  fallbackLng: FALLBACK_LNG,
  ns: ["translations"],
  cleanCode: true,
  preload: [],
  // useLocalStorage: !(process && process.env.NODE_ENV === "development"),

  interpolation: {
    escapeValue: false, // not needed for react!!
    interpolation: {
      prefix: "{",
      suffix: "}"
    }
  },

  react: {
    useSuspense: false
  },

  // react i18next special options (optional)
  // override if needed - omit if ok with defaults
  /*
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  }
  */

  backend: {
    loadPath: backendUrl + "languages/{{lng}}/translations",
    parse: data => data && JSON.parse(data).values
  }
};

// This step is done on server.js when using SSR
if (!process || process.browser === true) {
  i18n
    .use(XHR.default || XHR)
    .use({
      type: "postProcessor",
      name: "htmlParser",
      process: function(value) {
        return value.length ? parse(value) : "";
      }
    })
    .use(ReactI18next.initReactI18next)
    .init({
      postProcess: ["htmlParser"],
      interpolation: {
        prefix: "{",
        suffix: "}"
      }
    });

  // .use(Cache)
  // .use(LanguageDetector)
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
}

i18n.getDefaultLanguage = availableLanguages =>
  getDefaultLanguage(availableLanguages || i18n.languages);

// a simple helper to getInitialProps passed on loaded i18n data
i18n.getInitialProps = (req, namespaces) => {
  if (!namespaces) {
    namespaces = i18n.options.defaultNS || "";
  }

  if (typeof namespaces === "string") {
    namespaces = [namespaces];
  }

  if (!req.i18n) {
    return {};
  }

  const initialI18nStore = {};

  req.i18n.toJSON = () => null; // do not serialize i18next instance and send to client

  req.i18n.languages.forEach(l => {
    initialI18nStore[l] = {};

    namespaces.forEach(ns => {
      initialI18nStore[l][ns] =
        (req.i18n.services.resourceStore.data[l] || {})[ns] || {};
    });
  });

  return {
    i18n: req.i18n, // use the instance on req - fixed language on request (avoid issues in race conditions with lngs of different users)
    initialI18nStore,
    initialLanguage: req.i18n.language
  };
};

const LOAD_LANGUAGES_ON_DEMAND = true;

module.exports = {
  LOAD_LANGUAGES_ON_DEMAND,
  i18n,
  options,
  getDefaultLanguage
};
