import React from "react";
import { styled } from "../../styles/theme/themeHelper";

interface StyleProps {
  styleSize: number;
  styleColor: string;
  textColor?: string;
}

const StyledBadge = styled.div<StyleProps>`
  background-color: ${props =>
    props.textColor ? props.styleColor : "transparent"};
  border: ${props => props.styleColor} 1px solid;
  border-radius: 25px;
  display: block;
  text-align: center;
  min-width: ${props => `${Math.floor(props.styleSize * 2)}px`};
  width: ${props => `${Math.floor(props.styleSize * 2.5)}px`};
  height: ${props => `${Math.floor(props.styleSize * 2)}px`};
`;

const StyledText = styled.span<StyleProps>`
  font-size: ${props => `${props.styleSize}px`};
  font-weight: bold;
  color: ${props => (props.textColor ? props.textColor : props.styleColor)};
  width: 100%;
  display: inline-block;
`;

export interface AppBadgeProps {
  color: string;
  size: number;
  value?: number;
  textColor?: string;
  onClick?: () => void;
}

type Props = AppBadgeProps;

export const AppBadge: React.FunctionComponent<Props> = ({
  color,
  value,
  size,
  textColor,
  onClick
}: Props) => {
  const textElement = (
    <StyledText styleSize={size} styleColor={color} textColor={textColor}>
      {value || "X"}
    </StyledText>
  );

  return (
    <StyledBadge
      onClick={onClick}
      styleSize={size}
      styleColor={color}
      textColor={textColor}
    >
      <div
        className={"w-100 h-100 d-flex"}
        style={{
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center"
        }}
      >
        {textElement}
      </div>
    </StyledBadge>
  );
};
