import { LoadScriptProps } from "@react-google-maps/api";
import dynamic from "next/dynamic";
import * as React from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";
import { Settings } from "../../shared";
import { MapProps, SearchProps } from "./interface";

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${(props) => props.theme.body.font.secondaryTextColor};
  margin: 0;
  padding: 1rem;
`;

export const Loader = () => (
  <div className={"w-100 p-4 text-center load-script"}>
    <Spinner />
  </div>
);

export const SearchWrapper = styled.div`
  border-radius: 42px;
  box-sizing: border-box;
  height: 42px;
  margin-bottom: 24px;
  border: 1px solid #c5cad4;
`;

const SearchLoader = () => <SearchWrapper className="animated-background" />;

export const MapWrapper = styled.div`
  width: 100%;
  height: 531px;
  box-sizing: border-box;
  border: ${(props) => (props.theme.isMobile ? 0 : 1)}px solid #c5cad4;
  border-radius: ${(props) => (props.theme.isMobile ? 0 : 5)}px;
  overflow: hidden;
`;

const MapLoader = () => <MapWrapper className="animated-background" />;

const isInternetExplorer = () => {
  const ua = navigator.userAgent;
  return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
};

export const getMap = (settings: Settings): React.ComponentType<MapProps> =>
  dynamic(
    import(
      `./${
        // Check for IE browser because react-leaflet does not support it
        settings.googleMaps || isInternetExplorer() ? "GoogleMap" : "LeafletMap"
      }`
    ),
    {
      loading: MapLoader,
      ssr: false,
    }
  );

export const getSearch = (
  settings: Settings
): React.ComponentType<SearchProps> =>
  dynamic(
    import(
      `./${settings?.googleMaps ? "GoogleMapSearch" : "LeafletMapSearch"}`
    ),
    {
      loading: SearchLoader,
      ssr: false,
    }
  );

const NoScriptToLoad: React.FC = ({ children }) => <>{children}</>;
export const getLoadScript = (
  settings?: Settings | void
): React.ComponentType<LoadScriptProps> =>
  // Check for IE browser because react-leaflet does not support it
  (!!settings && settings.googleMaps) || isInternetExplorer()
    ? dynamic(import("./LoadGoogleMapScript"), {
        loading: Loader,
        ssr: false,
      })
    : NoScriptToLoad;
