import { ofType } from "redux-observable";
import { of } from "rxjs";
import { mergeMap } from "rxjs/operators";
// tslint:disable-next-line: ordered-imports
import { CHANGE_LANGUAGE, SetLanguagesAction, SET_LANGUAGES } from "../types";

export const setLanguagesEpic = (action$: any) => {
  return action$.pipe(
    ofType(SET_LANGUAGES),
    mergeMap((action: SetLanguagesAction) => {
      return of({
        language: action.apiLanguagesObj.defaultLanguageIsoCode,
        type: CHANGE_LANGUAGE
      });
    })
  );
};
