import React from "react";
import { useTranslation } from "react-i18next";
import { allowedOverflow, AppParagraph2, EditIcon } from "../../../shared";
import { SummaryCard } from "./summaryCard";
import { CardBookingValidTypes } from "../../../components/bookingTypes/types";

export interface BookingTypeSummaryProps {
  selectedBookingType: CardBookingValidTypes;
  linkText?: string;
  onLinkClick?: () => void;
}

type Props = BookingTypeSummaryProps;

export const BookingTypeSummary: React.FunctionComponent<Props> = ({
  selectedBookingType,
  linkText,
  onLinkClick,
}: Props) => {
  const { t } = useTranslation();
  const bookingTypeDefaultTranslations: Record<CardBookingValidTypes, string> =
    {
      inStoreOrVirtual: "In-Store or Virtual Appointment",
      virtualAggregated: "Virtual Appointment with Any Store",
      liveChat: "Live video chat",
    };

  return (
    <SummaryCard
      id={`summary-card-booking-type`}
      icon={
        <EditIcon
          size={14}
          title={`${t("type", { defaultValue: "Type" })} icon`}
        />
      }
      linkText={linkText}
      onLinkClick={onLinkClick}
      title={t("type", { defaultValue: "Type" })}
    >
      <AppParagraph2
        id={"summary-card-booking-type-text"}
        className={`text-left color-text1 font-medium`}
        style={allowedOverflow}
      >
        {t(`${selectedBookingType}Summary`, {
          defaultValue: bookingTypeDefaultTranslations[selectedBookingType],
        })}
      </AppParagraph2>
    </SummaryCard>
  );
};
