import { Service } from "../../shared";
import { SAVE_ADD_ONS, SET_CURRENT_LAYOUT } from "./constants";

export const setCurrentLayout = (layoutType: string) => ({
  layoutType,
  type: SET_CURRENT_LAYOUT
});

export const saveAddOns = (addOns: Service[]) => ({
  addOns,
  type: SAVE_ADD_ONS
});
