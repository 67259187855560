import React from "react";
import { allowedOverflow, AppParagraph3, Theme } from "../../../../shared";
import { useTranslation } from "react-i18next";

interface TotalServiceDurationProps {
  totalDuration: string;
  theme?: Theme;
}
export const TotalServiceDuration = ({
  theme,
  totalDuration,
}: TotalServiceDurationProps) => {
  const { t } = useTranslation();
  return (
    <div
      className="w-100 d-flex flex-column align-items-end"
      style={{ justifyContent: "space-between" }}
    >
      <AppParagraph3 style={allowedOverflow}>
        {t("serviceDuration", { defaultValue: "Service duration" })}
      </AppParagraph3>
      <AppParagraph3
        id={"summary-card-service-time-total"}
        style={{
          ...allowedOverflow,
          color: theme?.body.font.mainTextColor,
        }}
      >
        {totalDuration}
      </AppParagraph3>
    </div>
  );
};
