export interface CustomerDetails {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  orderNumber?: string;
  externalId?: string;
  postcode?: string;
  receiveSmsReminder?: boolean;
  notes?: string;
  title?: TitleDescription;
  groupSize?: string;
  lang?: string;
  countryCode?: {
    name: string;
    iso2: string;
  };
}

export enum TitleDescription {
  MR = "MR",
  MRS = "MRS",
  MISS = "MISS",
  DR = "DR",
  PROF = "PROF",
  MS = "MS",
  REV = "REV",
}
