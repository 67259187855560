import chroma from "chroma-js";
import React from "react";
import { Button, ButtonProps } from "reactstrap";
import colors from "../../styles/theme/colors";
import fonts, { fontStrongWeight } from "../../styles/theme/fonts";
import { getColorContrast, styled } from "../../styles/theme/themeHelper";

const StyledButton = styled(Button)<{ hidden?: boolean }>`
  ${(props) => (props.hidden ? "display: none;" : "")}
  border-radius: 4px;
  font-size: ${fonts.paragraph2.size}px;
  font-weight: ${fontStrongWeight};
  line-height: ${fonts.paragraph2.height}px;
  min-height: 42px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 0px 15px;

  &.btn-lg,
  &.btn-group-lg > .btn {
    width: 180px;
  }

  &.btn-default {
    background-color: ${colors.disabled};
    border-color: ${colors.disabled};

    &:active,
    &:focus {
      border-color: ${(props) =>
        chroma(props.theme.body.buttons.mainBackgroundColor || colors.primary)
          .brighten()
          .hex()} !important;
    }
  }

  &.btn-primary {
    background-color: ${(props) =>
      props.theme.body.buttons.mainBackgroundColor || colors.primary};
    border-color: ${(props) =>
      props.theme.body.buttons.mainBackgroundColor || colors.primary};
    color: ${(props) => props.theme.body.buttons.mainTextColor};

    &:active,
    &:focus {
      border-color: ${(props) =>
        chroma(props.theme.body.buttons.mainBackgroundColor || colors.primary)
          .brighten()
          .hex()} !important;
    }
  }

  &.btn-secondary {
    background-color: ${(props) =>
      props.theme.body.buttons.secondaryBackgroundColor || colors.secondary};
    border-color: ${(props) =>
      props.theme.body.buttons.secondaryBackgroundColor || colors.secondary};
    color: ${(props) => props.theme.body.buttons.secondaryTextColor};

    &:active,
    &:focus {
      border-color: ${(props) =>
        chroma(
          props.theme.body.buttons.secondaryBackgroundColor || colors.secondary
        )
          .brighten()
          .hex()} !important;
    }
  }

  &.btn-light {
    background-color: ${"#E9ECEF"};
    border-color: ${"#000"};

    &:active,
    &:focus {
      border-color: ${"#000"} !important;
    }
  }

  &.btn-ghost {
    color: ${(props) => props.theme.body.font.mainTextColor};
    border: 1px solid
      ${(props) => getColorContrast(props.theme.body.backgroundColor)};
  }

  &.btn-danger {
    color: white;
    border: none;
    background-color: ${colors.danger};

    &:active,
    &:focus {
      border-color: ${(props) =>
        chroma(
          props.theme.body.buttons.secondaryBackgroundColor || colors.secondary
        )
          .brighten()
          .hex()} !important;
    }
  }

  .d-none {
    display: none;
  }
`;

interface AppButtonProps extends ButtonProps {
  isLoading?: boolean;
  width?: number;
  hidden?: boolean;
}

type Props = AppButtonProps;

export const AppButton: React.FunctionComponent<Props> = ({
  isLoading,
  width,
  disabled,
  children,
  hidden,
  ...props
}: AppButtonProps) => (
  <StyledButton {...props} disabled={disabled || isLoading} hidden={hidden}>
    <span id={props.id ? `${props.id}-inner-div` : ""}>
      <span
        id={props.id ? `${props.id}-loader` : ""}
        className={`spinner-border spinner-border-sm ${
          isLoading ? "" : "d-none"
        }`}
        role="status"
        aria-hidden="true"
      ></span>
      <span
        id={props.id ? `${props.id}-text` : ""}
        className={`${isLoading ? "d-none" : ""}`}
      >
        {children}
      </span>
    </span>
  </StyledButton>
);
