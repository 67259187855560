import { Booking, Reservation } from "../../shared";
import { ApplicationState } from "../../store";

const getReservation = (state: ApplicationState) => {
  return state.api.get("RESERVATION");
};

export const getReservationResponse = (
  state: ApplicationState
): Reservation | void => {
  if (!getReservation(state) || !getReservation(state).get("payload")) {
    return;
  }

  return getReservation(state).getIn(["payload", "response"]);
};

const getBooking = (state: ApplicationState) => {
  return state.api.get("BOOKING");
};

export const getBookingResponse = (
  state: ApplicationState
): Booking | undefined => {
  if (!getBooking(state) || !getBooking(state).get("payload")) {
    return;
  }

  return getBooking(state).getIn(["payload", "response"]);
};

export const getReservationVenueId = (
  state: ApplicationState
): string | void => {
  if (!getReservation(state) || !getReservation(state).get("payload")) {
    return;
  }

  return getReservation(state).getIn(["payload", "response", "venueId"]);
};

export const getReservationProductId = (
  state: ApplicationState
): string | void => {
  if (!getReservation(state) || !getReservation(state).get("payload")) {
    return;
  }

  return getReservation(state).getIn(["payload", "response", "productId"]);
};

export const getReservationAdvisorId = (
  state: ApplicationState
): string | void => {
  if (!getReservation(state) || !getReservation(state).get("payload")) {
    return;
  }

  return getReservation(state).getIn(["payload", "response", "staffId"]);
};

export const getBookingVenueId = (state: ApplicationState): string | void => {
  if (!getBooking(state) || !getBooking(state).get("payload")) {
    return;
  }

  return getBooking(state).getIn(["payload", "response", "venueId"]);
};

export const getBookingProductId = (state: ApplicationState): string | void => {
  if (!getBooking(state) || !getBooking(state).get("payload")) {
    return;
  }

  return getBooking(state).getIn(["payload", "response", "productId"]);
};

export const getBookingAdvisorId = (state: ApplicationState): string | void => {
  if (!getBooking(state) || !getBooking(state).get("payload")) {
    return;
  }

  return getBooking(state).getIn(["payload", "response", "staffId"]);
};
