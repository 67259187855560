import Head from "next/head";
import React from "react";

const PageHead = (props: any) => (
  <Head>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta charSet="utf-8" />
    <link
      rel="shortcut icon"
      type="image/png"
      sizes="16x16"
      href="/static/favicon/favicon-16x16.png"
    />
    <link
      rel="shortcut icon"
      type="image/png"
      sizes="24x24"
      href="/static/favicon/favicon-24x24.png"
    />
    <link
      rel="shortcut icon"
      type="image/png"
      sizes="32x32"
      href="/static/favicon/favicon-32x32.png"
    />
    <link
      rel="shortcut icon"
      type="image/png"
      sizes="64x64"
      href="/static/favicon/favicon-64x64.png"
    />
    <title>
      {props.titlePart ? props.titlePart + " | " : ""}Booking Widget
    </title>
  </Head>
);

export default PageHead;
