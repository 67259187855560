import moment from "moment";
import "moment-timezone";

export const calculateDuration = (
  timezone: string | void,
  expirationDateTime: string | void
) => {
  if (!timezone || !expirationDateTime) {
    return;
  }

  const startTime = moment.tz(timezone);
  const endTime = moment.tz(expirationDateTime, timezone);

  return moment.duration(endTime.diff(startTime)).asSeconds();
};
