import { List, Map } from "immutable";
import { Reducer } from "redux";
import {
  CLEAR_ALL,
  CLEAR_PRODUCT,
  CLEAR_ADVISOR,
  CLEAR_RESERVATION,
  CLEAR_BOOKING,
  CLEAR_VENUE,
  CLEAR_DATEPICKER_SLOTS,
  CLEAR_FIRST_AVAILABLE_SLOT,
  CLEAR_NEARBY_VENUES_SEARCH,
} from "../../components/layout/types";
import {
  API_REQUEST_INIT,
  GET_RESPONSE,
  HTTP_ERROR,
  REQUEST_ENDS,
  RESTART_PAGINATION,
} from "./constants";
import _ from "lodash";

export const initialState: any = Map({});

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ALL: {
      state = state.setIn(["VENUES", "payload"], Map());
      state = state.setIn(["VENUES", "paginated"], List());
      state = state.setIn(["SINGLE_VENUE", "payload"], Map());
      state = state.setIn(["PRODUCTS", "payload"], Map());
      state = state.setIn(["PRODUCTS", "paginated"], List());
      state = state.setIn(["SINGLE_PRODUCT", "payload"], Map());
      state = state.setIn(["ADDONS", "payload"], Map());
      state = state.setIn(["SINGLE_ADVISOR", "payload"], Map());
      state = state.setIn(["ADVISORS", "payload"], Map());
      state = state.setIn(["ADVISORS", "paginated"], List());
      state = state.setIn(["SLOTS", "payload"], Map());
      state = state.setIn(["SLOTS", "paginated"], List());
      state = state.setIn(["FIRST_AVAILABLE_SLOT", "payload"], Map());
      state = state.setIn(["QUESTIONS", "payload"], Map());
      state = state.setIn(["RESERVATION", "payload"], Map());
      state = state.setIn(["BOOKING", "payload"], Map());
      state = state.setIn(["CANCELLATION", "payload"], Map());
      state = state.setIn(["ANALYTICS_SESSION", "payload"], Map());
      state = state.setIn(["PAYMENT", "payload"], Map());
      return state;
    }
    case API_REQUEST_INIT:
      state = state.set(
        action.name,
        Map({
          isLoading: true,
          paginated: action.paginationType
            ? state.getIn([action.name, "paginated"])
            : List(),
          payload: state.getIn([action.name, "payload"]),
        })
      );

      return state;
    case RESTART_PAGINATION:
      state = state.setIn([action.name, "payload"], Map());

      state = state.setIn([action.name, "paginated"], List());

      return state;
    case GET_RESPONSE:
      if (action.resetData) {
        state = state.setIn([action.name, "payload"], Map());
        state = state.setIn([action.name, "paginated"], List());
      }
      if (
        !_.isEqual(
          state.getIn([action.name, "payload", "response"]),
          action.payload.response
        ) &&
        action.payload.response !== undefined
      ) {
        state = state.setIn([action.name, "payload"], Map(action.payload));
      }
      if (action.paginationType) {
        state = state.updateIn(
          [action.name, "paginated"],
          (list: List<any> = List()) =>
            list.push(...action.payload.response[action.paginationType])
        );
      }

      return state;
    case REQUEST_ENDS:
      state = state.setIn([action.name, "isLoading"], action.isLoading);

      return state;
    case HTTP_ERROR:
      const errorObject = {
        ...action.error,
        response: {
          ...(action.error.response || {}),
          status: action.error.status,
        },
      };
      state = state.setIn([action.name, "error"], Map(errorObject));

      state = state.setIn([action.name, "isLoading"], action.isLoading);

      return state;
    case CLEAR_RESERVATION:
      state = state.setIn(["VENUES", "payload"], Map());
      state = state.setIn(["VENUES", "paginated"], List());
      state = state.setIn(["PRODUCTS", "payload"], Map());
      state = state.setIn(["PRODUCTS", "paginated"], List());
      state = state.setIn(["ADDONS", "payload"], Map());
      state = state.setIn(["ADVISORS", "payload"], Map());
      state = state.setIn(["ADVISORS", "paginated"], List());
      state = state.setIn(["SLOTS", "payload"], Map());
      state = state.setIn(["SLOTS", "paginated"], List());
      state = state.setIn(["FIRST_AVAILABLE_SLOT", "payload"], Map());
      state = state.setIn(["RESERVATION", "payload"], Map());
      state = state.setIn(["SLOTS_DATEPICKER", "payload"], Map());
      state = state.setIn(["SLOTS_DATEPICKER", "paginated"], List());
      return state;
    case CLEAR_BOOKING:
      state = state.setIn(["BOOKING", "payload"], Map());
      state = state.setIn(["CANCELLATION", "payload"], Map());
      state = state.setIn(["SLOTS_DATEPICKER", "payload"], Map());
      state = state.setIn(["SLOTS_DATEPICKER", "paginated"], List());
      return state;
    case CLEAR_PRODUCT:
      state = state.setIn(["SINGLE_PRODUCT", "payload"], Map());
      state = state.setIn(["PRODUCTS", "payload"], Map());
      state = state.setIn(["PRODUCTS", "paginated"], List());
      state = state.setIn(["SLOTS", "payload"], Map());
      state = state.setIn(["SLOTS", "paginated"], List());
      state = state.setIn(["FIRST_AVAILABLE_SLOT", "payload"], Map());
      state = state.setIn(["SLOTS_DATEPICKER", "payload"], Map());
      state = state.setIn(["SLOTS_DATEPICKER", "paginated"], List());
      state = state.setIn(["QUESTIONS", "payload"], Map());
      return state;
    case CLEAR_VENUE:
      state = state.setIn(["SINGLE_VENUE", "payload"], Map());
      state = state.setIn(["VENUES", "payload"], Map());
      state = state.setIn(["VENUES", "paginated"], List());
      state = state.setIn(["SLOTS", "payload"], Map());
      state = state.setIn(["SLOTS", "paginated"], List());
      state = state.setIn(["FIRST_AVAILABLE_SLOT", "payload"], Map());
      state = state.setIn(["SLOTS_DATEPICKER", "payload"], Map());
      state = state.setIn(["SLOTS_DATEPICKER", "paginated"], List());
      return state;
    case CLEAR_ADVISOR:
      state = state.setIn(["SINGLE_ADVISOR", "payload"], Map());
      state = state.setIn(["ADVISORS", "payload"], Map());
      state = state.setIn(["ADVISORS", "paginated"], List());
      state = state.setIn(["SLOTS", "payload"], Map());
      state = state.setIn(["SLOTS", "paginated"], List());
      state = state.setIn(["FIRST_AVAILABLE_SLOT", "payload"], Map());
      state = state.setIn(["SLOTS_DATEPICKER", "payload"], Map());
      state = state.setIn(["SLOTS_DATEPICKER", "paginated"], List());
      return state;
    case CLEAR_DATEPICKER_SLOTS:
      state = state.setIn(["SLOTS_DATEPICKER", "payload"], Map());
      state = state.setIn(["SLOTS_DATEPICKER", "paginated"], List());
      return state;
    case CLEAR_FIRST_AVAILABLE_SLOT:
      state = state.setIn(["FIRST_AVAILABLE_SLOT", "payload"], Map());
      return state;
    case CLEAR_NEARBY_VENUES_SEARCH:
      state = state.setIn(["OTHER_VENUES_SEARCH", "payload"], Map());
      return state;
    default: {
      return state;
    }
  }
};

export { reducer as apiReducer };
