import React from "react";
import { useTranslation } from "react-i18next";
import {
  allowedOverflow,
  AppButton,
  AppModal,
  AppParagraph1,
  AppParagraph2,
} from "../../shared";
import { styled } from "../../styles/theme/themeHelper";

const StyledTitle = styled.div`
  min-height: 48px;
  padding-bottom: 1.5rem;
`;

const StyledText = styled.div`
  min-height: 48px;
  padding-bottom: 1.5rem;
`;

export interface ErrorModalProps {
  title?: string;
  message?: string;
  isOpen: boolean;
  onToggle: () => void;
}

type Props = React.HTMLAttributes<HTMLElement> & ErrorModalProps;

export const ErrorModal: React.FunctionComponent<Props> = ({
  title,
  message,
  isOpen,
  onToggle,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const renderModal = () => {
    return (
      <AppModal
        id={`error-modal`}
        className={"text-center"}
        isOpen={isOpen}
        toggle={onToggle}
        aria-modal="true"
        aria-labelledby="error-modal-title"
        {...props}
      >
        <StyledTitle>
          <AppParagraph1
            className={"color-text1"}
            style={allowedOverflow}
            id="error-modal-title"
          >
            {title}
          </AppParagraph1>
        </StyledTitle>
        <StyledText>
          <AppParagraph2 style={allowedOverflow}>{message}</AppParagraph2>
        </StyledText>
        <AppButton
          id={`error-modal-button`}
          color="primary"
          className={"w-100"}
          onClick={onToggle}
        >
          {t("close", { defaultValue: "Close" })}
        </AppButton>
      </AppModal>
    );
  };

  return <div>{renderModal()}</div>;
};
