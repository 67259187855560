import { Attachments } from "../../components/questions/types";

export enum AnswerType {
  FreeText = "FreeText",
  Dropdown = "Dropdown",
  RadioButton = "RadioButton",
  CheckBox = "CheckBox",
  Rating = "Rating",
  Date = "Date",
  Number = "Number",
  Upload = "Upload",
}

export enum WhenToAsk {
  WHEN_JOINING = "WHEN_JOINING",
  AFTER_JOINING = "AFTER_JOINING",
  AFTER_SERVICE_BY_CUSTOMER = "AFTER_SERVICE_BY_CUSTOMER",
}

export interface CheckboxOptions {
  name: string;
  fileUrl?: string | null;
  thumbnailUrl?: string | null;
}
export interface Questions {
  answerType: AnswerType;
  questionOptions: string[];
  questionText: string;
  questionInfoText: string;
  id: string;
  required: boolean;
  hasOptionForOther: boolean;
  ratingScale?: number;
  answerValidationPattern?: string | null;
  maxUploads?: number;
  allowedFileTypeText?: string;
  selectionMinLimit?: number;
  selectionMaxLimit?: number;
  distantDate: boolean;
}

export type TranslatedQuestions = Questions & {
  checkBoxOptions?: CheckboxOptions[];
};

export interface ConfirmationQuestions {
  answer: Answers | null;
  question: QuestionsResponse;
}

export interface Answers {
  answerFiles: Attachments;
  answerValue: string;
  questionId: string;
}

export interface Translation {
  translation: string;
  languageCode: string;
}

export interface MultipleChoiceAnswer {
  text: string;
  answerTranslations: Translation[];
  fileUrl?: string | null;
  thumbnailUrl?: string | null;
}

export type QuestionsResponse = Questions & {
  questionTranslations: Translation[];
  multipleChoiceAnswers: MultipleChoiceAnswer[];
};
