var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { Integrations } from "@sentry/tracing";
import { appVersion, sentryUrl } from "./config";

const domainNameFromUrl = window.location.hostname;

Sentry.init({
  environment: domainNameFromUrl,
  dsn: sentryUrl,
  release: appVersion,
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: 0.2,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend: function (event, hint) {
    // https://github.com/getsentry/sentry-javascript/issues/3440
    // filter out UnhandledRejection errors that have no information
    if (
      event !== undefined &&
      event.exception !== undefined &&
      event.exception.values !== undefined &&
      event.exception.values.length == 1
    ) {
      var e = event.exception.values[0];
      if (
        e.type === "UnhandledRejection" &&
        e.value === "Non-Error promise rejection captured with value: "
      ) {
        return null;
      }
    }
  },
});
