import { i18n } from "../../i18n/i18n";

export function getDateTimeLocale() {
  const currentLanguage = i18n.language || "en";
  const currentLanguageMain = currentLanguage.split("-")[0];

  switch (currentLanguageMain.toLowerCase()) {
    case "zh":
      return "zh-cn";
    case "pa":
      return "pa-in";
    case "hy":
      return "hy-am";
    default:
      return currentLanguage;
  }
}
