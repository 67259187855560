import * as React from "react";
import { withTheme } from "styled-components";
import { AppParagraph3, SelectedIcon, Theme } from "../../../shared";
import { styled, getColorContrast } from "../../../styles/theme/themeHelper";

export enum StepIndicatorState {
  blank = 0,
  active = 1,
  checked = 2,
}

export interface StepIndicatorProps {
  stepState: StepIndicatorState;
  stepSubTitle?: string;
  stepLabel: string;
  stepName: string;
  stepTitle?: string;
  stepCompleteTranslation?: string;
  stepCurrentTranslation?: string;
}

const Container = styled.li`
  width: 100px;
  display: inline-block;
  margin-top: 0.5rem;

  & + &::before {
    margin-top: 5px;
    margin-left: -42px;
    position: absolute;
    width: 84px;
    height: 0px;
    border-bottom: solid 1px
      ${(props) =>
        getColorContrast(props.theme.body.font.secondaryTextColor, 0.5)};
    content: " ";
  }
`;

const StepOuterDot = styled.div`
  display: flex;
  margin: auto;
  width: 12px;
  height: 12px;
  border-radius: 50%;

  &.blank {
    border: solid 1px
      ${(props) =>
        getColorContrast(props.theme.body.font.secondaryTextColor, 0.5)};
  }

  &.active {
    border: dotted 1px
      ${(props) =>
        getColorContrast(props.theme.body.font.secondaryTextColor, 0.5)};
  }

  &.checked {
    border: none;
  }
`;

const StepInnerDot = styled.div`
  border-radius: 50%;
  background: ${(props) => props.theme.body.buttons.mainBackgroundColor};

  &.blank {
    display: none;
  }

  &.active {
    height: 6px;
    width: 6px;
    margin: 2px;
  }
`;

const ScreenReaderSpan = styled.span`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
`;

/*
const StepInnerIcon = styled.object`
  position: absolute;
  display: block;
  height: 12px;
  width: 12px;
`;

 */

type Props = StepIndicatorProps & { theme: Theme };

export const StepIndicatorBase: React.FunctionComponent<Props> = ({
  stepName,
  stepState,
  stepLabel,
  theme,
  stepCompleteTranslation,
  stepCurrentTranslation,
}: Props) => {
  const stateName = StepIndicatorState[stepState];

  return (
    <Container
      id={stepName}
      aria-current={
        stepState === StepIndicatorState.active ? "step" : undefined
      }
    >
      {stepState === StepIndicatorState.checked ? (
        <StepOuterDot className={stateName}>
          <SelectedIcon
            color={theme.body.buttons.mainBackgroundColor}
            size={12}
            ariaHiddenValue
          />
        </StepOuterDot>
      ) : (
        <StepOuterDot className={stateName} aria-hidden>
          <StepInnerDot className={stateName}></StepInnerDot>
        </StepOuterDot>
      )}
      {stepState === StepIndicatorState.blank ? (
        <AppParagraph3
          style={{
            color: getColorContrast(theme.body.font.mainTextColor, 0.7),
          }}
          className={"text-center m-1 color-text3 font-regular"}
        >
          {stepLabel}
        </AppParagraph3>
      ) : stepState === StepIndicatorState.checked ? (
        <AppParagraph3
          style={{ color: theme.body.font.mainTextColor }}
          className={"text-center m-1 font-regular"}
        >
          <ScreenReaderSpan>{stepCompleteTranslation}</ScreenReaderSpan>
          {stepLabel}
        </AppParagraph3>
      ) : (
        <AppParagraph3
          style={{ color: theme.body.font.mainTextColor }}
          className={"text-center m-1"}
        >
          <ScreenReaderSpan>{stepCurrentTranslation}</ScreenReaderSpan>
          {stepLabel}
        </AppParagraph3>
      )}
    </Container>
  );
};

export const StepIndicator = withTheme(StepIndicatorBase);
