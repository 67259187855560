import { ApplicationState } from "../../store";

const getApi = (state: ApplicationState, type: string) => {
  return state.api.get(type);
};

export const getError = (state: ApplicationState, type: string) => {
  if (!getApi(state, type)) {
    return;
  }

  const errorResponse = getApi(state, type).getIn(["error", "response"]);

  return errorResponse;
};
