import * as React from "react";
import { AppHeading3 } from "./appHeading";
import { AppLink } from "./appLink";

export interface GoToQueueProps {
  goToQueue?: () => void;
}

type Props = GoToQueueProps;

export const AppGoToQueue: React.FunctionComponent<Props> = ({
  goToQueue
}: Props) => {
  return (
    <div className={"p-2 w-100"}>
      <hr className={"p-0 mt-0"} />
      <div className={"d-inline-flex"}>
        <AppHeading3>Want to see someone now?</AppHeading3>
        &nbsp;
        <AppLink onClick={goToQueue}>JOIN QUEUE</AppLink>
      </div>
    </div>
  );
};
