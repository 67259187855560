export enum TypeKeys {
  ADD_ATTACHMENT = "ADD_ATTACHMENT",
  PRESIGNED_URL_REQUEST_COMPLETED = "PRESIGNED_URL_REQUEST_COMPLETED",
  UPLOAD_ATTACHMENTS_COMPLETED = "UPLOAD_ATTACHMENTS_COMPLETED",
  DELETE_ATTACHMENT = "DELETE_ATTACHMENT",
  DELETE_ATTACHMENT_SUCCESS = "DELETE_ATTACHMENT_SUCCESS",
  DELETE_ATTACHMENT_FAIL = "DELETE_ATTACHMENT_FAIL",
  REMOVE_FILE = "REMOVE_FILE",
  CLEAR_ALL = "CLEAR_ALL",
  INIT_UPLOAD_STATE = "INIT_UPLOAD_STATE",
  TRANSFORM_ANSWER_FILES_OF_CONFIRMED_QUESTIONS = "TRANSFORM_ANSWER_FILES_OF_CONFIRMED_QUESTIONS"
}
