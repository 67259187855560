import * as React from "react";
import styled from "styled-components";
import { AppButton, Settings } from "..";
import config from "../../../config";
import { AddIcon, NextIcon, RemoveIcon } from "./appIcon";

export interface AppRowProps {
  id?: string;
  rowText: React.ReactChild;
  rowImage?: React.ReactChild;
  selected?: boolean | null;
  onToggleSelected?: () => void;
  onToggleExpanded?: () => void;
  type?: string;
  isMobile?: boolean;
  buttonText?: string;
  settings?: Settings;
  border?: boolean;
  extra?: React.ReactChild;
  disable?: boolean;
  hideSelectButtonAndDisableAction?: boolean;
}

type Props = AppRowProps;

const Holder = styled.li<{ border?: boolean; disable?: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-bottom: 12px;
  margin-bottom: 20px;
  border-bottom: ${(props) => (props.border ? 1 : 0)}px solid rgba(0, 0, 0, 0.1) !important;
  ${(props) => props.disable && `pointer-events: none; opacity: .5;`};
`;

const Row = styled.div`
  display: flex;
`;

export const AppRow: React.FunctionComponent<Props> = ({
  id,
  rowText,
  rowImage,
  selected,
  onToggleSelected,
  type,
  isMobile,
  buttonText,
  onToggleExpanded,
  settings,
  border,
  extra,
  disable,
  hideSelectButtonAndDisableAction,
}: Props) => {
  const showButton = Boolean(!isMobile && buttonText);

  const renderIcon = () => {
    if (hideSelectButtonAndDisableAction) {
      return;
    }
    const rowIcon = selected ? (
      <RemoveIcon size={36} className={"float-right"} />
    ) : (
      <AddIcon size={36} className={"float-right"} />
    );

    if (config.isMultiProductSelectionActive || (type && type === "add-ons")) {
      return rowIcon;
    }

    return (
      <>
        <AppButton
          color="primary"
          className={"px-5 mb-2 float-right select-button"}
          hidden={!showButton}
          onClick={onClick}
        >
          {buttonText}
        </AppButton>
        <NextIcon
          size={36}
          className={"float-right select-button-arrow"}
          hidden={showButton}
          onClick={onClick}
        />
      </>
    );
  };

  const onClick = React.useCallback(
    () =>
      settings && settings.enableProductCheckboxConfirmation && onToggleExpanded
        ? onToggleExpanded()
        : (onToggleSelected as () => void)(),
    [onToggleExpanded, onToggleSelected]
  );

  const renderContent = () => {
    return (
      <Holder
        onClick={() => !hideSelectButtonAndDisableAction && onClick()}
        border={border}
        onKeyPress={
          (settings && settings.enableProductCheckboxConfirmation) ||
          hideSelectButtonAndDisableAction
            ? undefined
            : onClick
        }
        role={!showButton ? "button" : ""}
        disable={disable}
        aria-disabled={disable ? "true" : undefined}
      >
        <Row>
          <div
            id={`row-item-${id}`}
            className="d-inline-flex justify-content-between w-100"
          >
            {rowImage && (
              <div className="mr-3 flex-grow-0 flex-shrink-0">{rowImage}</div>
            )}
            <div className="flex-grow-1 flex-shrink-1 flex-column">
              {rowText}
            </div>
          </div>
          <div className={`${isMobile ? `pl-3 mr-2` : `pl-3 mr-4`}`}>
            {renderIcon()}
          </div>
        </Row>
        <Row>{extra}</Row>
      </Holder>
    );
  };

  return renderContent();
};
