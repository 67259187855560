import { AnalyticsEventActions } from "./eventActions";

export interface AnalyticsSessionPayload {
  browser?: string;
  browserVersion?: string;
  device?: string;
  os?: string;
  osVersion?: string;
  city?: string;
  postalRegion?: string;
  country?: string;
  ip?: string;
  kioskIdentifier?: string;
  path?: string;
  referrer?: string;
  customerId?: string;
  externalId?: string;
  emailAddress?: string;
  bookingAttendeeId?: string;
  isReschedule?: boolean;
  isManageAppointment?: boolean;
  isCancelAppointment?: boolean;
  isAnsweredQuestions?: boolean;
  venueId?: string;
  productId?: string;
}

export enum AnalyticsCategory {
  EVENT = "event",
}

export enum AnalyticsEventType {
  CLICK = "click",
  SCREEN_TRACKING = "screen_tracking",
}

export interface AnalyticsEvent {
  action: AnalyticsEventActions;
  properties: {
    category: AnalyticsCategory;
    eventType: AnalyticsEventType;
    label: string;
  };
}

export interface TrackAnalyticsEvent {
  app: string;
  page: string;
  event: AnalyticsEvent;
}
