import React from "react";
import { useTranslation } from "react-i18next";
import {
  Advisor,
  allowedOverflow,
  AppParagraph2,
  AppParagraph3,
  SummaryAdvisorIcon,
} from "../../../shared";
import { AdvisorPortrait } from "../../advisorSelector/components/advisorPortrait";
import { SummaryCard } from "./summaryCard";

export interface AdvisorSummaryProps {
  advisor: Advisor;
  linkText?: string;
  language?: string;
  showStaffExternalName?: boolean;
  onLinkClick?: () => void;
}

type Props = AdvisorSummaryProps;

export const AdvisorSummary: React.FunctionComponent<Props> = ({
  advisor,
  linkText,
  language,
  showStaffExternalName,
  onLinkClick,
}: Props) => {
  const { t } = useTranslation();

  const advisorInfo =
    advisor.personLanguageLabels &&
    advisor.personLanguageLabels.find(
      (label) => label.languageCode === language
    );

  const renderAdvisor = () => {
    return (
      <div className="d-inline-flex justify-content-between w-100">
        <AdvisorPortrait
          id={"summary-card-advisor-portrait"}
          advisor={advisor}
          size={36}
        />
        <div className="ml-3 flex-grow-1 flex-shrink-1 flex-column text-left">
          {advisor.name && (
            <AppParagraph2
              id={"summary-card-advisor-name"}
              className="mb-1 color-text1 font-medium"
              style={allowedOverflow}
            >
              {!!showStaffExternalName
                ? advisorInfo && advisorInfo.externalName
                : advisor.name}
            </AppParagraph2>
          )}
          {advisor.role && (
            <AppParagraph3
              id={"summary-card-advisor-role"}
              style={allowedOverflow}
              className="mb-1"
            >
              {advisorInfo && advisorInfo.role}
            </AppParagraph3>
          )}
        </div>
      </div>
    );
  };

  return (
    <SummaryCard
      id={`summary-card-advisor`}
      icon={
        <SummaryAdvisorIcon
          size={14}
          title={`${t("advisor", { defaultValue: "Advisor" })} icon`}
          ariaHiddenValue={true}
        />
      }
      linkText={linkText}
      onLinkClick={onLinkClick}
      title={t("advisor", { defaultValue: "Advisor" })}
    >
      {renderAdvisor()}
    </SummaryCard>
  );
};
