import { Map } from "immutable";
import { Reducer } from "redux";
import { SET_BOOKING_TYPE } from "./constants";

export const initialState = Map({});

const reducer: Reducer = (state = initialState, action) => {
  if (action.type === SET_BOOKING_TYPE) {
    state = state.set("selectedBookingType", action.bookingType);
    return state;
  }
  return state;
};

export { reducer as bookingTypeReducer };
