import { Map } from "immutable";
import { Reducer } from "redux";
import { CLEAR_ALL } from "../layout/types";
import { SET_CUSTOMER_DETAILS } from "./constants";

export const initialState = Map({});

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ALL:
      return initialState;

    case SET_CUSTOMER_DETAILS:
      state = state.set("details", action.details);

      return state;
    default: {
      return state;
    }
  }
};

export { reducer as detailsReducer };
