import { useRouter } from "next/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { withTheme } from "styled-components";
import {
  ApiLanguages,
  BackArrowIcon,
  CartIcon,
  CloseIcon,
  StepNames,
  Theme,
  withBadge,
} from "..";
import config from "../../../config";
import { getColorContrast, styled } from "../../styles/theme/themeHelper";
import {
  getFirstPageFromRouter,
  getIdsFromRouter,
} from "../../utils/getFirstPageFromRouter";
import PageRouter from "../../utils/pageRouter";
import { AppCurrencySelector } from "./appCurrencySelector";
import { AppLanguageSelector } from "./appLanguageSelector";

export interface AppNavbarProps {
  availableLanguages?: ApiLanguages | void;
  currentLanguage?: string;
  onLanguageSelection?: (language: string) => void;
  showLanguageSelector?: boolean;
  showCurrencySelector?: boolean;
  showCart?: boolean;
  isCartOpen?: boolean;
  cartAmount?: number;
  onCartToggle?: () => void;
  clearAll?: () => void;
  showGoBack?: boolean;
  onGoBack?: () => void;
  hideGoBack?: boolean;
  firstPage?: StepNames;
}

const StyledNav = styled.div<{ isEmbedded: boolean }>`
  display: flex;
  opacity: ${(props) => (props.theme.logo ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;
  min-height: ${(props) => (props.theme.isMobile ? "50px" : "80px")};
  padding: 0px 15px;
  background-color: ${({ theme, isEmbedded }) =>
    isEmbedded ? theme.body.backgroundColor : theme.header.backgroundColor};
  ${({ isEmbedded, theme }) =>
    isEmbedded
      ? "border-bottom: solid 1px " +
        getColorContrast(theme.body.backgroundColor) +
        ";"
      : ""}
`;

const StyledNavItem = styled.div`
  width: 85px;
  margin: auto;
  flex: 1 1 auto;
`;

const StyledPointer = styled.div`
  display: inline-flex;
  cursor: pointer;
`;

const StyledImage = styled.img<{ onLogoClickEnable?: boolean }>`
  ${(props) => (props.onLogoClickEnable ? "cursor: pointer;" : "")}
  max-height: ${(props) => (props.theme.isMobile ? "50px" : "80px")};
  max-width: 100%;
`;

type Props = AppNavbarProps & { theme: Theme };

const AppNavbarBase: React.FunctionComponent<Props> = ({
  availableLanguages,
  currentLanguage,
  onLanguageSelection,
  showLanguageSelector,
  showCurrencySelector,
  isCartOpen,
  cartAmount,
  onCartToggle,
  showCart,
  onGoBack,
  showGoBack,
  theme,
  hideGoBack,
  clearAll,
  firstPage,
}: Props) => {
  const router = useRouter();
  const [isEmbedded, setIsEmbedded] = React.useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    setIsEmbedded(router && String(router.query.embedded) === "true");
  }, []);

  const backgroundColor = isEmbedded
    ? theme.body.backgroundColor
    : getColorContrast(theme.header.backgroundColor, 1, 0.2);
  const fontColor = isEmbedded
    ? theme.body.font.mainTextColor
    : getColorContrast(theme.header.backgroundColor, 1, 5);

  const backArrowColor = isEmbedded
    ? theme.body.font.mainTextColor
    : getColorContrast(theme.header.backgroundColor, 1, 5);

  const availableLanguagesStrings =
    availableLanguages &&
    availableLanguages.languages.map(({ isoCode }) => isoCode);

  const hideLanguageDropdown = !(
    availableLanguagesStrings ||
    currentLanguage ||
    onLanguageSelection ||
    showLanguageSelector
  );

  const languageDropdown = !showCurrencySelector && (
    <StyledNavItem className="float-right">
      <StyledPointer className="float-right">
        <AppLanguageSelector
          outline={isEmbedded}
          availableLanguages={availableLanguagesStrings}
          currentLanguage={currentLanguage}
          onLanguageSelection={onLanguageSelection}
          backgroundColor={backgroundColor}
          fontColor={fontColor}
          hide={hideLanguageDropdown}
        />
      </StyledPointer>
    </StyledNavItem>
  );

  const openCartButton =
    onCartToggle &&
    withBadge(CartIcon)({
      ariaLabel: t("ariaLabelOpenSummary", {
        defaultValue: "Open summary",
      }),
      badge: {
        color: fontColor,
        hidden: cartAmount === 0,
        size: 7,
        value: cartAmount,
        onClick: onCartToggle,
      },
      className: "float-right",
      color: fontColor,
      onClick: onCartToggle,
      size: 26,
    });

  const closeCartButton = (
    <StyledPointer
      aria-label={t("ariaLabelCloseIcon", { defaultValue: "Close" })}
      role="button"
      className="flex-grow-0 flex-shrink-0 float-right"
      tabIndex={0}
      onKeyPress={onCartToggle}
    >
      <CloseIcon onClick={onCartToggle} color={fontColor} />
    </StyledPointer>
  );

  const cartAction = isCartOpen ? closeCartButton : openCartButton;

  const renderGoBack = () => {
    if (!showGoBack || !onGoBack || !theme.isMobile || hideGoBack) {
      return;
    }

    return (
      <StyledPointer className={"float-left"}>
        <StyledPointer
          aria-label={t("ariaLabelGoBackButton", {
            defaultValue: "Go to the previous page",
          })}
          className="flex-grow-0 flex-shrink-0"
        >
          <BackArrowIcon onClick={onGoBack} color={backArrowColor} />
        </StyledPointer>
      </StyledPointer>
    );
  };

  const onLogoClickEnable = false;

  const goToFirstPage = () => {
    if (!onLogoClickEnable) {
      return;
    }

    const { venueId, queueId, productId } = getIdsFromRouter();

    if (clearAll) {
      clearAll();
    }

    PageRouter.goToFirstPage(
      firstPage || getFirstPageFromRouter(),
      venueId,
      queueId,
      productId
    );
  };

  const renderActionButton = () => {
    const currencySelector = showCurrencySelector && (
      <StyledNavItem className="float-right">
        <StyledPointer className={"float-right"}>
          <AppCurrencySelector
            backgroundColor={backgroundColor}
            fontColor={fontColor}
          />
        </StyledPointer>
      </StyledNavItem>
    );
    if (!theme.isMobile) {
      return null;
    }

    return !showCart ? (
      <>
        {currencySelector}
        {languageDropdown}
      </>
    ) : (
      <StyledPointer className={"float-right"}>{cartAction}</StyledPointer>
    );
  };

  return !theme.isMobile && isEmbedded ? (
    <></>
  ) : (
    <StyledNav
      id="app-header"
      className={"nav-fill"}
      isEmbedded={isEmbedded}
      style={{ flexWrap: "nowrap" }}
      role="presentation"
    >
      <StyledNavItem>{renderGoBack()}</StyledNavItem>
      {isEmbedded || !theme.logo ? (
        <></>
      ) : (
        <StyledImage
          src={`${config.baseApiUrl}${theme.logo}`}
          alt={t("brandLogoAltText", { defaultValue: "Brand logo" })}
          className="m-auto"
          onClick={goToFirstPage}
          onLogoClickEnable={onLogoClickEnable}
        />
      )}
      <StyledNavItem>{renderActionButton()}</StyledNavItem>
    </StyledNav>
  );
};

export const AppNavbar = withTheme(AppNavbarBase);
