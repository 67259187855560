import ButterToast, { POS_RIGHT, POS_TOP } from "butter-toast";
import "butter-toast/dist/lean.min.js";
import i18next from "i18next";
import { fromJS } from "immutable";
import { NextComponentType, NextPageContext } from "next";
import withRedux from "next-redux-wrapper";
import App from "next/app";
import React from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { Store } from "redux";
import { i18n as initialI18nInstance } from "../../i18n/i18n";
import { ApplicationState, initStore } from "../store";
import Layout from "./layout/LayoutContainer";
import Analytics from "../components/analytics/Analytics";
import ReactDOM from "react-dom";
import "moment/min/locales";

// @ts-ignore - import for styled components react css prop
import * as types from "styled-components/cssprop";
import { Router } from "next/router";
import { VideoMeetingChatProvider } from "./bookingVideoMeeting/VideoMeetingChatProvider";
import { MeetingProvider } from "amazon-chime-sdk-component-library-react";
export interface MyAppProps {
  store: Store<ApplicationState>;
  i18nInitialProps: I18nInitialProps;
}

export interface I18nInitialProps {
  i18n: i18next.i18n;
  initialI18nStore: any;
  initialLanguage: any;
}

export interface InitialProps {
  ctx: NextPageContext;
  Component: NextComponentType;
}

class MyApp extends App<MyAppProps> {
  public static async getInitialProps({
    ctx,
    Component,
  }: InitialProps): Promise<any & { i18nInitialProps: I18nInitialProps }> {
    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {};
    const i18nInitialProps = ctx.req
      ? initialI18nInstance.getInitialProps(ctx.req, "translations")
      : {};

    return { pageProps, i18nInitialProps };
  }

  public componentDidMount() {
    Router.events.on("routeChangeStart", () => {
      /*
        https://github.com/vercel/next.js/issues/7681
        Tab focus is not on top of the page. This allows to that behaviour
       */
      document.body.setAttribute("tabIndex", "-1");
      document.body.focus();
    });

    document.body.addEventListener("blur", () => {
      document.body.removeAttribute("tabIndex");
    });
  }

  public render() {
    const { Component, pageProps, store, i18nInitialProps } = this.props;

    /*

        This is required to be like this since when importing axe we need to have the window defined.
        These rules need to be disabled because on the first render we don't have it set and this would
        cause issues to show on console.

        disableDeduplicate makes so that every rerender we have the issues on the console.

        A complete list of the rules can be found here: https://github.com/dequelabs/axe-core/blob/develop/doc/rule-descriptions.md

    */
    if (
      typeof window !== "undefined" &&
      process.env.NODE_ENV === "development"
    ) {
      const axeConfig = {
        rules: [
          {
            id: "document-title",
            enabled: false,
          },
          {
            id: "html-has-lang",
            enabled: false,
          },
          {
            id: "html-lang-valid",
            enabled: false,
          },
        ],
        disableDeduplicate: true,
      };

      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const axe = require("@axe-core/react");
      axe(React, ReactDOM, 1000, axeConfig);
    }

    return (
      <>
        <Provider store={store}>
          <ButterToast
            position={{ vertical: POS_TOP, horizontal: POS_RIGHT }}
            timeout={Infinity}
          />
          <Analytics>
            <MeetingProvider>
              <VideoMeetingChatProvider>
                <I18nextProvider {...i18nInitialProps}>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </I18nextProvider>
              </VideoMeetingChatProvider>
            </MeetingProvider>
          </Analytics>
        </Provider>
      </>
    );
  }
}

export default withRedux(() => initStore(), {
  deserializeState: (state: ApplicationState) => fromJS(state),
})(MyApp);
