import { ActionsObservable, ofType } from "redux-observable";
import { EMPTY, forkJoin, Observable, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { catchError, mergeMap } from "rxjs/operators";
import config from "../../../config";
import { getBWID } from "../../utils/getBWID";
import {
  AddAttachment,
  DeleteAttachment,
  deleteAttachmentFail,
  deleteAttachmentSuccess,
  PreSignedUrlRequestCompleted,
  preSignedUrlRequestCompleted,
  uploadAttachmentsCompleted,
} from "./actionCreators";
import { TypeKeys } from "./actionTypes";
import { getFileId } from "./selectors";
import { DropZoneFile, SelectedFile, UploadStatus } from "./types";

const presignedUrlRequest = (
  { reservationId, questionId, isConfirmationPage }: AddAttachment,
  file: DropZoneFile
): Observable<SelectedFile> => {
  const responsePayload: SelectedFile = {
    questionId,
    file,
    uploadStatus: UploadStatus.SUCCESS,
  };

  return ajax({
    method: "POST",
    url: `${config.baseApiUrl}/${getBWID()}/${
      isConfirmationPage ? "bookings" : "reservations"
    }/${reservationId}/customer-answers/files?questionId=${questionId}&filename=${
      file.filename
    }`,
  }).pipe(
    mergeMap(({ response }) =>
      of({ ...responsePayload, presignedUrl: response })
    ),
    catchError(({ response }) =>
      of({
        ...responsePayload,
        message: response,
        uploadStatus: UploadStatus.ERROR,
      })
    )
  );
};

export const addAttachmentEpic = (
  action$: ActionsObservable<AddAttachment>
) => {
  return action$.pipe(
    ofType(TypeKeys.ADD_ATTACHMENT),
    mergeMap((action: AddAttachment) => {
      const parallelObservables = action.files.map((file: DropZoneFile) =>
        presignedUrlRequest(action, file)
      );
      return forkJoin(parallelObservables).pipe(
        mergeMap((res: SelectedFile[]) => {
          return of(preSignedUrlRequestCompleted(res));
        })
      );
    })
  );
};

export const uploadToS3Request = (
  data: SelectedFile
): Observable<SelectedFile> => {
  if (data.presignedUrl && data.file) {
    return ajax({
      url: data.presignedUrl.uploadUrl,
      method: "PUT",
      headers: {
        "Content-Type": data.presignedUrl.mimeType,
      },
      body: data.file.file,
    }).pipe(
      mergeMap(() => of({ ...data, uploadStatus: UploadStatus.SUCCESS })),
      catchError(({ response }) =>
        of({ ...data, message: response, uploadStatus: UploadStatus.ERROR })
      )
    );
  }

  return EMPTY;
};

export const preSignedUrlRequestCompletedEpic = (
  action$: ActionsObservable<PreSignedUrlRequestCompleted>
) => {
  return action$.pipe(
    ofType(TypeKeys.PRESIGNED_URL_REQUEST_COMPLETED),
    mergeMap((action: PreSignedUrlRequestCompleted) => {
      const successOnes = action.payload.filter(
        (response) => response.uploadStatus === UploadStatus.SUCCESS
      );
      const failedOnes = action.payload.filter(
        (response) => response.uploadStatus === UploadStatus.ERROR
      );
      const parallelS3UploadObservables = successOnes.map(
        (data: SelectedFile) => uploadToS3Request(data)
      );

      if (parallelS3UploadObservables.length > 0) {
        return forkJoin(parallelS3UploadObservables).pipe(
          mergeMap((res: SelectedFile[]) => {
            return of(uploadAttachmentsCompleted([...res, ...failedOnes]));
          })
        );
      }

      if (failedOnes.length > 0) {
        return of(uploadAttachmentsCompleted([...failedOnes]));
      }

      return EMPTY;
    })
  );
};

const deleteAttachmentFromS3Request = (
  { questionId, index, reservationId, isConfirmationPage }: DeleteAttachment,
  fileId: string | number
) => {
  return ajax({
    method: "DELETE",
    url: `${config.baseApiUrl}/${getBWID()}/${
      isConfirmationPage ? "bookings" : "reservations"
    }/${reservationId}/customer-answers/files/${fileId}`,
  }).pipe(
    mergeMap(() => of(deleteAttachmentSuccess(questionId, index))),
    catchError(() => of(deleteAttachmentFail(questionId, index)))
  );
};

export const deleteAttachmentEpic = (action$: any, store: any) => {
  return action$.pipe(
    ofType(TypeKeys.DELETE_ATTACHMENT),
    mergeMap((action: DeleteAttachment) => {
      const fileIdToDelete = getFileId(
        store.value,
        action.questionId,
        action.index
      );

      if (fileIdToDelete) {
        return deleteAttachmentFromS3Request(action, fileIdToDelete);
      }

      return EMPTY;
    })
  );
};
