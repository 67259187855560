const SENDER = "Qudini";

export enum SchedulingType {
  APPOINTMENT = "Appointment",
  EVENT = "Event"
}

export enum AnalyticsEvent {
  CONFIRMATION = "Confirmation",
  RESCHEDULE_CONFIRMATION = "RescheduleConfirmation",
  CANCELLATION = "Cancellation",
  ROUTE_CHANGE_COMPLETE = "RouteChangeComplete"
}

export interface AnalyticsData {
  customerIdentifier?: string;
  url?: string;
}

interface PostMessageEvent {
  schedulingType: SchedulingType;
  analyticsEvent: AnalyticsEvent;
  analyticsData?: AnalyticsData;
}

export const sendAnalyticsEventToParentWindow = (event: PostMessageEvent) => {
  if (parent) {
    parent.postMessage(
      {
        customMessageSender: SENDER,
        ...event
      },
      "*"
    );
  }
};
