export enum TimePeriod {
  earlyMorning = "earlyMorning",
  morning = "morning",
  afternoon = "afternoon",
  evening = "evening",
}

export enum Availability {
  Available,
  Unavailable,
  Closed,
}

export interface SlotsCalendar {
  date: string;
  notAvailableYet: boolean;
  unAvailable: boolean;
  venueClosed: boolean;
  venueFullyBooked: boolean;
  slots: Slot[];
}

export interface Slot {
  startDate: string;
  endDate: string;
  venueId?: number;
}

type TimePeriodTypes = keyof typeof TimePeriod;

export type PeriodSlots = { [T in TimePeriodTypes]: Slot[] };
