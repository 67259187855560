import { Map } from "immutable";
import {
  ApiLanguages,
  Currency,
  CustomerDetails,
  Policies,
  Settings,
  StepNames,
  Theme,
} from "../../shared";
import { ApplicationState } from "../../store";

/* Api selectors */

const getApiState = (state: ApplicationState): Map<string, any> => {
  return state.api;
};

/* Language selectors */

const getLanguages = (state: ApplicationState): Map<string, any> | void => {
  const api = getApiState(state);

  if (!api) {
    return;
  }

  return api.get("LANGUAGES");
};

export const isLoadingLanguages = (state: ApplicationState): boolean => {
  const languages = getLanguages(state);

  if (!languages) {
    return true;
  }

  return languages.get("isLoading") as boolean;
};

export const getLanguagesResponse = (
  state: ApplicationState
): ApiLanguages | void => {
  const languages = getLanguages(state);

  if (!languages || !languages.get("payload")) {
    return;
  }

  return languages.getIn(["payload", "response"]) as ApiLanguages;
};

/* Currencies selectors */
const getCurrencies = (state: ApplicationState): Map<string, any> | void => {
  const api = getApiState(state);

  if (!api) {
    return;
  }

  return api.get("CURRENCIES");
};

export const isLoadingCurrencies = (state: ApplicationState): boolean => {
  const currencies = getCurrencies(state);

  if (!currencies) {
    return false;
  }

  return currencies.get("isLoading") as boolean;
};

export const getCurrenciesResponse = (
  state: ApplicationState
): Currency[] | undefined => {
  const currencies = getCurrencies(state);

  if (!currencies || !currencies.get("payload")) {
    return;
  }

  return currencies.getIn(["payload", "response", "currencies"]);
};

/* Settings selectors */

const getSettings = (state: ApplicationState): Map<string, any> | void => {
  const api = getApiState(state);

  if (!api) {
    return;
  }

  return api.get("SETTINGS");
};

export const isLoadingSettings = (state: ApplicationState): boolean => {
  const settings = getSettings(state);

  if (!settings) {
    return true;
  }

  return settings.get("isLoading") as boolean;
};

export const getSettingsError = (state: ApplicationState): Error | void => {
  const settings = getSettings(state);

  if (!settings || !settings.get("error")) {
    return;
  }

  return settings.getIn(["error"]);
};

export const getSettingsResponse = (
  state: ApplicationState
): Settings | undefined => {
  const settings = getSettings(state);

  if (!settings || !settings.get("payload")) {
    return;
  }

  return settings.getIn(["payload", "response"]) as Settings;
};

const getSsoCustomerDetails = (
  state: ApplicationState
): Map<string, any> | void => {
  const api = getApiState(state);

  if (!api) {
    return;
  }

  return api.get("SSO_CUSTOMER_DETAILS");
};

export const getSsoCustomerDetailsError = (
  state: ApplicationState
): Error | void => {
  const ssoCustomerDetails = getSsoCustomerDetails(state);

  if (!ssoCustomerDetails || !ssoCustomerDetails.get("error")) {
    return;
  }

  return ssoCustomerDetails.getIn(["error"]);
};

export const getSsoCustomerDetailsResponse = (
  state: ApplicationState
): CustomerDetails | undefined => {
  const ssoCustomerDetails = getSsoCustomerDetails(state);

  if (!ssoCustomerDetails || !ssoCustomerDetails.get("payload")) {
    return;
  }

  return ssoCustomerDetails.getIn(["payload", "response"]);
};

export const isLoadingSsoCustomerDetails = (
  state: ApplicationState,
  defaultValue: boolean = false
) => {
  const bookingState = state.api.get("SSO_CUSTOMER_DETAILS");
  if (!bookingState) {
    return defaultValue;
  }

  return bookingState.get("isLoading");
};

/* Style settings selectors */

const getTheme = (state: ApplicationState): Map<string, any> | void => {
  const api = getApiState(state);

  if (!api) {
    return;
  }

  return api.get("THEME");
};

export const isLoadingTheme = (state: ApplicationState): boolean => {
  const style = getTheme(state);

  if (!style) {
    return true;
  }

  return style.get("isLoading") as boolean;
};

export const getThemeResponse = (state: ApplicationState): Theme | void => {
  const style = getTheme(state);

  if (!style || !style.get("payload")) {
    return;
  }

  return style.getIn(["payload", "response"]) as Theme;
};

/* Policies Selectors */

const getPolicies = (state: ApplicationState): Map<string, any> | void => {
  const api = getApiState(state);

  if (!api) {
    return;
  }

  return api.get("POLICIES");
};

export const isLoadingPolicies = (state: ApplicationState): boolean => {
  const policies = getPolicies(state);

  if (!policies) {
    return true;
  }

  return policies.get("isLoading") as boolean;
};

export const getPoliciesResponse = (
  state: ApplicationState
): Policies | void => {
  const policies = getPolicies(state);

  if (!policies || !policies.get("payload")) {
    return;
  }

  return policies.getIn(["payload", "response"]) as Policies;
};

/* Layout selectors */

const getLayoutState = (state: ApplicationState): Map<string, any> | void => {
  return state.layout;
};

export const getCurrentLanguage = (state: ApplicationState): string => {
  const layout = getLayoutState(state);

  const currentLanguage = layout && layout.get("currentLanguage");

  return currentLanguage as string;
};

export const getFirstPage = (state: ApplicationState): StepNames => {
  const layout = getLayoutState(state);

  return layout && layout.get("firstPage");
};

export const getBookingStatus = (
  state: ApplicationState
): string | undefined => {
  const api = getApiState(state);

  if (!api) {
    return;
  }

  return api.getIn(["BOOKING", "payload", "response", "status"]);
};

export const getCartAmount = (state: ApplicationState): number => {
  let cartAmount = 0;

  const api = getApiState(state);
  const products = state.products;

  if (api.getIn(["SINGLE_PRODUCT", "payload", "response", "id"])) {
    cartAmount++;
  }
  if (products.get("selectedAddOns")) {
    cartAmount += products.get("selectedAddOns").length;
  }

  return cartAmount;
};
