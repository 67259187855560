import { fromJS } from "immutable";
import { getDefaultLanguage, i18n } from "../../../i18n/i18n";
import {
  CHANGE_LANGUAGE,
  LayoutActionTypes,
  LayoutState,
  SET_FIRST_PAGE,
  SET_LANGUAGES
} from "./types";

export const initialState = fromJS({
  currentLanguage: getDefaultLanguage()
} as LayoutState);

const reducer = (state = initialState, action: LayoutActionTypes) => {
  switch (action.type) {
    case CHANGE_LANGUAGE: {
      state = state.set("currentLanguage", action.language.toLowerCase());
      i18n.changeLanguage(action.language.toLowerCase());
      document.documentElement.lang = action.language.toLocaleLowerCase();
      return state;
    }
    case SET_LANGUAGES: {
      i18n.loadLanguages(
        action.apiLanguagesObj.languages.map(({ isoCode }) => isoCode)
      );
      state = state.set(
        "currentLanguage",
        action.apiLanguagesObj.defaultLanguageIsoCode
      );
      i18n.changeLanguage(action.apiLanguagesObj.defaultLanguageIsoCode);
      return state;
    }
    case SET_FIRST_PAGE: {
      state = state.set("firstPage", action.firstPage);
      return state;
    }
    default:
      return state;
  }
};

export { reducer as layoutReducer };
