export const fontRegularWeight = 400;
export const fontMediumWeight = 500;
export const fontStrongWeight = "bold";

export default {
  heading1: {
    height: 30,
    size: 24,
    weight: fontMediumWeight
  },

  heading2: {
    height: 24,
    size: 18,
    weight: fontMediumWeight
  },

  heading3: {
    height: 18,
    size: 14,
    weight: fontMediumWeight
  },

  paragraph1: {
    height: 18,
    size: 16,
    weight: fontRegularWeight
  },

  paragraph2: {
    height: 18,
    size: 14,
    weight: fontRegularWeight
  },

  paragraph3: {
    height: 16,
    size: 12,
    weight: fontMediumWeight
  },

  paragraph4: {
    height: 16,
    size: 11,
    weight: fontRegularWeight
  },

  link: {
    height: 16,
    size: 12,
    weight: fontStrongWeight
  }
};
