import React from "react";
import { useTranslation } from "react-i18next";
import { ProductIcon, Service, Settings, Theme } from "../../../../shared";
import { SummaryCard } from "../summaryCard";
import { ProductDetails } from "./productDetails";

export interface ProductCardProps {
  service: Service;
  linkText?: string;
  onLinkClick?: () => void;
  selectedAddOns: Service[] | void;
  theme?: Theme;
  settings: Settings;
  loading?: boolean;
}

type Props = ProductCardProps;

export const ProductSummary = ({
  service,
  linkText,
  onLinkClick,
  selectedAddOns,
  theme,
  settings,
  loading,
}: Props) => {
  const { t } = useTranslation();

  return (
    <SummaryCard
      id={`summary-card-service`}
      icon={
        <ProductIcon
          size={14}
          title={`${t("service", { defaultValue: "Service" })} icon`}
        />
      }
      linkText={linkText}
      onLinkClick={onLinkClick}
      title={t("service", { defaultValue: "Service" })}
    >
      {loading ? (
        <div className="animated-background" style={{ height: 133 }} />
      ) : (
        <ProductDetails
          selectedAddOns={selectedAddOns}
          settings={settings}
          service={service}
          theme={theme}
        />
      )}
    </SummaryCard>
  );
};
