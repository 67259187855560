import { Reducer } from "redux";
import { QUEUE_SELECTED } from "./constants";

export interface QueueState {
  id?: string;
}

const initialState: QueueState = {};

const reducer: Reducer = (state: QueueState = initialState, action) => {
  switch (action.type) {
    case QUEUE_SELECTED:
      return { ...state, id: action.queueId };
    default: {
      return state;
    }
  }
};

export { reducer as queueReducer };
