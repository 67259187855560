import React from "react";

import {
  allowedOverflow,
  AppParagraph2,
  AppParagraph3,
  Service,
  Theme,
} from "../../../../shared";
import { useTranslation } from "react-i18next";
import { humanizeTime } from "../../../../utils/time";
import { renderPrice } from "../utils";

export interface ProductRowProps {
  service: Service;
  index: number;
  theme?: Theme;
  showServiceDuration: boolean;
  showServicePricing: boolean;
  currency: string | void;
  showDeposit: boolean;
  showRefundable: boolean;
}

export const ProductRow = ({
  service: { price, id, isDeposit, serveTimeInMinutes, isRefundable, name },
  showServicePricing,
  showRefundable,
  showDeposit,
  theme,
  currency,
  showServiceDuration,
  index,
}: ProductRowProps) => {
  const { t } = useTranslation();

  const getPriceDetailsString = () => {
    if (!showServicePricing || !price || Number(price) === 0) {
      return "";
    }

    const details = [];

    if (showDeposit && isDeposit) {
      details.push(t("deposit", { defaultValue: "Deposit" }));
    }

    if (showRefundable) {
      details.push(
        isRefundable
          ? t("refundable", { defaultValue: "Refundable" })
          : t("notRefundable", { defaultValue: "Not refundable" })
      );
    }

    return details.join(", ");
  };

  const priceDetailsString = getPriceDetailsString();

  return (
    <div className={"w-100 mb-3"}>
      <div className={"w-100 d-flex flex-row justify-content-between mb-1"}>
        <AppParagraph2
          id={`summary-card-service-name-${id + index}`}
          className={"mr-2 text-left"}
          style={{
            ...allowedOverflow,
            color: theme?.body.font.mainTextColor,
          }}
        >
          {index === 0 ? name : `+ ${name}`}
        </AppParagraph2>
        {showServicePricing && (
          <AppParagraph2
            id={`summary-card-service-price-${id + index}`}
            style={allowedOverflow}
          >
            {renderPrice(Number(price), currency)}
          </AppParagraph2>
        )}
      </div>
      {showServicePricing && priceDetailsString && (
        <div className={"w-100 d-flex flex-row"}>
          <AppParagraph3
            fontSize={10}
            style={{
              ...allowedOverflow,
              color: theme?.body.font.secondaryTextColor,
            }}
          >
            {priceDetailsString}
          </AppParagraph3>
        </div>
      )}
      {showServiceDuration && serveTimeInMinutes && serveTimeInMinutes !== 0 && (
        <div className={"w-100 d-flex flex-row"}>
          <AppParagraph3
            id={`summary-card-service-duration-${id + index}`}
            style={{
              ...allowedOverflow,
              color: theme?.body.font.secondaryTextColor,
            }}
          >
            {humanizeTime(serveTimeInMinutes)}
          </AppParagraph3>
        </div>
      )}
    </div>
  );
};
