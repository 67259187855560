export enum DisplayPolicyInOptions {
  FIRST_STEP_IN_JOURNEY = "FIRST_STEP_IN_JOURNEY",
  INLINE_IN_DETAILS = "INLINE_IN_DETAILS",
  NOT_SHOW = "NOT_SHOW"
}

export interface Policies {
  cookiePolicy: {
    url?: string | null;
  };
  privacyPolicy: {
    url?: string | null;
    displayPolicy: boolean;
    displayPolicyIn: DisplayPolicyInOptions;
    policyHeaderText: string;
    policyText: string;
    policyHyperlinkText: string;
    agreeButtonText: string;
    disagreeButtonText: string;
  };
  termsAndConditionsPolicy: {
    url?: string | null;
    enableOnCustomerDetails?: boolean;
  };
}
