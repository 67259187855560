const Icons = {
  add: "/static/icons/add.svg",
  address: "/static/icons/address.svg",
  advisor: "/static/icons/advisor.svg",
  alert: "/static/icons/alert.svg",
  anyAdvisor: "/static/icons/any-advisor.svg",
  arrowDown: "/static/icons/arrow-down.svg",
  backArrow: "/static/icons/back-arrow.svg",
  calendar: "/static/icons/calendar.svg",
  cancellation: "/static/icons/cancellation.svg",
  cart: "/static/icons/cart.svg",
  close: "/static/icons/close.svg",
  cycling: "/static/icons/cycling.svg",
  delete: "/static/icons/delete.svg",
  driving: "/static/icons/driving.svg",
  edit: "/static/icons/edit.svg",
  email: "/static/icons/email.svg",
  error: "/static/icons/error-illustration.svg",
  fail: "/static/icons/error.svg",
  googleIcon: "/static/icons/google.svg",
  group: "/static/icons/group.svg",
  hideContent: "/static/icons/hide-content.svg",
  infoCircle: "/static/icons/info-circle.svg",
  language: "/static/icons/language.svg",
  next: "/static/icons/next.svg",
  pin: "/static/icons/pin.svg",
  plus: "/static/icons/plus.svg",
  product: "/static/icons/product.svg",
  remove: "/static/icons/remove.svg",
  selected: "/static/icons/selected.svg",
  selectedMarker: "/static/icons/selected-marker.svg",
  shape: "/static/icons/shape.svg",
  starEmpty: "/static/icons/star-empty.svg",
  starFull: "/static/icons/star-full.svg",
  starInvalid: "/static/icons/star-invalid.svg",
  summaryAdvisor: "/static/icons/summary-advisor.svg",
  telephone: "/static/icons/telephone.svg",
  time: "/static/icons/time.svg",
  transport: "/static/icons/transport.svg",
  upload: "/static/icons/upload.svg",
  userLocation: "/static/icons/user-location.svg",
  venueMarker: "/static/icons/venue-marker.svg",
  walking: "/static/icons/walking.svg",
  fullscreen: "/static/icons/fullscreen.svg",
  videoMeeting: {
    callEnd: "/static/icons/videoMeeting/call-end.svg",
    cameraFlip: "/static/icons/videoMeeting/camera-flip.svg",
    cameraOff: "/static/icons/videoMeeting/camera-off.svg",
    cameraOn: "/static/icons/videoMeeting/camera-on.svg",
    blurOn: "/static/icons/videoMeeting/blur-on.svg",
    blurOff: "/static/icons/videoMeeting/blur-off.svg",
    chat: "/static/icons/videoMeeting/chat.svg",
    muteOff: "/static/icons/videoMeeting/mute-off.svg",
    muteOn: "/static/icons/videoMeeting/mute-on.svg",
    participants: "/static/icons/videoMeeting/participants.svg",
    popout: "/static/icons/videoMeeting/popout.svg",
    shareScreenOn: "/static/icons/videoMeeting/share-screen-on.svg",
    shareScreenOff: "/static/icons/videoMeeting/share-screen-off.svg",
    volumeUp: "/static/icons/videoMeeting/volume-up.svg",
    settings: "/static/icons/videoMeeting/settings.svg",
  },
};

export default Icons;
