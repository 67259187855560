import React from "react";
import { styled } from "../../styles/theme/themeHelper";
import { AppBadge, AppBadgeProps } from "../components/appBadge";

const BadgeContainerStyled = styled.div`
  position: absolute;
  top: -5px;
  right: -11px;
  display: flex;
`;

const ContainerStyled = styled.div`
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

interface WithBadgeProps {
  hidden: boolean;
}

interface WithBadgeHocProps {
  badge: AppBadgeProps & WithBadgeProps;
  ariaLabel?: string;
}

type Props = WithBadgeHocProps;

export const withBadge = <P extends object>(
  Component: React.ComponentType<P>
) => {
  const WithBadge: React.FunctionComponent<Props & P> = ({
    badge,
    ...props
  }) => {
    const { hidden, ...badgeProps } = badge;

    return (
      <ContainerStyled
        tabIndex={0}
        role="button"
        onKeyPress={badge.onClick}
        aria-label={props.ariaLabel}
        onClick={badge.onClick}
      >
        <Component {...(props as P)} />

        {!hidden && (
          <BadgeContainerStyled>
            <AppBadge {...badgeProps} />
          </BadgeContainerStyled>
        )}
      </ContainerStyled>
    );
  };

  return WithBadge;
};
