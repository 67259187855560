import * as React from "react";
import { css } from "styled-components";
import { Theme } from "..";
import fonts, {
  fontMediumWeight,
  fontRegularWeight,
} from "../../styles/theme/fonts";
import {
  getColorContrast,
  getDefaultColor,
  styled,
} from "../../styles/theme/themeHelper";

export const AppHeadingStyle = (props: ThemeProps) => css`
  opacity: ${props.theme.logo ? 1 : 0};
  transition: opacity 0.5s ease-in-out;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin: 0;
  font-size: ${props.fontSize + "px"};
  line-height: ${props.lineHeight + "px"};
  color: ${props.color};
  font-weight: ${props.fontWeight};

  &.color-text1 {
    color: ${props.theme.body.font.mainTextColor};
  }
  &.color-text2 {
    color: ${props.theme.body.font.secondaryTextColor};
  }
  &.color-text3 {
    color: ${getColorContrast(props.theme.body.font.secondaryTextColor, 0.5)};
  }
  &.color-text4 {
    color: ${getColorContrast(props.theme.body.font.mainTextColor, 0.5)};
  }
  &.color-text4-paragraph {
    color: ${getDefaultColor(
      props.theme.body.buttons.productIconTextColor,
      props.theme.body.font.mainTextColor
    )};
  }
  &.color-text5 {
    color: ${getColorContrast(
      props.theme.body.buttons.productSecondaryTextColor,
      0.5
    )};
  }

  &.font-regular {
    font-weight: ${fontRegularWeight};
  }
  &.font-medium {
    font-weight: ${fontMediumWeight};
  }
`;

export interface AppHeadingThemeProps {
  color?: string;
  fontSize?: number;
  fontWeight?: number | string;
  lineHeight?: number;
}

type ThemeProps = { theme: Theme } & AppHeadingThemeProps;

type Props = React.HTMLAttributes<ThemeProps>;

export const AppHeading1 = styled.h1<Props>`
  ${(props) =>
    AppHeadingStyle({
      color: props.theme.body.font.mainTextColor,
      fontSize: fonts.heading1.size,
      fontWeight: fonts.heading1.weight,
      lineHeight: fonts.heading1.height,
      ...props,
    })};
`;

export const AppHeading2 = styled.h2<Props>`
  ${(props) =>
    AppHeadingStyle({
      color: props.color || props.theme.body.font.mainTextColor,
      fontSize: fonts.heading2.size,
      fontWeight: fonts.heading2.weight,
      lineHeight: fonts.heading2.height,
      ...props,
    })}
`;

export const AppHeading3 = styled.h3<Props>`
  ${(props) =>
    AppHeadingStyle({
      color: props.theme.body.font.mainTextColor,
      fontSize: fonts.heading3.size,
      fontWeight: fonts.heading3.weight,
      lineHeight: fonts.heading3.height,
      ...props,
    })}
`;

export const AppSubHeading = styled.h2<Props>`
  ${(props) =>
    AppHeadingStyle({
      color: props.theme.body.font.secondaryTextColor,
      fontSize: fonts.heading2.size,
      fontWeight: fonts.heading2.weight,
      lineHeight: fonts.heading2.height,
      ...props,
    })}
`;

export const AppSubHeadingStyled = styled((props) => (
  <AppSubHeading {...props} />
))`
  a {
    color: ${(props) => props.theme.body.buttons.secondaryBackgroundColor};
  }
`;

export const LegendText = styled.legend<Props>`
  color: ${(props) => props.theme.body.font.secondaryTextColor};
`;
