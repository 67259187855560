import React, { useEffect, useState, useMemo } from "react";
import { useRouter } from "next/router";
import { makeRequest } from "../../services/api/actions";
import { RequestData } from "../../services/api/epics/apiRequest";
import config from "../../../config";
import { getBWID } from "../../utils/getBWID";
import { urlHasPart } from "../../utils/urlHasPart";
import { connect } from "react-redux";
import { ApplicationState } from "../../store";
import Bowser from "bowser";
import {
  getProductId,
  getVenueId,
  isLoadingProduct,
  isLoadingVenue,
  isLoadingBooking,
} from "../summary/selectors";
import { useTracking } from "../../utils/useTrackingHook";
import {
  AnalyticsEvent,
  AnalyticsSessionPayload,
  TrackAnalyticsEvent,
} from "./interfaces";
import { getError } from "../errorManager/selectors";
import { ApiError } from "../../shared/interfaces/apiError";
import { Settings } from "../../shared/interfaces/settings";
import {
  getSettingsResponse,
  getSsoCustomerDetailsResponse,
  isLoadingSsoCustomerDetails,
} from "../layout/selectors";
import { TRACK_ROUTE_CHANGE_COMPLETE_EVENT } from "./eventCreators";
import { AnalyticsEventActions } from "./eventActions";
import { CustomerDetails } from "../../shared";

interface AnalyticsProps {
  currentCustomerId?: string;
  sessionId?: string;
  sessionError?: ApiError;
  productId?: string;
  venueId?: string;
  loading?: boolean;
  settings?: Settings | void;
  ssoCustomerDetails?: CustomerDetails;
}

interface AnalyticsDispatchProps {
  analyticsSession: (requestData: RequestData) => void;
  analyticsEvent: (requestData: RequestData) => void;
}

type Props = AnalyticsProps & AnalyticsDispatchProps;

const Analytics: React.FC<Props> = ({
  children,
  sessionId,
  sessionError,
  analyticsSession,
  analyticsEvent,
  venueId,
  productId,
  loading,
  settings,
  ssoCustomerDetails,
}) => {
  const { asPath, query } = useRouter();
  const { kioskId, bookingId } = query;

  React.useEffect(() => {
    window.sessionId = sessionId;
  }, [sessionId]);

  const [isAnsweredQuestions, setIsAnsweredQuestions] = useState<
    undefined | boolean
  >();

  const sendAnalyticsEvent = (data: AnalyticsEvent) => {
    if (
      data.action === AnalyticsEventActions.UPDATE_BUTTON_CUSTOMER_ANSWERS ||
      data.action === AnalyticsEventActions.COMPLETE_BUTTON_CUSTOMER_ANSWERS
    ) {
      setIsAnsweredQuestions(true);
    }

    if (
      !window.sessionId ||
      (data.action === AnalyticsEventActions.ROUTE_CHANGE_COMPLETE &&
        !TRACK_ROUTE_CHANGE_COMPLETE_EVENT) ||
      data.action === AnalyticsEventActions.UPDATE_BUTTON_CUSTOMER_ANSWERS
    ) {
      return;
    }

    analyticsEvent({
      body: data,
      method: "POST",
      url: `${config.baseApiUrl}/${getBWID()}/analytics/sessions/${
        window.sessionId
      }/events`,
    });
  };

  const { Track } = useTracking(
    {
      app: "Qudini: Appointment",
      page: asPath,
    },
    {
      dispatch: (data: TrackAnalyticsEvent) => {
        if (!window.dataLayer) {
          window.dataLayer = [];
        }
        window.dataLayer.push(data);
        sendAnalyticsEvent(data.event);
      },
    }
  );

  const userInfo = useMemo(
    () =>
      process && process.browser
        ? Bowser.parse(window.navigator.userAgent)
        : undefined,
    [process && process.browser]
  );
  const deviceInfo = useMemo(
    () =>
      userInfo && {
        browser: userInfo.browser.name,
        browserVersion: userInfo.browser.version,
        device: `${userInfo.platform.vendor || userInfo.os.name} (${
          userInfo.platform.type
        })`,
        os: userInfo.os.name,
        osVersion: userInfo.os.version,
      },
    [userInfo]
  );
  const referrer = useMemo(
    () =>
      process && process.browser && document.referrer !== ""
        ? document.referrer
        : undefined,
    [process && process.browser, document.referrer]
  );
  const path = useMemo(() => asPath, []);
  const kioskIdentifier = useMemo(() => kioskId as string, []);
  const hasRescheduleUrl = urlHasPart(asPath, "reschedule");
  const isReschedule = !!(
    bookingId &&
    venueId &&
    productId &&
    hasRescheduleUrl
  );
  const hasCancellationUrl = urlHasPart(asPath, "cancellation");
  const isCancelAppointment = !!(
    bookingId &&
    venueId &&
    productId &&
    hasCancellationUrl
  );

  const isManageAppointment = useMemo(() => !!bookingId, []);

  const [recordedCustomerId, setRecordedCustomerId] = useState<
    undefined | string
  >(undefined);
  const [recordedVenueId, setRecordedVenueId] = useState<undefined | string>(
    undefined
  );
  const [recordedProductId, setRecordedProductId] = useState<
    undefined | string
  >(undefined);
  const [recordedIsReschedule, setRecordedIsReschedule] = useState<
    undefined | boolean
  >(undefined);
  const [recordedIsCancelAppointment, setRecordedIsCancelAppointment] =
    useState<undefined | boolean>(undefined);
  const [recordedIsAnsweredQuestions, setRecordedIsAnsweredQuestions] =
    useState<undefined | boolean>(undefined);

  const createAnalyticsSession = () => {
    analyticsSession({
      body: createSessionPayload(),
      method: "POST",
      url: `${config.baseApiUrl}/${getBWID()}/analytics/sessions`,
    });
  };

  const updateAnalyticsSession = () => {
    if (!sessionId) {
      createAnalyticsSession();
      return;
    }

    analyticsSession({
      body: createSessionPayload(),
      method: "PUT",
      url: `${config.baseApiUrl}/${getBWID()}/analytics/sessions/${sessionId}`,
      withCredentials: false,
    });
  };

  const createSessionPayload = (): AnalyticsSessionPayload => {
    const customerId = bookingId as string;

    if (customerId !== recordedCustomerId) {
      setRecordedCustomerId(customerId);
    }
    if (venueId !== recordedVenueId) {
      setRecordedVenueId(venueId);
    }
    if (productId !== recordedProductId) {
      setRecordedProductId(productId);
    }
    if (isReschedule !== recordedIsReschedule) {
      setRecordedIsReschedule(isReschedule);
    }
    if (isCancelAppointment !== recordedIsCancelAppointment) {
      setRecordedIsCancelAppointment(isCancelAppointment);
    }
    if (isAnsweredQuestions !== recordedIsAnsweredQuestions) {
      setRecordedIsAnsweredQuestions(isAnsweredQuestions);
    }

    return {
      ...deviceInfo,
      kioskIdentifier,
      path,
      referrer,
      customerId,
      externalId: ssoCustomerDetails
        ? ssoCustomerDetails.externalId
        : undefined,
      emailAddress: ssoCustomerDetails ? ssoCustomerDetails.email : undefined,
      venueId,
      productId,
      isManageAppointment,
      ...(isReschedule && { isReschedule }),
      ...(isCancelAppointment && { isCancelAppointment }),
      ...(isAnsweredQuestions && { isAnsweredQuestions }),
    };
  };

  const [ready, setReady] = useState(false);

  useEffect(() => {
    // Need to wait for the ssoCustomerDetails before creating the analytics session
    // this ensure the externalId is being sent through
    const ssoReady =
      settings && settings.identityProviderId ? ssoCustomerDetails : true;
    if (settings && ssoReady) {
      setReady(true);
    }
  }, [settings, ssoCustomerDetails]);

  useEffect(() => {
    if (
      !ready ||
      (sessionError && [400, 404].indexOf(sessionError.status) !== -1)
    ) {
      return;
    }

    const shouldUpdate =
      !loading &&
      (venueId !== recordedVenueId ||
        productId !== recordedProductId ||
        (bookingId as string) !== recordedCustomerId ||
        isReschedule !== recordedIsReschedule ||
        isCancelAppointment !== recordedIsCancelAppointment ||
        isAnsweredQuestions !== recordedIsAnsweredQuestions);

    if (shouldUpdate) {
      updateAnalyticsSession();
    } else if (!loading && !sessionId) {
      createAnalyticsSession();
    }
  }, [
    loading,
    venueId,
    productId,
    bookingId,
    ready,
    isReschedule,
    isCancelAppointment,
    isAnsweredQuestions,
  ]);

  return <Track>{children}</Track>;
};

const isLoadingAnalyticsSession = (state: ApplicationState): boolean => {
  const analyticsSessionState = state.api.get("ANALYTICS_SESSION");
  if (!analyticsSessionState) {
    return false;
  }

  return analyticsSessionState.get("isLoading");
};

const getAnalyticsSessionId = (state: ApplicationState): string | undefined => {
  const analyticsSessionState = state.api.get("ANALYTICS_SESSION");
  if (!analyticsSessionState) {
    return undefined;
  }

  return analyticsSessionState.getIn(["payload", "response", "sessionId"]);
};

const mapStateToProps = (state: ApplicationState): AnalyticsProps => ({
  settings: getSettingsResponse(state),
  productId: getProductId(state),
  loading:
    isLoadingAnalyticsSession(state) ||
    isLoadingProduct(state, false) ||
    isLoadingVenue(state, false) ||
    isLoadingBooking(state, false) ||
    isLoadingSsoCustomerDetails(state, false),
  venueId: getVenueId(state),
  sessionId: getAnalyticsSessionId(state),
  sessionError: getError(state, "ANALYTICS_SESSION"),
  ssoCustomerDetails: getSsoCustomerDetailsResponse(state),
});

const mapDispatchToProps: AnalyticsDispatchProps = {
  analyticsEvent: (dataRequest: RequestData) =>
    makeRequest(dataRequest, "ANALYTICS_EVENT"),
  analyticsSession: (dataRequest: RequestData) =>
    makeRequest(dataRequest, "ANALYTICS_SESSION"),
};

export default process && process.browser
  ? connect(mapStateToProps, mapDispatchToProps)(Analytics)
  : React.Fragment;
