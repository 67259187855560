import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseApiUrl } from "../../config";
import { getSingleVenueResponse } from "../components/bookingConfirmation/selectors";
import {
  getCurrenciesResponse,
  getSettingsResponse,
  isLoadingCurrencies,
} from "../components/layout/selectors";
import { makeRequest } from "../services/api/actions";
import { getBWID } from "./getBWID";

let fetching = false;

export const useCurrencies = () => {
  const bwid = getBWID();
  const singleVenue = useSelector(getSingleVenueResponse);
  const noVenue = singleVenue?.id === undefined;
  const dispatch = useDispatch();
  const loading = useSelector(isLoadingCurrencies);
  const settings = useSelector(getSettingsResponse);
  useEffect(() => {
    if (noVenue && !loading && !fetching && settings?.showCurrencySelector) {
      fetching = true;
      dispatch(
        makeRequest(
          {
            url: `${baseApiUrl}/${bwid}/config/currencies`,
            method: "GET",
          },
          "CURRENCIES"
        )
      );
    }
  }, [bwid, dispatch, loading, settings?.showCurrencySelector, noVenue]);

  const currencies = useSelector(getCurrenciesResponse) || [];
  const defaultCurrencyCode = settings?.showCurrencySelector
    ? settings?.defaultCurrencyCode || currencies[0]?.code || ""
    : "";

  return { currencies, defaultCurrencyCode };
};
