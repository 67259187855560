import { Map } from "immutable";
import { Reducer } from "redux";
import { CLEAR_ADDON, CLEAR_ALL } from "../layout/types";
import { SAVE_ADD_ONS, SET_CURRENT_LAYOUT } from "./constants";

export const initialState = Map({
  layoutType: "products",
});

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ALL:
      return state;

    case SET_CURRENT_LAYOUT:
      state = state.set("layoutType", action.layoutType);

      return state;
    case SAVE_ADD_ONS:
      state = state.set("selectedAddOns", action.addOns);

      return state;
    case CLEAR_ADDON:
      state = state.set("selectedAddOns", []);
      return state;
    default: {
      return state;
    }
  }
};

export { reducer as productsReducer };
