import React from "react";
import { Modal, ModalBody, ModalProps } from "reactstrap";
import styled from "styled-components";

const StyledModal = styled(Modal)`
  min-height: 240px;

  &.modal-dialog {
    margin: 10rem auto 0rem auto;
  }

  @media (max-height: 375px) {
    &.modal-dialog {
      margin: 0rem auto;
    }
  }

  @media (min-height: 376px) and (max-height: 414px) {
    &.modal-dialog {
      margin: 3rem auto;
    }
  }

  @media (min-height: 415px) and (max-height: 768px) {
    &.modal-dialog {
      margin: 6rem auto 0rem auto;
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  padding: 1.5rem 2.5rem;

  @media (max-width: 575px) {
    padding: 1.5rem 1.5rem;
  }
`;

export type AppModalProps = ModalProps;

type Props = AppModalProps & { children: React.ReactNode };

export const AppModal: React.FunctionComponent<Props> = ({
  children,
  ...props
}: Props) => {
  return (
    <StyledModal {...props}>
      <StyledModalBody>{children}</StyledModalBody>
    </StyledModal>
  );
};
