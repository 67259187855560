import { Map } from "immutable";
import { ApplicationState } from "../../store";

const getVenue = (state: ApplicationState) => {
  return state.api.get("SINGLE_VENUE");
};

export const isLoadingSingleVenue = (state: ApplicationState) => {
  if (!getVenue(state)) {
    return false;
  }

  return getVenue(state).get("isLoading");
};

export const getSingleVenueResponse = (state: ApplicationState) => {
  if (!getVenue(state) || !getVenue(state).get("payload")) {
    return;
  }

  return getVenue(state).getIn(["payload", "response"]);
};

const getProduct = (state: ApplicationState): Map<string, any> => {
  return state.api.get("SINGLE_PRODUCT");
};

export const isLoadingSingleProduct = (state: ApplicationState): boolean => {
  if (!getProduct(state)) {
    return false;
  }

  return getProduct(state).get("isLoading");
};

export const getSingleProductResponse = (state: ApplicationState) => {
  if (!getProduct(state) || !getProduct(state).get("payload")) {
    return;
  }

  return getProduct(state).getIn(["payload", "response"]);
};

const getCancellation = (state: ApplicationState): Map<string, any> => {
  return state.api.get("CANCELLATION");
};

export const isLoadingCancellation = (state: ApplicationState): boolean => {
  if (!getCancellation(state)) {
    return false;
  }

  return getCancellation(state).get("isLoading");
};

export const getCancellationStatus = (
  state: ApplicationState
): number | void => {
  if (!getCancellation(state) || !getCancellation(state).get("payload")) {
    return;
  }

  return getCancellation(state).getIn(["payload", "status"]);
};

export const getAdvisorName = (state: ApplicationState): string | undefined => {
  return state.api.getIn(["SINGLE_ADVISOR", "payload", "response", "name"]);
};

const getAfterQuestions = (state: ApplicationState) => {
  return state.api.get("AFTER_QUESTIONS");
};

export const isLoadingAfterQuestions = (state: ApplicationState) => {
  if (!getAfterQuestions(state)) {
    return false;
  }

  return getAfterQuestions(state).get("isLoading");
};

export const getAfterQuestionsResponse = (state: ApplicationState) => {
  if (!getAfterQuestions(state) || !getAfterQuestions(state).get("payload")) {
    return [];
  }

  return getAfterQuestions(state).getIn(["payload", "response", "questions"]);
};

const getBeforeQuestions = (state: ApplicationState) => {
  return state.api.get("BEFORE_QUESTIONS");
};

export const isLoadingBeforeQuestions = (state: ApplicationState) => {
  if (!getBeforeQuestions(state)) {
    return false;
  }

  return getBeforeQuestions(state).get("isLoading");
};

export const getBeforeQuestionsResponse = (state: ApplicationState) => {
  if (!getBeforeQuestions(state) || !getBeforeQuestions(state).get("payload")) {
    return [];
  }

  return getBeforeQuestions(state).getIn(["payload", "response", "questions"]);
};

export const getIsLoadingSubmitAnswers = (state: ApplicationState) => {
  return state.api.getIn(["RESERVATION_QUESTIONS", "isLoading"]);
};

export const getSubmitAnswersResult = (state: ApplicationState) => {
  return state.api.getIn(["RESERVATION_QUESTIONS", "payload", "status"]);
};
