import { List } from "immutable";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Spinner } from "reactstrap";
import { Action, Dispatch } from "redux";
import config from "../../../config";
import { LOAD_LANGUAGES_ON_DEMAND, i18n } from "../../../i18n/i18n";
import { makeRequest } from "../../services/api/actions";
import { RequestData } from "../../services/api/epics/apiRequest";
import {
  ApiLanguages,
  Booking,
  CustomerDetails,
  Policies,
  Reservation,
  Service,
  Settings,
  StepNames,
  Theme,
  Venue,
} from "../../shared";
import { Questions } from "../../shared/interfaces/questions";
import { ApplicationState } from "../../store";
import { AppThemeProvider } from "../../styles/components/appThemeProvider";
import { getBWID } from "../../utils/getBWID";
import { getFirstPageFromRouter } from "../../utils/getFirstPageFromRouter";
import PageRouter, { getQueryStringValue } from "../../utils/pageRouter";
import { UseCoordinates, useCoordinates } from "../../utils/useCoordinatesHook";
import { ThemeFixture } from "../../__fixtures__/theme/_fixtures";
import {
  getBookingResponse,
  getReservationResponse,
} from "../details/selectors";
import ApiErrorManagerContainer from "../errorManager/ApiErrorManagerContainer";
import ErrorLayout from "../genericErrorPage/ErrorLayout";
import {
  getIsPriceInvolved,
  getProductsAggregate,
  getSelectedAddOns,
  getSelectedProductHasAddons,
} from "../products/selectors";
import { getResponse, isLoadingQuestions } from "../questions/selectors";
import RestoreDataContainer from "../restoreData/RestoreData";
import { getSingleVenueResponse } from "../summary/selectors";
import * as layoutActions from "./actions";
import LayoutPage from "./LayoutPage";
import {
  getCurrentLanguage,
  getFirstPage,
  getLanguagesResponse,
  getPoliciesResponse,
  getSettingsResponse,
  getThemeResponse,
  isLoadingLanguages,
  isLoadingSettings,
  isLoadingTheme,
  getBookingStatus,
  getCartAmount,
  getSettingsError,
  getSsoCustomerDetailsResponse,
  getSsoCustomerDetailsError,
} from "./selectors";
import * as Sentry from "@sentry/nextjs";
import { useLanguage, UseLanguage } from "../../utils/useLanguageHook";
import { UserCurrencyProvider } from "../../utils/useCurrencyHook";
import { getSingleProductResponse } from "../bookingConfirmation/selectors";
import Cookies from "js-cookie";
import { getCompletePaymentResponse } from "../payment/selectors";

export interface BaseProps {
  children: React.ReactElement;
}

interface StateProps {
  currentLanguage?: string;
  firstPage: StepNames;
  settings?: Settings;
  settingsReady: boolean;
  policies: Policies | void;
  products: List<Service>;
  selectedProductHasAddons?: boolean;
  questions: Questions[];
  apiLanguagesObj?: ApiLanguages | void;
  theme?: Theme | void;
  isLoading: boolean;
  singleVenue?: Venue;
  reservation: Reservation | void;
  bookingStatus?: string;
  cartAmount: number;
  booking?: Booking;
  singleProduct?: Service;
  selectedAddOns: Service[] | void;
  ssoCustomerDetails: CustomerDetails | void;
  ssoCustomerDetailsError: Error | void;
  showPaymentStep: boolean;
  isPaymentAuthorised: boolean;
}

interface DispatchProps {
  clearAll: () => void;
  clearAddOn: () => void;
  clearProduct: () => void;
  clearVenue: () => void;
  clearCoordinates: () => void;
  fetchSettings: () => void;
  fetchPolicies: (language: string) => void;
  fetchLanguages: () => void;
  fetchTheme: () => void;
  setCurrentLanguage: (language: string) => void;
  setFirstPage: (page: StepNames) => void;
  setLanguages: (apiLanguagesObj: ApiLanguages) => void;
  handleReservation: (requestData: RequestData) => void;
  ssoRedirect: () => void;
  fetchSsoCustomerDetails: (requestData: RequestData) => void;
}

export type LayoutContainerProps = BaseProps & StateProps & DispatchProps;

export const UserCoordinatesContext = React.createContext<UseCoordinates>({});
export const UserLanguageContext = React.createContext<UseLanguage>({});

const defaultTheme = new ThemeFixture().withSettingsNotAvailable;

const spinnerFullPageStyle: React.CSSProperties = {
  alignItems: "center",
  display: "flex",
  height: "100%",
  justifyContent: "center",
  position: "absolute",
  width: "100%",
};

export const LayoutContainer = ({
  children,
  currentLanguage,
  firstPage,
  policies,
  products,
  selectedProductHasAddons,
  questions,
  settings,
  settingsReady,
  apiLanguagesObj,
  theme,
  isLoading,
  singleVenue,
  reservation,
  fetchSettings,
  fetchPolicies,
  fetchLanguages,
  fetchTheme,
  setCurrentLanguage,
  setFirstPage,
  setLanguages,
  clearProduct,
  clearCoordinates,
  clearAddOn,
  clearAll,
  clearVenue,
  handleReservation,
  bookingStatus,
  cartAmount,
  booking,
  singleProduct,
  selectedAddOns,
  ssoRedirect,
  fetchSsoCustomerDetails,
  ssoCustomerDetails,
  ssoCustomerDetailsError,
  showPaymentStep,
  isPaymentAuthorised,
}: LayoutContainerProps) => {
  useEffect(() => {
    fetchSettings();
    Sentry.withScope((sentryScope) => {
      sentryScope.setTags({
        domain: window.location.hostname,
        bookingWidgetId: getBWID(),
      });
    });
  }, []);

  const [ssoReady, setSsoReady] = useState(false);
  const [showLoadingOverlay, setShowLoadingOverlay] = useState(isLoading);

  useEffect(() => {
    if (settingsReady) {
      // First part of the SSO journey, based on the settings check for an identity provider is used
      if (settings) {
        if (
          settings.identityProviderId &&
          settings.identityProviderId !== null
        ) {
          const bwToken = Cookies.get("BwToken");
          if (bwToken) {
            // Making sure to not perform the sso login if a token has been provided
            // Validate token and fetch customer details mapped data
            fetchSsoCustomerDetails({
              method: "GET",
              url: `${
                config.baseApiUrl
              }/${getBWID()}/saml/sso/details/${bwToken}`,
            });
          } else {
            ssoRedirect(); // Triggering the logic in `ssoRedirectEpic` to   redirect to the correct SSO login page
          }
        } else {
          setSsoReady(true);
        }
      }
      setShowLoadingOverlay(false);
    }
  }, [settingsReady, settings, fetchSsoCustomerDetails, ssoRedirect]);

  useEffect(() => {
    if (ssoCustomerDetails) {
      setSsoReady(true);
    }
  }, [ssoCustomerDetails]);

  useEffect(() => {
    if (ssoCustomerDetailsError) {
      PageRouter.goToError(undefined, ["token"]);
    }
  }, [ssoCustomerDetailsError]);

  useEffect(() => {
    // Avoid the need to make those api call if the user need SSO authentication
    if (ssoReady) {
      fetchLanguages();
      fetchTheme();
    }
  }, [ssoReady, fetchLanguages, fetchTheme]);

  useEffect(() => {
    if (!firstPage || !!singleProduct) {
      setFirstPage(
        getFirstPageFromRouter(settings, !!singleProduct?.numberOfAddOns)
      );
    }
  }, [firstPage, setFirstPage, settings, singleProduct]);

  // Update i18n apiLanguagesObj after response
  // This is not necessary as it's breaking the "on demand" logic of `i18next-xhr-backend`
  // Since we are using an API to fetch the translations i18n doesn't need to be aware of all the available languages, this is handle on by the language selector component
  useEffect(() => {
    if (!apiLanguagesObj || LOAD_LANGUAGES_ON_DEMAND) {
      // Put the lang attribute on the HTML tag when we fetch the languages
      document.documentElement.lang = i18n.language
        ? i18n.language
        : apiLanguagesObj
        ? apiLanguagesObj.defaultLanguageIsoCode
        : null;
      return;
    }

    // If this is called the application will fetch the translations for all the languages in one go
    setLanguages(apiLanguagesObj);
  }, [apiLanguagesObj, setLanguages]);

  const userCoordinates = useCoordinates(
    undefined,
    getQueryStringValue("location") === "false"
  );

  const userLanguage = useLanguage();

  return (
    <AppThemeProvider theme={theme || defaultTheme}>
      <UserLanguageContext.Provider value={userLanguage}>
        <UserCoordinatesContext.Provider value={userCoordinates}>
          <UserCurrencyProvider>
            <ApiErrorManagerContainer />
            <RestoreDataContainer />
            {showLoadingOverlay ? (
              <div className={"p-4"} style={spinnerFullPageStyle}>
                <Spinner color="dark" type="grow" />
              </div>
            ) : settings ? (
              <LayoutPage
                currentLanguage={currentLanguage}
                firstPage={
                  firstPage ||
                  getFirstPageFromRouter(
                    settings,
                    !!singleProduct?.numberOfAddOns
                  )
                }
                languages={apiLanguagesObj}
                policies={policies}
                fetchPolicies={fetchPolicies}
                products={products}
                selectedProductHasAddons={selectedProductHasAddons}
                questions={questions}
                settings={settings}
                settingsReady={settingsReady}
                singleVenue={singleVenue}
                reservation={reservation}
                setCurrentLanguage={setCurrentLanguage}
                clearProduct={clearProduct}
                clearCoordinates={clearCoordinates}
                clearAddOn={clearAddOn}
                clearVenue={clearVenue}
                clearAll={clearAll}
                handleReservation={handleReservation}
                bookingStatus={bookingStatus}
                cartAmount={cartAmount}
                booking={booking}
                singleProduct={singleProduct}
                selectedAddOns={selectedAddOns}
                showPaymentStep={showPaymentStep}
                isPaymentAuthorised={isPaymentAuthorised}
              >
                {children}
              </LayoutPage>
            ) : (
              <ErrorLayout />
            )}
          </UserCurrencyProvider>
        </UserCoordinatesContext.Provider>
      </UserLanguageContext.Provider>
    </AppThemeProvider>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  const booking = getBookingResponse(state);
  const settingsResponse = getSettingsResponse(state);
  const isPriceInvolved = getIsPriceInvolved(state);
  const isPaymentAuthorised = Boolean(getCompletePaymentResponse(state));
  const isRescheduleJourney = Boolean(booking?.bookingId);
  const isPaymentEnabled = Boolean(settingsResponse?.paymentEnabled);
  const showPaymentStep =
    (isPaymentEnabled && isPriceInvolved && !isRescheduleJourney) ||
    isPaymentAuthorised;
  return {
    apiLanguagesObj: getLanguagesResponse(state),
    currentLanguage: getCurrentLanguage(state),
    firstPage: getFirstPage(state),
    isLoading:
      isLoadingSettings(state) &&
      isLoadingTheme(state) &&
      isLoadingLanguages(state),
    policies: getPoliciesResponse(state),
    products: getProductsAggregate(state),
    selectedProductHasAddons: getSelectedProductHasAddons(state),
    questions: isLoadingQuestions(state) ? [] : getResponse(state),
    settings: settingsResponse || undefined,
    settingsReady: !!(settingsResponse || getSettingsError(state)),
    theme: getThemeResponse(state),
    singleVenue: getSingleVenueResponse(state),
    reservation: getReservationResponse(state),
    bookingStatus: getBookingStatus(state),
    cartAmount: getCartAmount(state),
    booking,
    singleProduct: getSingleProductResponse(state),
    selectedAddOns: getSelectedAddOns(state),
    ssoCustomerDetails: getSsoCustomerDetailsResponse(state),
    ssoCustomerDetailsError: getSsoCustomerDetailsError(state),
    showPaymentStep,
    isPaymentAuthorised,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  const baseURL = config.baseApiUrl;

  return {
    clearAddOn: () => {
      dispatch(layoutActions.clearAddOn());
    },
    clearAll: () => {
      dispatch(layoutActions.clearAll());
    },
    clearVenue: () => {
      dispatch(layoutActions.clearVenue());
    },
    clearProduct: () => {
      dispatch(layoutActions.clearProduct());
    },
    clearCoordinates: () => {
      dispatch(layoutActions.clearCoordinates());
    },
    fetchLanguages: () => {
      dispatch(
        makeRequest(
          {
            method: "GET",
            url: `${baseURL}/${getBWID()}/languages`,
          },
          "LANGUAGES"
        )
      );
    },
    fetchPolicies: (language: string) => {
      dispatch(
        makeRequest(
          {
            method: "GET",
            url: `${baseURL}/${getBWID()}/policies?lang=${language}`,
          },
          "POLICIES"
        )
      );
    },
    fetchSettings: () => {
      dispatch(
        makeRequest(
          {
            method: "GET",
            url: `${baseURL}/${getBWID()}/config/settings`,
          },
          "SETTINGS"
        )
      );
    },
    fetchTheme: () => {
      dispatch(
        makeRequest(
          {
            method: "GET",
            url: `${baseURL}/${getBWID()}/config/theme`,
          },
          "THEME"
        )
      );
    },
    setCurrentLanguage: (language: string) => {
      dispatch(layoutActions.changeLanguage(language));
    },
    setFirstPage: (page: StepNames) => {
      dispatch(layoutActions.setFirstPage(page));
    },
    setLanguages: (apiLanguagesObj: ApiLanguages) => {
      dispatch(layoutActions.setLanguages(apiLanguagesObj));
    },
    handleReservation: (dataRequest: RequestData) => {
      dispatch(makeRequest(dataRequest, "RESERVATION"));
    },
    ssoRedirect: () => dispatch(layoutActions.ssoRedirect()),
    fetchSsoCustomerDetails: (dataRequest: RequestData) =>
      dispatch(makeRequest(dataRequest, "SSO_CUSTOMER_DETAILS")),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContainer);
