import * as React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardFooter } from "reactstrap";
import { withTheme } from "styled-components";
import { Settings, Theme } from "..";
import fonts from "../../styles/theme/fonts";
import Icons from "../../styles/theme/icons";
import {
  getColorContrast,
  getDefaultColor,
  styled,
} from "../../styles/theme/themeHelper";
import { TickIcon } from "./appIcon";
import { MoreInfoLink } from "./appLink";

export interface AppCardProps {
  alignCenter?: boolean;
  cardText: React.ReactChild;
  cardImage?: React.ReactChild;
  selected?: boolean | null;
  highlighted?: boolean;
  id?: string;
  onToggleSelected?: () => void;
  onToggleExpanded?: (() => void) | null;
  settings?: Settings;
  staffCard: boolean;
  disable?: boolean;
}

export const StyledCard = styled(
  ({ highlighted, selected, disable, ...props }) => <Card {...props} />
)<{
  highlighted: boolean;
  selected: boolean;
  disable: boolean;
}>`
  ${(props) => props.disable && `pointer-events: none; opacity: .5`};
  display: flex;
  height: 100%;
  min-height: 130px;
  background-color: ${(props) =>
    props.highlighted
      ? getColorContrast(
          getDefaultColor(
            props.theme.body.buttons.productButtonColor,
            props.theme.body.backgroundColor
          ),
          1,
          0.2
        )
      : getDefaultColor(
          props.theme.body.buttons.productButtonColor,
          props.theme.body.backgroundColor
        )};

  ${(props) =>
    props.selected &&
    `
    border: 1px solid ${getColorContrast(
      getDefaultColor(
        props.theme.body.buttons.productIconTextColor,
        props.theme.body.font.mainTextColor
      )
    )}

    box-shadow: 0 0 0px 1px ${getColorContrast(
      getDefaultColor(
        props.theme.body.buttons.productIconTextColor,
        props.theme.body.font.mainTextColor
      )
    )}

  `}

  a {
    height: 100%;
  }

  ${({ selected, theme }) =>
    selected &&
    `outline: 2px solid ${getColorContrast(
      getDefaultColor(
        theme.body.buttons.productSecondaryTextColor,
        theme.body.buttons.mainBackgroundColor
      )
    )};`}
`;

const CardSelectedIcon = styled(TickIcon)`
  position: absolute;
  top: 15px;
  right: 15px;
`;

const StyledCardImage = styled.div`
  cursor: pointer;
`;

interface CardBodyStylingProps {
  alignCenter: boolean;
}

export const StyledCardBody = styled(CardBody).withConfig({
  shouldForwardProp: (prop) => !["alignCenter"].includes(prop as string),
})<CardBodyStylingProps>`
  width: 100%;
  cursor: pointer;
  padding: 1rem 1rem 0.5rem 1rem;
  min-height: 85px;
  display: flex;
  flex-direction: column;
  background-color: transparent;

  ${({ alignCenter }) =>
    alignCenter
      ? `
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
  `
      : ""}
`;

const StyledCardFooter = styled(CardFooter)`
  height: 100%;
  max-height: 25px;
  padding: 0.25rem;
  font-size: ${fonts.link.size + "px"};
  line-height: ${fonts.link.height + "px"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

type Props = AppCardProps & { theme: Theme; className?: string };

const AppCardBase: React.FunctionComponent<Props> = ({
  cardImage,
  cardText,
  id,
  selected,
  highlighted,
  alignCenter,
  onToggleSelected,
  onToggleExpanded,
  theme,
  settings,
  staffCard,
  disable,
  className,
}: Props) => {
  const { t } = useTranslation();

  const color = getColorContrast(
    getDefaultColor(
      theme.body.buttons.productSecondaryTextColor,
      theme.body.buttons.mainBackgroundColor
    )
  );
  const strokeColor = getDefaultColor(
    theme.body.buttons.productButtonColor,
    theme.body.backgroundColor
  );

  const cardSelectedIcon = selected && (
    <CardSelectedIcon
      src={Icons.selected}
      fillColor={color}
      strokeColor={strokeColor}
      size={25}
    />
  );

  const cardFooter = onToggleExpanded && (
    <StyledCardFooter
      onClick={onToggleExpanded}
      id={`app-card-moreinfo-${id}`}
      tabIndex={0}
      onKeyPress={onToggleExpanded}
      role="button"
    >
      <MoreInfoLink
        className="text-center text-uppercase"
        color={color}
        noUnderline
      >
        {t("productMoreInfo", { defaultValue: "More Info" })}
      </MoreInfoLink>
    </StyledCardFooter>
  );

  const renderCard = () => {
    return (
      <>
        <StyledCardImage>
          {cardSelectedIcon}
          {cardImage ? cardImage : null}
        </StyledCardImage>
        <StyledCardBody alignCenter={alignCenter}>{cardText}</StyledCardBody>
      </>
    );
  };

  const onClick = React.useCallback(
    () =>
      settings &&
      settings.enableProductCheckboxConfirmation &&
      !staffCard &&
      onToggleExpanded
        ? (onToggleExpanded as () => void)()
        : (onToggleSelected as () => void)(),
    [onToggleExpanded, onToggleSelected]
  );

  return (
    <StyledCard
      id={`card-item-${id}`}
      highlighted={highlighted}
      selected={selected}
      disable={disable}
      aria-disabled={disable ? "true" : undefined}
      className={className}
    >
      <Content
        tabIndex={0}
        onClick={onClick}
        onKeyPress={onClick}
        role="button"
      >
        {renderCard()}
      </Content>
      {(staffCard ||
        (settings && !settings.enableProductCheckboxConfirmation)) &&
        cardFooter}
    </StyledCard>
  );
};

export const AppCard = withTheme(AppCardBase);
