import React from "react";
import styled, { css } from "styled-components";
import { Theme } from "..";
import fonts from "../../styles/theme/fonts";
import {
  getColorContrast,
  getDefaultColor
} from "../../styles/theme/themeHelper";

export const AppLinkStyle = (props: AppLinkThemeProps) => css`
  font-weight: ${props.fontWeight};
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: ${props.fontSize + "px"};
  line-height: ${props.lineHeight + "px"};
  color: ${props.color} !important;
  cursor: pointer;
  text-decoration: ${props.noUnderline ? "none" : "underline"} !important;
`;

export interface AppLinkThemeProps {
  color?: string;
  fontSize?: number;
  fontWeight?: number | string;
  lineHeight?: number;
  noUnderline?: boolean;
}

type ThemeProps = { theme: Theme } & AppLinkThemeProps;

type Props = React.AnchorHTMLAttributes<React.ReactChild> & AppLinkThemeProps;

export const AppLink = styled.a<Props>`
  ${(props: ThemeProps) =>
    AppLinkStyle({
      color: props.color || props.theme.body.buttons.mainBackgroundColor,
      fontSize: props.fontSize || fonts.link.size,
      fontWeight: props.fontWeight || fonts.link.weight,
      lineHeight: props.lineHeight || fonts.link.height,
      noUnderline: props.noUnderline
    })};
`;

export const MoreInfoLink = styled.a<Props>`
  ${(props: ThemeProps) =>
    AppLinkStyle({
      color:
        props.color ||
        getColorContrast(
          getDefaultColor(
            props.theme.body.buttons.productIconTextColor,
            props.theme.body.buttons.mainBackgroundColor
          )
        ),
      fontSize: props.fontSize || fonts.link.size,
      fontWeight: props.fontWeight || fonts.link.weight,
      lineHeight: props.lineHeight || fonts.link.height,
      noUnderline: props.noUnderline
    })};
`;
