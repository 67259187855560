import { AttachmentAction } from "./actionCreators";
import { TypeKeys } from "./actionTypes";
import {
  handleAddAttachment,
  handleDetetAttachment,
  handleDetetAttachmentFail,
  handleDetetAttachmentSuccess,
  handleTransformAnswerFiles,
  handleUploadAttachmentsCompleted
} from "./reducerHelpers";
import { UploadState } from "./types";

export const initialState: UploadState = {
  questions: {},
  loading: false
};

const reducer = (
  state: UploadState = initialState,
  action: AttachmentAction
): UploadState => {
  switch (action.type) {
    case TypeKeys.INIT_UPLOAD_STATE:
    case TypeKeys.CLEAR_ALL:
      return initialState;
    case TypeKeys.ADD_ATTACHMENT:
      return handleAddAttachment(state, action);
    case TypeKeys.UPLOAD_ATTACHMENTS_COMPLETED:
      return handleUploadAttachmentsCompleted(state, action);
    case TypeKeys.DELETE_ATTACHMENT:
      return handleDetetAttachment(state, action);
    case TypeKeys.DELETE_ATTACHMENT_SUCCESS:
    case TypeKeys.REMOVE_FILE:
      return handleDetetAttachmentSuccess(state, action);
    case TypeKeys.DELETE_ATTACHMENT_FAIL:
      return handleDetetAttachmentFail(state, action);
    case TypeKeys.TRANSFORM_ANSWER_FILES_OF_CONFIRMED_QUESTIONS:
      return handleTransformAnswerFiles(state, action);
    default:
      return state;
  }
};

export { reducer as uploadReducer };
