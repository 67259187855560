import { useEffect, useState } from "react";
import { Coordinates } from "../shared";

export interface Position {
  coords: Coordinates;
}

export interface UseCoordinates {
  coordinates?: Coordinates;
  geolocationError?: any;
}

export const useCoordinates = (
  defaultCoords?: Coordinates,
  disabled?: boolean
): UseCoordinates => {
  const [coordinates, setCoordinates] = useState<Coordinates>();
  const [geolocationError, setError] = useState<any>();

  useEffect(() => {
    if (disabled) {
      setError({
        locationDisabled: true,
      });
    } else if (!disabled && window.navigator.geolocation) {
      const success = (position: Position) => {
        const coords = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };

        setCoordinates(coords);
      };

      const failure = (locationError: any) => {
        if (defaultCoords) {
          setCoordinates(defaultCoords);
        }
        setError(locationError);
      };
      try {
        navigator.geolocation.getCurrentPosition(success, failure, {
          maximumAge: Infinity,
          timeout: 5000,
        });
      } catch {
        setCoordinates(defaultCoords);
        setError({
          locationDisabled: true,
        });
      }
    } else if (defaultCoords) {
      setCoordinates(defaultCoords);
    }
  }, []);

  return { coordinates, geolocationError };
};
