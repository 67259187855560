export enum AnalyticsEventActions {
  BOOK_HERE_STORE_FINDER = "Book Here Store Finder",
  JOIN_QUEUE_STORE_FINDER = "Join Queue Store Finder",
  POSTCODE_STORE_FINDER = "Postcode Store Finder",
  BACK_BUTTON = "Back Button",
  EDIT_BUTTON = "Edit Button",
  NEXT_BUTTON = "Next Button",
  SELECT_QUEUE = "Select Queue",
  SELECT_PRODUCT = "Select Product",
  SELECT_ADDON = "Select Add-on",
  REMOVE_ADDON = "Remove Add-on",
  SELECT_ADVISOR = "Select Advisor",
  NO_PREFERENCE_BUTTON_ADVISOR_SELECTION = "No Preference Button Advisor Selection",
  MORE_INFO_BUTTON_ADVISOR_SELECTION = "More Info Button Advisor Selection",
  BACK_BUTTON_ADVISOR_PROFILE = "Back Button Advisor Profile",
  BOOK_WITH_BUTTON_ADVISOR_PROFILE = "Book With Button Advisor Profile",
  SELECT_DAY_APPOINTMENT_TIME = "Select Day Appointment Time",
  NEXT_DAY_BUTTON_APPOINTMENT_TIME = "Next Day Button Appointment Time",
  PREVIOUS_DAY_BUTTON_APPOINTMENT_TIME = "Previous Day Button Appointment Time",
  CLICK_SLOT = "Click Slot",
  SCROLL_UP_SLOT = "Scroll Up Slot",
  SCROLL_DOWN_SLOT = "Scroll Down Slot",
  SELECT_SLOT = "Select Slot",
  NEXT_BUTTON_CUSTOMER_DETAILS = "Next Button Customer Details",
  COMPLETE_BUTTON_CUSTOMER_DETAILS = "Complete Button Customer Details",
  COMPLETE_BUTTON_CUSTOMER_ANSWERS = "Complete Button Customer Answers",
  UPDATE_BUTTON_CUSTOMER_ANSWERS = "Update Button Customer Answers",
  ADVISOR_PROFILE_PAGE_BUTTON_CONFIRMATION = "Advisor Profile Page Button Confirmation",
  RESCHEDULE_BUTTON_CONFIRMATION = "Reschedule Button Confirmation",
  YES_BUTTON_RESCHEDULE_BOOKING = "Yes Button Reschedule Booking",
  NO_BUTTON_RESCHEDULE_BOOKING = "No Button Reschedule Booking",
  CANCEL_BUTTON_CONFIRMATION = "Cancel Button Confirmation",
  YES_BUTTON_CANCEL_BOOKING = "Yes Button Cancel Booking",
  NO_BUTTON_CANCEL_BOOKING = "No Button Cancel Booking",
  GO_BACK_TO_HOMEPAGE_BUTTON_CONFIRMATION = "Go Back To Homepage Button Confirmation",
  GO_BACK_TO_HOMEPAGE_BUTTON_CANCEL_CONFIRMATION = "Go Back To Homepage Button Cancel Confirmation",
  BOOK_NEW_APPOINTMENT_BUTTON_CANCEL_CONFIRMATION = "Book New Appointment Button Cancel Confirmation",
  ROUTE_CHANGE_COMPLETE = "Route Change Complete",
  PRODUCT_CONFIRMATION_CHECKBOX = "Product Confirmation Checkbox",
  CONFIRM_PAYMENT = "Confirm Payment Button",
}
