import { Map } from "immutable";
import { BookingType, Queue, Venue } from "../../shared";
import { ApplicationState } from "../../store";
import { getReservationResponse } from "../details/selectors";
import { getBookingResponse } from "../questions/selectors";
import { getSelectedBookingType } from "../bookingTypes/selectors";

const getProduct = (state: ApplicationState): Map<string, any> => {
  return state.api.get("SINGLE_PRODUCT");
};

export const isLoadingProduct = (
  state: ApplicationState,
  defaultValue = true
): boolean => {
  if (!getProduct(state)) {
    return defaultValue;
  }
  return getProduct(state).get("isLoading");
};

export const getSingleProductResponse = (state: ApplicationState) => {
  if (!getProduct(state) || !getProduct(state).get("payload")) {
    return;
  }
  return getProduct(state).getIn(["payload", "response"]);
};

const getVenue = (state: ApplicationState) => {
  return state.api.get("SINGLE_VENUE");
};

export const isLoadingVenue = (
  state: ApplicationState,
  defaultValue = true
) => {
  if (!getVenue(state)) {
    return defaultValue;
  }
  return getVenue(state).get("isLoading");
};

export const getSingleVenueResponse = (state: ApplicationState) => {
  if (!getVenue(state) || !getVenue(state).get("payload")) {
    return;
  }
  return getVenue(state).getIn(["payload", "response"]);
};

export const getVenueId = (state: ApplicationState) => {
  const venueState = getVenue(state);
  if (!venueState) {
    return;
  }
  return venueState.getIn(["payload", "response", "id"]);
};

export const getProductId = (state: ApplicationState) => {
  const productState = getProduct(state);
  if (!productState) {
    return;
  }
  return productState.getIn(["payload", "response", "id"]);
};

export const getBookingId = (state: ApplicationState) => {
  const bookingState = state.api.get("BOOKING");
  if (!bookingState) {
    return;
  }

  return bookingState.getIn(["payload", "response", "bookingId"]);
};

export const isLoadingBooking = (
  state: ApplicationState,
  defaultValue: boolean = false
) => {
  const bookingState = state.api.get("BOOKING");
  if (!bookingState) {
    return defaultValue;
  }

  return bookingState.get("isLoading");
};

export const isLoadingReservation = (
  state: ApplicationState,
  defaultValue: boolean = false
) => {
  const bookingState = state.api.get("RESERVATION");
  if (!bookingState) {
    return defaultValue;
  }

  return bookingState.get("isLoading");
};

export const getBookingType = (
  state: ApplicationState
): BookingType | undefined => {
  const reservation = getReservationResponse(state);
  if (reservation) {
    return reservation.bookingType;
  }

  const booking = getBookingResponse(state);

  if (booking) {
    return booking.bookingType;
  }

  return undefined;
};

export const getIsLiveChat = (state: ApplicationState): boolean => {
  const bookingType = getBookingType(state);

  if (bookingType) {
    return bookingType === BookingType.LIVE_VIDEO_CHAT;
  } else {
    return getSelectedBookingType(state) === "liveChat";
  }
};
/* Layout Type */

export const getCurrentLayout = (state: ApplicationState): string => {
  return state.products.get("layoutType");
};

export const getMaxBookingGroupSize = (
  queueId?: number,
  venue?: Venue
): number | undefined => {
  if (!queueId || !venue) {
    return undefined;
  }

  const queue =
    venue && venue.queues.find((q: Queue) => q.id === queueId.toString());
  return queue && queue.maxBookingGroupSize;
};
