import { Map } from "immutable";
import { Reducer } from "redux";
import { CLEAR_ALL } from "../layout/types";
import {
  JOIN_VIDEO_MEETING,
  LEAVE_VIDEO_MEETING,
  TOGGLE_CHAT,
} from "./constants";

export const initialState = Map({
  isUserInVideoMeeting: false,
  isChatActive: false,
});

const reducer: Reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ALL:
      return state;

    case JOIN_VIDEO_MEETING:
      state = state.set("isUserInVideoMeeting", true);
      return state;

    case LEAVE_VIDEO_MEETING:
      state = state.set("isUserInVideoMeeting", false);
      return state;

    case TOGGLE_CHAT:
      state = state.set("isChatActive", !state.get("isChatActive"));
      return state;

    default: {
      return state;
    }
  }
};

export { reducer as videoMeetingReducer };
