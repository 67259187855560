import React from "react";
import {
  Service,
  Settings,
  shouldShowServiceDuration,
  shouldShowServicePricing,
  Theme,
} from "../../../../shared";
import {
  getTotalDepositPrice,
  getTotalDuration,
  getTotalPrice,
  getTotalRefundablePrice,
  renderPrice,
} from "../utils";
import { TotalServiceDuration } from "./totalServiceDuration";
import { TotalServicePrice } from "./totalServicePrice";
import { ProductRow } from "./productRow";

export interface ProductDetailsProps {
  service: Service;
  selectedAddOns: Service[] | void;
  theme?: Theme;
  settings: Settings;
}

export const ProductDetails = ({
  service,
  selectedAddOns,
  theme,
  settings,
}: ProductDetailsProps) => {
  const showServiceDuration = shouldShowServiceDuration(settings);
  const showServicePricing = shouldShowServicePricing(settings);

  const totalPrice = showServicePricing
    ? getTotalPrice(service, selectedAddOns)
    : 0;
  const totalDepositPrice = showServicePricing
    ? getTotalDepositPrice(service, selectedAddOns)
    : 0;
  const totalRefundablePrice = showServicePricing
    ? getTotalRefundablePrice(service, selectedAddOns)
    : 0;

  const showDeposit =
    settings.paymentEnabled && totalPrice !== totalDepositPrice;
  const showRefundable =
    settings.paymentEnabled &&
    totalPrice !== totalRefundablePrice &&
    totalRefundablePrice > 0;

  const currencyCode =
    service?.currencyCode ||
    (selectedAddOns &&
      selectedAddOns.find((s) => s.currencyCode)?.currencyCode);

  return (
    <div className="d-flex w-100 flex-column">
      {[service, ...(selectedAddOns ?? [])].map((s, i) => (
        <ProductRow
          key={s.id + i}
          index={i}
          service={s}
          theme={theme}
          showServicePricing={showServicePricing}
          showRefundable={showRefundable}
          showDeposit={showDeposit}
          currency={currencyCode}
          showServiceDuration={showServiceDuration}
        />
      ))}
      {(showServiceDuration || totalPrice > 0) && (
        <hr className={"w-100 mt-0 mb-2"} />
      )}
      {totalPrice > 0 && (
        <TotalServicePrice
          totalPrice={totalPrice}
          totalRefundablePrice={totalRefundablePrice}
          totalDepositPrice={totalDepositPrice}
          theme={theme}
          total={renderPrice(totalPrice, currencyCode)}
          showTotalPriceDetails={settings.paymentEnabled}
        />
      )}
      {showServiceDuration && (
        <TotalServiceDuration
          theme={theme}
          totalDuration={getTotalDuration(service, selectedAddOns)}
        />
      )}
    </div>
  );
};
