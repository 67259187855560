import {
  AddAttachment,
  DeleteAttachment,
  DeleteAttachmentFail,
  DeleteAttachmentSuccess,
  RemoveFile,
  TransformAnswerFilesOfConfirmedQuestions,
  UploadAttachmentsCompleted,
} from "./actionCreators";
import { DeleteStatus, SelectedFile, UploadState, UploadStatus } from "./types";

export const handleAddAttachment = (
  state: UploadState,
  action: AddAttachment
): UploadState => {
  const questionId = action.questionId;
  const currentAttachments = questionId
    ? state.questions && state.questions[questionId]
    : [];
  const selectedFiles: SelectedFile[] = action.files.map((file) => ({
    questionId,
    file,
    uploadStatus: UploadStatus.IN_PROGRESS,
  }));

  const hasAttachments = currentAttachments && currentAttachments.length > 0;

  if (questionId) {
    return {
      ...state,
      questions: {
        ...state.questions,
        [questionId]: hasAttachments
          ? [...currentAttachments, ...selectedFiles]
          : [...selectedFiles],
      },
      loading: true,
    };
  }

  return state;
};

export const handleUploadAttachmentsCompleted = (
  state: UploadState,
  action: UploadAttachmentsCompleted
): UploadState => {
  const questionId =
    action.payload && action.payload.length > 0 && action.payload[0].questionId;
  const currentAttachments = questionId
    ? state.questions && state.questions[questionId]
    : [];
  const hasAttachments = currentAttachments && currentAttachments.length > 0;

  const removeInprogressOnes = hasAttachments
    ? currentAttachments.filter(
        (attachment) =>
          !(
            attachment.uploadStatus === UploadStatus.IN_PROGRESS &&
            action.payload.find(
              (p) => p.file.filename === attachment.file.filename
            )
          )
      )
    : [];

  if (questionId) {
    return {
      ...state,
      questions: {
        ...state.questions,
        [questionId]: hasAttachments
          ? [...removeInprogressOnes, ...action.payload]
          : [...action.payload],
      },
      loading: false,
    };
  }
  return state;
};

export const handleDetetAttachment = (
  state: UploadState,
  action: DeleteAttachment
): UploadState => {
  const { questionId, index } = action;
  const question = state.questions[questionId];
  const attachment = question && question[index];

  const attachments = [
    ...question.slice(0, index),
    { ...attachment, deleteStatus: DeleteStatus.IN_PROGRESS },
    ...question.slice(index + 1),
  ];

  if (questionId) {
    return {
      ...state,
      questions: {
        ...state.questions,
        [questionId]: [...attachments],
      },
    };
  }

  return state;
};

export const handleDetetAttachmentSuccess = (
  state: UploadState,
  action: DeleteAttachmentSuccess | RemoveFile
): UploadState => {
  const { questionId, index } = action;
  const attachments = [
    ...state.questions[questionId].slice(0, index),
    ...state.questions[questionId].slice(index + 1),
  ];

  if (questionId) {
    return {
      ...state,
      questions: {
        ...state.questions,
        [questionId]: [...attachments],
      },
    };
  }

  return state;
};

export const handleDetetAttachmentFail = (
  state: UploadState,
  action: DeleteAttachmentFail
): UploadState => {
  const { questionId, index } = action;
  const question = state.questions[questionId];
  const attachment = question && question[index];

  const attachments = [
    ...question.slice(0, index),
    { ...attachment, deleteStatus: DeleteStatus.ERROR },
    ...question.slice(index + 1),
  ];

  if (questionId) {
    return {
      ...state,
      questions: {
        ...state.questions,
        [questionId]: [...attachments],
      },
    };
  }

  return state;
};

export const handleTransformAnswerFiles = (
  state: UploadState,
  action: TransformAnswerFilesOfConfirmedQuestions
): UploadState => {
  const { questions } = action;

  const confirmedUploadedImages = questions.reduce((acc, cur) => {
    const answer = cur.answer;

    if (answer) {
      const answerFiles = answer.answerFiles.map((answerFile) => {
        const { downloadUrl, filename, mimeType, thumbnailUrl, id } =
          answerFile;

        return {
          file: {
            id,
            downloadUrl,
            thumbnailUrl,
            filename,
            mimeType,
          },
          questionId: answer.questionId,
          uploadStatus: UploadStatus.SUCCESS,
        };
      });

      return {
        ...acc,
        [answer.questionId]: answerFiles,
      };
    } else {
      return { ...acc };
    }
  }, {});

  return {
    ...state,
    questions: {
      ...state.questions,
      ...confirmedUploadedImages,
    },
  };
};
