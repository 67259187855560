import { List } from "immutable";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Action, Dispatch } from "redux";
import config from "../../../config";
import { makeRequest } from "../../services/api/actions";
import { RequestData } from "../../services/api/epics/apiRequest";
import { getSingleVenueQueryParams } from "../../services/api/helpers";
import {
  Advisor,
  Booking,
  BookingStatus,
  Reservation,
  Service,
  Settings,
  StepNames,
  Venue,
} from "../../shared";
import { Questions, WhenToAsk } from "../../shared/interfaces/questions";
import { ApplicationState } from "../../store";
import { getBWID } from "../../utils/getBWID";
import {
  isProductsBeforeStoreStep,
  isVirtualProductsBeforeStoreStep,
} from "../../utils/pageRouter";
import { useCurrency } from "../../utils/useCurrencyHook";
import { getSingleAdvisorResponse } from "../advisorSelector/selectors";
import { getBookingResponse } from "../details/selectors";
import {
  getCurrentLanguage,
  getFirstPage,
  getSettingsResponse,
} from "../layout/selectors";
import { getProductsAggregate, isLoadingProducts } from "../products/selectors";
import { getResponse, isLoadingQuestions } from "../questions/selectors";
import {
  getSingleProductResponse,
  getSingleVenueResponse,
  getIsLiveChat,
} from "../summary/selectors";
import {
  getBookingAdvisorId,
  getBookingProductId,
  getBookingVenueId,
  getReservationAdvisorId,
  getReservationProductId,
  getReservationResponse,
  getReservationVenueId,
} from "./selectors";
import { isLiveProductsStep } from "../../utils/pageRouter";

interface StateProps {
  settings: Settings | void | undefined;
  advisorId: string | void;
  productId: string | void;
  questions: Questions[];
  singleAdvisor: Advisor | void;
  booking?: Booking;
  singleProduct: Service;
  singleVenue: Venue;
  venueId: string | void;
  language?: string | void;
  firstPage?: StepNames;
  products: List<Service>;
  isLoadingProducts: boolean;
  reservation: Reservation | void;
  isLiveChat?: boolean;
}

interface DispatchProps {
  fetchBooking: (requestData: RequestData) => void;
  fetchQuestions: (requestData: RequestData) => void;
  fetchSingleAdvisor: (requestData: RequestData) => void;
  fetchSingleProduct: (requestData: RequestData) => void;
  fetchSingleVenue: (requestData: RequestData) => void;
  fetchProducts: (requestData: RequestData) => void;
}

type Props = StateProps & DispatchProps;
const LIMIT = 20;

const RestoreDataContainer: React.FunctionComponent<Props> = (props) => {
  const router = useRouter();
  const { query } = router;
  const { currency } = useCurrency();

  const isVirtualProductsBeforeStore = isVirtualProductsBeforeStoreStep(
    router,
    props.firstPage || ""
  );

  const isLiveJourney =
    isLiveProductsStep(router, props.firstPage || "") || props.isLiveChat;

  // Fetch venue data
  useEffect(() => {
    if (props.singleVenue || !props.venueId || props.venueId === "-1") {
      return;
    }

    props.fetchSingleVenue({
      method: "GET",
      url: `${config.baseApiUrl}/${getBWID()}/venues/${
        props.venueId
      }${getSingleVenueQueryParams()}`,
    });
  }, [props.venueId]);

  const isProductsBeforeStore = isProductsBeforeStoreStep(
    router,
    props.firstPage || ""
  );

  useEffect(() => {
    if (
      props.singleProduct &&
      props.products?.size === 0 &&
      !props.isLoadingProducts
    ) {
      let url;
      const baseUrl = `${config.baseApiUrl}/${getBWID()}`;
      const params = `?limit=${LIMIT}&lang=${props.language}${
        currency && `&currency=${currency}`
      }`;

      if (isVirtualProductsBeforeStore) {
        url = `${baseUrl}/virtual/products${params}`;
      } else if (isProductsBeforeStore) {
        url = `${baseUrl}/products${params}`;
      } else if (isLiveJourney) {
        url = `${baseUrl}/live${params}`;
      } else if (props.singleVenue?.id) {
        url = `${baseUrl}/venues/${props.singleVenue.id}/products${params}`;
      }

      if (url) {
        props.fetchProducts({
          method: "GET",
          url,
        });
      }
    }
  }, [props.singleProduct, props.products, props.singleVenue, props.language]);

  // Fetch product and advisor data
  useEffect(() => {
    if (isLiveJourney && props.productId) {
      props.fetchSingleProduct({
        method: "GET",
        url: `${config.baseApiUrl}/${getBWID()}/products/${
          props.productId
        }?lang=${props.language || query.lang}${
          currency && `&currency=${currency}`
        }`,
      });
    } else if (
      !props.singleProduct &&
      props.venueId &&
      props.venueId !== "-1" &&
      props.productId
    ) {
      props.fetchSingleProduct({
        method: "GET",
        url: `${config.baseApiUrl}/${getBWID()}/venues/${
          props.venueId
        }/products/${props.productId}?lang=${props.language || query.lang}${
          currency && `&currency=${currency}`
        }`,
      });
    }

    const shouldFetchAdvisor =
      props.settings && props.settings.staffOptions.showStaffSelection;
    if (
      shouldFetchAdvisor &&
      !props.singleAdvisor &&
      props.venueId &&
      props.productId &&
      props.advisorId
    ) {
      props.fetchSingleAdvisor({
        method: "GET",
        url: `${config.baseApiUrl}/${getBWID()}/venues/${
          props.venueId
        }/products/${props.productId}/staff/${props.advisorId}`,
      });
    }
  }, [props.productId, props.venueId, props.advisorId, props.language]);

  const firstPage = useSelector(getFirstPage);

  const isLiveChat =
    useSelector(getIsLiveChat) || isLiveProductsStep(router, firstPage || "");

  useEffect(() => {
    if (
      props.singleVenue &&
      props.singleProduct &&
      !query.bookingId &&
      !isVirtualProductsBeforeStore
    ) {
      props.fetchQuestions({
        method: "GET",
        url: `${config.baseApiUrl}/${getBWID()}/venues/${
          props.singleVenue.id
        }/products/${props.singleProduct.id}/questions?lang=${
          props.language || query.lang
        }`,
      });
    } else if (props.singleProduct) {
      const whenToAsk = props.booking
        ? props.booking.status !== BookingStatus.POST_SERVED
          ? WhenToAsk.AFTER_JOINING
          : WhenToAsk.AFTER_SERVICE_BY_CUSTOMER
        : WhenToAsk.WHEN_JOINING;

      props.fetchQuestions({
        method: "GET",
        url: `${config.baseApiUrl}/${getBWID()}/${
          isLiveChat ? "live" : "virtual"
        }/products/${props.singleProduct.id}/questions?lang=${
          props.language || query.lang
        }&whenToAsk=${whenToAsk}`,
      });
    }
  }, [
    props.singleVenue,
    props.singleProduct,
    props.language,
    props.reservation,
  ]);

  useEffect(() => {
    if (query.bookingId && !props.booking) {
      props.fetchBooking({
        method: "GET",
        url: `${config.baseApiUrl}/${getBWID()}/bookings/${query.bookingId}`,
      });
    }
  }, []);

  return null;
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    settings: getSettingsResponse(state),
    reservation: getReservationResponse(state),
    isLiveChat: getIsLiveChat(state),
    booking: getBookingResponse(state),
    advisorId: getBookingAdvisorId(state) || getReservationAdvisorId(state),
    language: getCurrentLanguage(state),
    productId: getBookingProductId(state) || getReservationProductId(state),
    products: getProductsAggregate(state),
    isLoadingProducts: isLoadingProducts(state),
    questions: isLoadingQuestions(state) ? [] : getResponse(state),
    singleAdvisor: getSingleAdvisorResponse(state),
    singleProduct: getSingleProductResponse(state),
    singleVenue: getSingleVenueResponse(state),
    venueId: getBookingVenueId(state) || getReservationVenueId(state),
    firstPage: getFirstPage(state),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    fetchBooking: (dataRequest: RequestData) => {
      dispatch(makeRequest(dataRequest, "BOOKING"));
    },
    fetchQuestions: (dataRequest: RequestData) => {
      dispatch(makeRequest(dataRequest, "QUESTIONS"));
    },
    fetchSingleAdvisor: (dataRequest: RequestData) => {
      dispatch(makeRequest(dataRequest, "SINGLE_ADVISOR"));
    },
    fetchSingleProduct: (dataRequest: RequestData) => {
      dispatch(makeRequest(dataRequest, "SINGLE_PRODUCT"));
    },
    fetchSingleVenue: (dataRequest: RequestData) => {
      dispatch(makeRequest(dataRequest, "SINGLE_VENUE"));
    },
    fetchProducts: (dataRequest: RequestData) => {
      dispatch(makeRequest(dataRequest, "PRODUCTS", "products"));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestoreDataContainer);
