import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ApiError } from "../../shared/interfaces/apiError";
import { ApplicationState } from "../../store";
import { goToError } from "../../utils/pageRouter";
import { ErrorModal } from "./ErrorModal";
import { getError } from "./selectors";

import Router from "next/router";

interface StateProps {
  booking: ApiError;
  product: ApiError;
  products: ApiError;
  reservation: ApiError;
  slots: ApiError;
  venue: ApiError;
  venues: ApiError;
}

type Props = StateProps;

const ApiErrorManagerContainer: React.FunctionComponent<Props> = (props) => {
  const { t } = useTranslation();

  const all = [
    props.booking,
    props.product,
    props.products,
    props.reservation,
    props.slots,
    props.venue,
    props.venues,
  ];

  const goBack = () => {
    Router.back();
  };

  const [showErrorModal, setShowErrorModal] = useState<
    { title: string; message: string; onToggle?: () => void } | undefined
  >(undefined);

  const defaultTitle = t("errorMessage", {
    defaultValue: "We’re sorry. There has been an error.",
  });

  const getErrorDescription = (error: string) => {
    switch (error) {
      case "invalid email":
        return t("emailAddressInvalid", {
          defaultValue: "Email address is invalid",
        });
      default:
        return error;
    }
  };

  useEffect(() => {
    all
      .filter((x) => x)
      .map((type) => {
        if (type.status === 404 && props.reservation === type) {
          goBack();
          return null;
        }
        switch (type.status) {
          case 400: {
            if (
              type &&
              type.errors &&
              type.errors[0] &&
              type.errors[0].description === "invalid identifier"
            ) {
              goToError(true);
              return;
            }

            const title = getErrorDescription(
              type && type.errors && type.errors[0]
                ? type.errors[0].description
                : defaultTitle
            );
            const message =
              type && type.errors && type.errors[0]
                ? `"${type.errors[0].value}"`
                : "";

            const onClose = () => {
              setShowErrorModal(undefined);
            };

            setShowErrorModal({
              title,
              message,
              onToggle: onClose,
            });

            return;
          }
          case 410:
            const appointmentReservedMessage = t("appointmentReservedMessage", {
              defaultValue:
                "Apologies, but we are experiencing high appointment traffic and this slot was reserved by another customer.\n" +
                "We hope you are able to find a new slot that suits you. (we release new appointment slots every week.)",
            });
            setShowErrorModal({
              title: t("appointmentSlotReserved", {
                defaultValue: "Time slot taken",
              }),
              message:
                appointmentReservedMessage ===
                "Apologies, but we are experiencing high appointment traffic and this slot was reserved by another customer. We hope you are able to find a new slot that suits you. (We release new appointment slots every week.)"
                  ? "Apologies, but we are experiencing high appointment traffic and this slot was reserved by another customer.\n" +
                    "We hope you are able to find a new slot that suits you. (we release new appointment slots every week.)"
                  : appointmentReservedMessage,
            });

            return null;
          default:
            goToError(true);

            return null;
        }
      });
  }, all);

  return (
    <ErrorModal
      isOpen={!!showErrorModal}
      onToggle={() => setShowErrorModal(undefined)}
      {...(showErrorModal || {})}
    />
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  return {
    booking: getError(state, "BOOKING"),
    product: getError(state, "SINGLE_PRODUCT"),
    products: getError(state, "PRODUCTS"),
    reservation: getError(state, "RESERVATION"),
    slots: getError(state, "SLOTS"),
    venue: getError(state, "SINGLE_VENUE"),
    venues: getError(state, "VENUES"),
  };
};

export default connect(mapStateToProps)(ApiErrorManagerContainer);
