import React, { createContext, FC, useContext, useMemo, useState } from "react";
import { useCurrencies } from "./useCurrenciesHook";

const UserCurrencyContext = createContext<UseCurrency>({});

interface UseCurrency {
  currency?: string;
  setCurrency?: (currency: string) => void;
}

export const useCurrency = (): UseCurrency => useContext(UserCurrencyContext);

export const UserCurrencyProvider: FC = ({ children }) => {
  const { defaultCurrencyCode } = useCurrencies();
  const [currency, setCurrency] = useState<string>();

  const value = useMemo(
    () => ({ currency: currency || defaultCurrencyCode, setCurrency }),
    [currency, setCurrency, defaultCurrencyCode]
  );

  return (
    <UserCurrencyContext.Provider value={value}>
      {children}
    </UserCurrencyContext.Provider>
  );
};
