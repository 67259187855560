import React from "react";
import {
  allowedOverflow,
  AppParagraph1,
  AppParagraph3,
  Theme,
} from "../../../../shared";
import { useTranslation } from "react-i18next";

interface TotalServicePriceProps {
  theme?: Theme;
  totalPrice: number;
  totalRefundablePrice: number;
  totalDepositPrice: number;
  total: string;
  showTotalPriceDetails: boolean;
}
export const TotalServicePrice = ({
  theme,
  totalPrice,
  totalRefundablePrice,
  totalDepositPrice,
  total,
  showTotalPriceDetails,
}: TotalServicePriceProps) => {
  const { t } = useTranslation();

  return (
    <div className="w-100 d-flex flex-row justify-content-end mb-3">
      <AppParagraph3
        id="summary-card-service-name-total"
        className="text-uppercase mt-1 mr-3"
        style={allowedOverflow}
      >
        {t("total", { defaultValue: "Total" })}
        {showTotalPriceDetails && (
          <>
            {totalDepositPrice === totalPrice && (
              <span className="text-capitalize">
                , {t("deposit", { defaultValue: "Deposit" })}
              </span>
            )}
            {totalRefundablePrice === totalPrice && (
              <span className="text-capitalize">
                , {t("refundable", { defaultValue: "Refundable" })}
              </span>
            )}
            {totalRefundablePrice === 0 && (
              <span className="text-capitalize">
                , {t("notRefundable", { defaultValue: "Not Refundable" })}
              </span>
            )}
          </>
        )}
      </AppParagraph3>
      <AppParagraph1
        id={"summary-card-service-price-total"}
        style={{
          ...allowedOverflow,
          color: theme?.body.font.mainTextColor,
          lineHeight: "22px",
        }}
      >
        {total}
      </AppParagraph1>
    </div>
  );
};
