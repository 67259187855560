import React from "react";
import { ReactChild } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { AppHeading3, AppHeadingThemeProps, AppLink } from "../../../shared";
import { styled } from "../../../styles/theme/themeHelper";

const StyledCardHeader = styled(CardHeader)`
  justify-content: space-between;
  display: flex;
  text-transform: uppercase;
  background-color: ${props => props.theme.body.backgroundColor};
`;

const StyledIconWrapper = styled.div`
  padding-top: 1px;
  width: 26px;
  height: 14px;
`;

const StyledCard = styled(Card)`
  background-color: ${props => props.theme.body.backgroundColor};
  margin-bottom: 0px !important;
`;

export interface SummaryCardProps {
  id: string;
  icon: React.ReactChild;
  title: string;
  children: React.ReactNode;
  linkText?: string;
  onLinkClick?: () => void;
}

type Props = SummaryCardProps;

export const SummaryCard: React.FunctionComponent<Props> = ({
  id,
  icon,
  title,
  children,
  linkText,
  onLinkClick
}: Props) => {
  const renderTitle = () => {
    const headerTitleProps: AppHeadingThemeProps = {
      fontSize: 12,
      fontWeight: "bold",
      lineHeight: 22
    };
    return (
      <div className={"d-flex"}>
        <StyledIconWrapper id={`${id}-icon`}>{icon}</StyledIconWrapper>
        <AppHeading3
          id={`${id}-title`}
          className={"pl-2"}
          {...headerTitleProps}
        >
          {title}
        </AppHeading3>
      </div>
    );
  };

  const keyPressHandler = (
    e: React.KeyboardEvent<HTMLAnchorElement> | React.KeyboardEvent<ReactChild>
  ): void => {
    if (e.key === "Enter" && onLinkClick) {
      onLinkClick();
    }
  };

  const renderLink = () => {
    return (
      linkText &&
      onLinkClick && (
        <AppLink
          id={`${id}-link`}
          fontSize={12}
          onClick={onLinkClick}
          onKeyPress={(
            e:
              | React.KeyboardEvent<HTMLAnchorElement>
              | React.KeyboardEvent<ReactChild>
          ) => keyPressHandler(e)}
          lineHeight={22}
          fontWeight="bold"
          tabIndex={0}
          aria-label={`${linkText} ${title}`}
        >
          {linkText}
        </AppLink>
      )
    );
  };

  return (
    <StyledCard className={"mb-3"}>
      <StyledCardHeader>
        {renderTitle()}
        {renderLink()}
      </StyledCardHeader>
      <CardBody
        className={"text-center d-flex flex-column justify-content-center"}
      >
        {children}
      </CardBody>
    </StyledCard>
  );
};
