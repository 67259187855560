import { Map } from "immutable";
import { ApplicationState } from "../../store";
import { CardBookingValidTypes } from "./types";

const getBookingTypes = (state: ApplicationState): Map<string, any> => {
  return state.api.get("BOOKING_TYPES");
};

export const isLoadingBookingTypes = (state: ApplicationState): boolean => {
  if (!getBookingTypes(state)) {
    return false;
  }

  return getBookingTypes(state).get("isLoading");
};

export const getBookingTypesResponse = (state: ApplicationState) => {
  if (!getBookingTypes(state) || !getBookingTypes(state).get("payload")) {
    return;
  }
  return getBookingTypes(state).getIn(["payload", "response"]);
};

export const getSelectedBookingType = (
  state: ApplicationState
): CardBookingValidTypes | undefined => {
  if (!state.bookingType) {
    return;
  }
  return state.bookingType.get("selectedBookingType");
};
