export interface Booking {
  addOnIds?: string[];
  bookingId: string;
  bookingRef?: string;
  venueId: string;
  productId: string;
  startDateTime: string;
  status: string;
  staffId?: string;
  bookingMeetingUrl?: string;
  bookingType: BookingType;
  journeyFrom?: JourneyFromType;
  bookingMeetingQudiniVideoDetails?: BookingMeetingQudiniVideoDetails;
  estimatedWaitTime?: number;
  queuePosition?: number;
}

export enum BookingStatus {
  CANCELLED = "CANCELLED",
  LEFT = "LEFT",
  NO_SHOW = "NO_SHOW",
  POST_SERVED = "POST_SERVED",
  ACTIVE = "ACTIVE",
  QUEUEING = "QUEUEING",
}

export enum BookingType {
  IN_PERSON = "IN_PERSON",
  VIRTUAL = "VIRTUAL",
  VIRTUAL_AGGREGATED = "VIRTUAL_AGGREGATED",
  LIVE_VIDEO_CHAT = "LIVE_VIDEO_CHAT",
}

export enum JourneyFromType {
  PRODUCTS_FIRST = "/products",
  TYPE_PRODUCT_FIRST = "/type/products",
  TYPE_VIRTUAL_AGGREGATED = "/type/virtual/products",
  TYPE_LIVE_VIDEO_CHAT = "/type/live",
  VIRTUAL_AGGREGATED = "/virtual/products",
  LIVE_VIDEO_CHAT = "/live",
}

// To be implemented with BW-1912
export interface BookingMeetingQudiniVideoDetails {
  attendees: any;
  errors: any;
  meeting: any;
  meetingStatus: any;
}
