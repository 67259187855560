import { ApplicationState } from "../../store";

export const getQudiniVideoMeetingDetails = (state: ApplicationState) => {
  return state.api.getIn([
    "BOOKING",
    "payload",
    "response",
    "bookingMeetingQudiniVideoDetails",
  ]);
};

export const getIsUserInVideoMeeting = (state: ApplicationState): boolean =>
  !!state.videoMeeting.get("isUserInVideoMeeting");

export const getIsChatActive = (state: ApplicationState): boolean =>
  !!state.videoMeeting.get("isChatActive");

export const getCustomerCanEnableCamera = (state: ApplicationState) =>
  getQudiniVideoMeetingDetails(state).customerCanEnableCamera;
