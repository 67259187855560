import React from "react";
import { useTranslation } from "react-i18next";
import { SummaryCard } from "./summaryCard";
import { InfoCircleIcon, Theme } from "../../../shared";
import { withTheme } from "../../../styles/theme/themeHelper";

const defaultValue = "You will receive a video conference link by email";

const VirtualAppointmentInfoSummaryBase: React.FunctionComponent<{
  theme: Theme;
}> = ({ theme }) => {
  const { t } = useTranslation();
  return (
    <SummaryCard
      id={"summary-card-virtual"}
      icon={
        <InfoCircleIcon
          size={14}
          title={`${t("virtualAppointment", {
            defaultValue: "Virtual appointment"
          })} icon`}
        />
      }
      title={t("virtualAppointment", {
        defaultValue: "Virtual appointment"
      })}
    >
      <p
        id={"summary-card-virtual-appointment"}
        className={"text-left"}
        style={{
          color: theme && theme.body.font.mainTextColor,
          fontSize: "14px"
        }}
      >
        {t("virtualAppointmentConfirmation", { defaultValue })}
      </p>
    </SummaryCard>
  );
};

export const VirtualAppointmentInfoSummary = withTheme(
  VirtualAppointmentInfoSummaryBase
);
