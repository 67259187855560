import { Service } from "../../../shared";
import { localizeCurrency } from "../../../utils/currency";
import { humanizeTime } from "../../../utils/time";

const calculateTotal = (
  service: Service,
  selectedAddOns: Service[] | void,
  condition: (service: Service) => boolean
) => {
  const servicePrice =
    condition(service) && service.price ? Number(service.price) : 0;
  const addOnsPrice = selectedAddOns
    ? selectedAddOns.reduce((acc, current) => {
        return condition(current) && current.price
          ? acc + Number(current.price)
          : acc;
      }, 0)
    : 0;
  return servicePrice + addOnsPrice;
};

export const getTotalDepositPrice = (
  service: Service,
  selectedAddOns: Service[] | void
) => calculateTotal(service, selectedAddOns, (item) => Boolean(item.isDeposit));

export const getTotalRefundablePrice = (
  service: Service,
  selectedAddOns: Service[] | void
) =>
  calculateTotal(service, selectedAddOns, (item) => Boolean(item.isRefundable));

export const getTotalPrice = (
  service: Service,
  selectedAddOns: Service[] | void
) => calculateTotal(service, selectedAddOns, () => true);

export const renderPrice = (price?: number, currency?: string | void) => {
  return price && currency ? localizeCurrency(price, currency) : "";
};

export const getTotalDuration = (
  service: Service,
  selectedAddOns: Service[] | void
) => {
  const serviceDuration = service.serveTimeInMinutes ?? 0;
  const addOnsDuration = selectedAddOns
    ? selectedAddOns.reduce((prev, current) => {
        return prev + (current.serveTimeInMinutes ?? 0);
      }, 0)
    : 0;

  const totalDuration = serviceDuration + addOnsDuration;
  return humanizeTime(totalDuration);
};
