import moment from "moment";
import "moment-timezone";
import React from "react";
import { useTranslation } from "react-i18next";
import { allowedOverflow, AppParagraph2, TimeIcon } from "../../../shared";
import { SummaryCard } from "./summaryCard";
import { getDateTimeLocale } from "../../../utils/locale";

export interface DateSummaryProps {
  date: string;
  linkText?: string;
  onLinkClick?: () => void;
  timezone: string;
  loading?: boolean;
}

type Props = DateSummaryProps;

export const DateSummary: React.FunctionComponent<Props> = ({
  date,
  linkText,
  onLinkClick,
  timezone,
  loading,
}: Props) => {
  const { t } = useTranslation();

  const dateAndTimeFormat = t("dateAndTimeFormat", { defaultValue: "LLLL" });

  const renderDate = () => {
    return (
      <AppParagraph2
        id={"summary-card-date-text"}
        className={`text-left color-text1 font-medium${
          loading ? " animated-background" : ""
        }`}
        style={allowedOverflow}
      >
        {loading
          ? ""
          : moment
              .tz(date, timezone)
              .locale(getDateTimeLocale())
              .format(dateAndTimeFormat)}
      </AppParagraph2>
    );
  };

  return (
    <SummaryCard
      id={`summary-card-date`}
      icon={
        <TimeIcon
          size={14}
          title={`${t("dateTime", { defaultValue: "Date & Time" })} icon`}
        />
      }
      linkText={linkText}
      onLinkClick={onLinkClick}
      title={t("dateTime", { defaultValue: "Date & Time" })}
    >
      {renderDate()}
    </SummaryCard>
  );
};
