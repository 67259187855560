export enum UploadStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR"
}

export enum DeleteStatus {
  IN_PROGRESS = "IN_PROGRESS",
  ERROR = "ERROR"
}
export interface Message {
  status: number;
  type: string;
  title: string;
}
export interface DropZoneFile {
  id?: number;
  filename: string;
  mimeType: string;
  downloadUrl?: string;
  thumbnailUrl?: string;
  file?: File;
}

export type Attachment = DropZoneFile;
export type Attachments = Attachment[];

export interface PresignedUrl {
  id: string;
  filename: string;
  mimeType: string;
  s3Key: string;
  s3Bucket: string;
  downloadUrl: string;
  uploadUrl: string;
  thumbnailUrl: string;
}
export interface SelectedFilesCount {
  id: string;
  count: number;
}
export interface SelectedFile {
  questionId: string;
  presignedUrl?: PresignedUrl;
  message?: Message;
  file: DropZoneFile;
  uploadStatus: UploadStatus;
  deleteStatus?: DeleteStatus;
}
export interface SelectedFileState {
  [questionId: string]: SelectedFile[];
}
export interface UploadState {
  questions: SelectedFileState;
  loading: boolean;
}
