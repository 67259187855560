import React from "react";
import { useTranslation } from "react-i18next";
import {
  AddressIcon,
  allowedOverflow,
  AppParagraph2,
  AppParagraph3,
  Coordinates,
  DistanceUnit,
  Settings,
  Venue,
} from "../../../shared";
import { getDistance } from "../../../utils/getDistance";
import { SummaryCard } from "./summaryCard";
import { VenuePhoneNumber } from "../../venueSelector/components/venuePhoneNumber";

export interface VenueSummaryProps {
  venue: Venue;
  distanceUnit: DistanceUnit;
  settings: Settings;
  linkText?: string;
  onLinkClick?: () => void;
  loading?: boolean;
  userCoordinates?: Coordinates;
}

type Props = VenueSummaryProps;

export const VenueSummary: React.FunctionComponent<Props> = ({
  venue,
  distanceUnit,
  linkText,
  onLinkClick,
  settings,
  loading,
  userCoordinates,
}: Props) => {
  const { t } = useTranslation();

  const renderAddress = () => {
    const street1 = settings.addressLinesFilter.displayStreet &&
      venue.address.street1 && (
        <AppParagraph2 style={allowedOverflow}>
          <span id={"summary-card-venue-street1"}>{venue.address.street1}</span>
        </AppParagraph2>
      );
    const street2 = settings.addressLinesFilter.displayStreet &&
      venue.address.street2 && (
        <AppParagraph2 style={allowedOverflow}>
          <span id={"summary-card-venue-street2"}>{venue.address.street2}</span>
        </AppParagraph2>
      );
    const street3 = settings.addressLinesFilter.displayStreet &&
      venue.address.street3 && (
        <AppParagraph2 style={allowedOverflow}>
          <span id={"summary-card-venue-street3"}>{venue.address.street3}</span>
        </AppParagraph2>
      );
    const street4 = settings.addressLinesFilter.displayStreet &&
      venue.address.street4 && (
        <AppParagraph2 style={allowedOverflow}>
          <span id={"summary-card-venue-street4"}>{venue.address.street4}</span>
        </AppParagraph2>
      );

    const city = settings.addressLinesFilter.displayCity &&
      venue.address.city && (
        <AppParagraph2 style={allowedOverflow}>
          <span id={"summary-card-venue-city"}>{venue.address.city}</span>
        </AppParagraph2>
      );
    const region = settings.addressLinesFilter.displayRegion &&
      venue.address.region && (
        <AppParagraph2 style={allowedOverflow}>
          <span id={"summary-card-venue-region"}>{venue.address.region}</span>
        </AppParagraph2>
      );
    const country = settings.addressLinesFilter.displayCountry &&
      venue.address.country && (
        <AppParagraph2 style={allowedOverflow}>
          <span id={"summary-card-venue-country"}>{venue.address.country}</span>
        </AppParagraph2>
      );
    const postcode = settings.addressLinesFilter.displayPostcode &&
      venue.address.postcode && (
        <AppParagraph2 style={allowedOverflow}>
          <span id={"summary-card-venue-postcode"}>
            {venue.address.postcode}
          </span>
        </AppParagraph2>
      );

    return (
      <>
        {name}
        {street1}
        {street2}
        {street3}
        {street4}
        {city}
        {region}
        {country}
        {postcode}
      </>
    );
  };

  const renderVenueDescription = () => {
    return (
      <div className="d-flex">
        <div className="mr-2 flex-grow-1 flex-shrink-1 text-left">
          <AppParagraph2
            id={"summary-card-venue-name"}
            className={"color-text1 font-medium mb-2"}
            style={allowedOverflow}
          >
            {venue.name}
          </AppParagraph2>
          {renderAddress()}
          {venue.phoneNumber && (
            <VenuePhoneNumber
              phoneNumber={venue.phoneNumber}
              defaultCountryCode={venue.defaultCountryCode}
            />
          )}
        </div>
        <div className="flex-grow-1 flex-shrink-0 text-right">
          {venue.geoLocation && userCoordinates && (
            <>
              <AppParagraph2
                id={"summary-card-venue-distance"}
                className="mb-0 color-text1"
              >
                {getDistance(
                  venue.geoLocation,
                  userCoordinates,
                  distanceUnit
                ).toFixed(2)}
              </AppParagraph2>
              <AppParagraph3
                id={"summary-card-venue-miles"}
                className={"text-lowercase color-text2"}
              >
                {t("miles", { defaultValue: "Miles" })}
              </AppParagraph3>
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <SummaryCard
      id={"summary-card-venue"}
      icon={
        <AddressIcon
          size={14}
          // title={`${t("address", { defaultValue: "Address" })} icon`}
        />
      }
      linkText={linkText}
      onLinkClick={onLinkClick}
      title={t("address", { defaultValue: "Address" })}
    >
      {loading ? (
        <div
          className="flex-column animated-background"
          style={{ height: 98 }}
        />
      ) : (
        renderVenueDescription()
      )}
    </SummaryCard>
  );
};
