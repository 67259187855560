import { Moment } from "moment";

export enum WeekDays {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

export type WeekDaysTypes = keyof typeof WeekDays;

export interface OpeningHours {
  closingTime: Moment;
  openingTime: Moment;
}

// Interface to support access by index
declare interface OpeningPeriodIndex<Type> {
  [key: string]: OpeningHours;
}

export type OpeningPeriod = OpeningPeriodIndex<WeekDays> &
  { [T in WeekDaysTypes]: OpeningHours };
