import { STEP_NAMES } from "../../shared";

export const SET_CURRENT_LAYOUT = "SET_CURRENT_LAYOUT";

export const SAVE_ADD_ONS = "SAVE_ADD_ONS";

export type ProductJourney =
  | STEP_NAMES.PRODUCTS
  | STEP_NAMES.PRODUCTS_BEFORE_STORE
  | STEP_NAMES.LIVE_CHAT_PRODUCTS
  | STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE;

export const NUM_PRODUCTS_LIMIT = "20";
