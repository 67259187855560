const getBWID = () => {
  if (process && process.browser) {
    const BWID = window.location.pathname.split("/");

    const match = window.location.search.match(/.*[?&]bwid=(\w*)/i);

    const param = match && match[1];

    return param || BWID[1];
  }

  return;
};

module.exports = {
  getBWID
};
