import moment from "moment";
import router from "next/router";
import { Questions } from "../shared";

export const QUESTION_ANSWERS_QUERY_PARAM_KEY = "qa";

let cachedAnswers: Record<number, string> | undefined;

export const clearCachedAnswers = () => (cachedAnswers = undefined);

export const getAnswersFromQueryParams = (): Record<number, string> => {
  if (cachedAnswers) {
    return cachedAnswers;
  }

  // I've noticed sometimes `router.query.qa` is not populated; Added my previous implementation as fallback
  const qa =
    router.query.qa ||
    new URL(location.href).searchParams.get(QUESTION_ANSWERS_QUERY_PARAM_KEY);

  if (!qa || qa === "") {
    cachedAnswers = {};
    return cachedAnswers;
  }

  try {
    cachedAnswers = JSON.parse(decodeURI(atob(qa as string)));
  } catch {
    cachedAnswers = {};
  }

  return cachedAnswers as Record<number, string>;
};

export const getAnswerFromQueryParams = (
  questionId: number | string
): string | undefined => getAnswersFromQueryParams()[Number(questionId)];

export const getDateAnswerFromQueryParams = (
  questionId: string
): Date | undefined => {
  const answer = getAnswerFromQueryParams(questionId);
  if (answer && moment(answer).isValid()) {
    return moment(answer).toDate();
  }
  return undefined;
};

export const getIsAllQuestionsAnsweredViaQueryParams = (
  questions: Questions[] = []
): boolean => {
  if (questions.length < 1) {
    return false;
  }
  return questions.every((question) => getAnswerFromQueryParams(question.id));
};
