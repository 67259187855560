import isMobileFn from "ismobilejs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { Theme } from "../../shared";

export interface AppThemeProviderProps {
  theme?: Theme | void;
  children: React.ReactChild;
}

const getIsMobile = () =>
  typeof window === "undefined"
    ? false
    : isMobileFn().phone || window.innerWidth < 768;

export const AppThemeProvider = ({
  theme,
  children
}: AppThemeProviderProps) => {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  // Update setIsMobileValue value on resize
  useEffect(() => {
    const handleResize = _.debounce(() => {
      setIsMobile(getIsMobile());
    }, 80);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ThemeProvider
      theme={{
        ...theme,
        isMobile
      }}
    >
      {children}
    </ThemeProvider>
  );
};
