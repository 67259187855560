import { ofType } from "redux-observable";
import { concat, of, EMPTY } from "rxjs";
import { mergeMap } from "rxjs/operators";
import config from "../../../../config";
import { makeRequest } from "../../../services/api/actions";
import { getBWID } from "../../../utils/getBWID";
import { getSingleVenueResponse } from "../../bookingConfirmation/selectors";
import { getSingleProductResponse } from "../../products/selectors";
import { getCurrentLanguage } from "../selectors";
import { CHANGE_LANGUAGE } from "../types";
import { getIsLiveChat } from "../../summary/selectors";

export const changeLanguagesEpic = (action$: any, store: any) => {
  return action$.pipe(
    ofType(CHANGE_LANGUAGE),
    mergeMap(() => {
      const venue = getSingleVenueResponse(store.value);
      const venueId = venue && venue.id;

      const product = getSingleProductResponse(store.value);
      const productId = product && product.id;

      const language = getCurrentLanguage(store.value);

      const isLiveChat = getIsLiveChat(store.value);

      if (productId && (isLiveChat || venueId)) {
        return concat(
          of(
            makeRequest(
              {
                method: "GET",
                url: `${config.baseApiUrl}/${getBWID()}${
                  isLiveChat ? "" : "/venues/" + venueId
                }/products/${productId}?lang=${language}`,
              },
              "SINGLE_PRODUCT"
            )
          ),
          of(
            makeRequest(
              {
                method: "GET",
                url: `${config.baseApiUrl}/${getBWID()}${
                  isLiveChat ? "" : "/venues/" + venueId
                }/products/${productId}/${
                  isLiveChat ? "addons" : "add-ons"
                }?lang=${language}`,
              },
              "ADDONS"
            )
          )
        );
      }
      return EMPTY;
    })
  );
};
