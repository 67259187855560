import { ApiLanguages, StepNames } from "../../shared";
import { TypedMap } from "../../utils/immutableTypesHelper";

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const SET_FIRST_PAGE = "SET_FIRST_PAGE";
export const CLEAR_PRODUCT = "CLEAR_PRODUCT";
export const CLEAR_ADDON = "CLEAR_ADDON";
export const CLEAR_ALL = "CLEAR_ALL";
export const CLEAR_ADVISOR = "CLEAR_ADVISOR";
export const CLEAR_RESERVATION = "CLEAR_RESERVATION";
export const CLEAR_BOOKING = "CLEAR_BOOKING";
export const CLEAR_COORDINATES = "CLEAR_COORDINATES";
export const CLEAR_VENUE = "CLEAR_VENUE";
export const CLEAR_DATEPICKER_SLOTS = "CLEAR_DATEPICKER_SLOTS";
export const CLEAR_FIRST_AVAILABLE_SLOT = "CLEAR_FIRST_AVAILABLE_SLOT";
export const CLEAR_NEARBY_VENUES_SEARCH = "CLEAR_NEARBY_VENUES_SEARCH";
export const SSO_REDIRECT = "SSO_REDIRECT";

export interface LayoutState extends TypedMap {
  availableLanguages?: string[] | null;
  currentLanguage?: string | null;
  firstPage?: StepNames;
}

interface ChangeLanguageAction {
  language: string;
  type: typeof CHANGE_LANGUAGE;
}

export interface SetLanguagesAction {
  apiLanguagesObj: ApiLanguages;
  type: typeof SET_LANGUAGES;
}

export interface SetFirstPageAction {
  firstPage: StepNames;
  type: typeof SET_FIRST_PAGE;
}

export type LayoutActionTypes =
  | ChangeLanguageAction
  | SetLanguagesAction
  | SetFirstPageAction;
