var API_URL = "";
var APP_VERSION = "";
var ENV = "production";

if (process.browser) {
  try {
    const getConfig = require("next/config").default;
    const { publicRuntimeConfig } = getConfig();

    API_URL = publicRuntimeConfig.API_URL;
    APP_VERSION = publicRuntimeConfig.APP_VERSION;
    ENV = process.env.NODE_ENV;
  } catch {}
}

module.exports = {
  baseApiUrl: API_URL,
  appVersion: APP_VERSION,
  googleApiKey:
    ENV === "development"
      ? "AIzaSyD-6sTKVCXzJFESJGciVDugzfhDvhrJERw"
      : "AIzaSyDWmzClyXhH5j8FEzmn9BwOPOU_M0tn3jM",
  mockedBaseApiUrl:
    "https://348620a2-405b-47cf-801d-687b61956869.mock.pstmn.io", // "http://localhost:8000", // "https://virtserver.swaggerhub.com/joeld/booking-widget_api/0.1.0-draft",
  mockBWCode: "YO29LMP12", // "YO29LMP12", // W27ORT5NN63,
  isMultiProductSelectionActive: false,
  isQueueIncluded: false,
  recaptchaKey: "6LfXsCsUAAAAAN_i1xg8rmX0_v_ffltda1iB--9O",
  openCageKey: "5baabc3a85b54dfdbe5b24f7dc9ad509",
  poweredByHeader: false,
  sentryUrl:
    "https://31b56818576b492d8b7a37667f639950@o159832.ingest.sentry.io/5862467"
};
