import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTracking as reactTracking } from "react-tracking";
import { getSingleVenueResponse } from "../components/bookingConfirmation/selectors";
import { useRouter } from "next/router";
import { onBookHereEvent } from "../components/analytics/eventCreators";

export const useTracking = (app?: any, dispatch?: any) => {
  const { trackEvent, Track } = reactTracking(app, dispatch);
  return {
    trackEvent,
    Track,
  };
};

export const useTrackEvent = () => {
  const { trackEvent } = useTracking();
  const venue = useSelector(getSingleVenueResponse);
  const router = useRouter();

  useEffect(() => {
    const hasDeeplinked = router.query.from?.includes("/venues");
    if (venue?.name && hasDeeplinked) {
      trackEvent(onBookHereEvent(venue.name));
    }
  }, [venue?.name]);
};
