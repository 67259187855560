import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "reactstrap";
import Container from "reactstrap/lib/Container";
import styled, { withTheme } from "styled-components";
import {
  allowedOverflow,
  AppHeading1,
  AppSubHeading,
  AppLink,
  ErrorIcon,
  StepNames,
} from "../../shared";
import { Theme } from "../../shared/interfaces/theme";
import { ApplicationState } from "../../store";
import {
  getFirstPageFromRouter,
  getIdsFromRouter,
} from "../../utils/getFirstPageFromRouter";
import PageRouter from "../../utils/pageRouter";
import { clearAll as clearAllAction } from "../layout/actions";
import { getFirstPage } from "../layout/selectors";
import { Action, Dispatch } from "redux";
import { connect } from "react-redux";
import { getError } from "../errorManager/selectors";
import { ApiError } from "../../shared/interfaces/apiError";
import Router from "next/router";
import colors from "../../styles/theme/colors";
import Cookies from "js-cookie";

const CenteredContainer = styled(Container)<{ fullHeight: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ fullHeight }) =>
    fullHeight ? "center" : "space-between"};
  align-content: center;
  height: ${({ fullHeight }) => (fullHeight ? "100vh" : "100%")};
  max-height: ${({ fullHeight }) => (fullHeight ? "100%" : "700px")};
`;

interface StateProps {
  shouldReload: boolean;
  settingsError?: ApiError | void;
  firstPage?: StepNames;
}

interface DispatchProps {
  clearAll?: () => void;
}

type Props = StateProps & DispatchProps & { theme: Theme };

const ErrorLayoutBase: React.FunctionComponent<Props> = ({
  theme,
  clearAll,
  firstPage,
  shouldReload,
  settingsError,
}: Props) => {
  const { t } = useTranslation();

  const tryAgain = () => {
    if (shouldReload) {
      Cookies.remove("BwToken");
      Router.reload();
    } else {
      const { venueId, queueId, productId } = getIdsFromRouter();

      if (clearAll) {
        clearAll();
      }

      PageRouter.goToFirstPage(
        firstPage || getFirstPageFromRouter(),
        venueId,
        queueId,
        productId
      );
    }
  };

  const getSettingsError = () => {
    if (settingsError) {
      if (
        settingsError.status >= 400 &&
        settingsError.status <= 499 &&
        settingsError.errors
      ) {
        return (
          settingsError.errors[0] &&
          `${settingsError.errors[0].description.toUpperCase()} "${
            settingsError.errors[0].value
          }"`
        );
      } else if (
        settingsError.status === 0 ||
        (settingsError.status >= 500 && settingsError.status <= 599)
      ) {
        return t("errorMessageSystemNotAvailable", {
          defaultValue:
            "We are aware of current issues with our appointment system. We will be back online shortly.",
        });
      } else if (settingsError.status === 404) {
        return t("errorMessageBookingServiceDoesNotExist", {
          defaultValue: "This booking service does not exist",
        });
      }
    }

    return "";
  };

  return (
    <>
      <CenteredContainer
        className={"text-center py-4"}
        fullHeight={!!settingsError}
      >
        <div>
          <Row>
            <AppHeading1
              className={"w-100 px-4"}
              style={{ ...allowedOverflow, opacity: 1 }}
              theme={theme}
            >
              {t("errorMessage", {
                defaultValue: "We’re sorry. There has been an error.",
              })}
            </AppHeading1>
          </Row>
          {settingsError ? (
            <Row>
              <AppSubHeading
                className={"w-100 px-4 mt-4"}
                style={{ ...allowedOverflow, opacity: 1, color: colors.danger }}
              >
                {getSettingsError()}
              </AppSubHeading>
            </Row>
          ) : (
            <></>
          )}
        </div>
        <Row>
          {theme.isMobile ? (
            <ErrorIcon className={"w-100 justify-content-center"} size={280} />
          ) : (
            <ErrorIcon
              className={"w-100 justify-content-center"}
              size={400}
              style={{ marginBottom: 80 }}
            />
          )}
        </Row>
        <Row>
          <div className={"w-100 d-flex justify-content-center"}>
            {(!settingsError ||
              (settingsError?.status !== 404 &&
                settingsError?.status !== 400)) && (
              <AppLink fontSize={18} onClick={tryAgain} theme={theme}>
                {t("pleaseTryAgain", {
                  defaultValue: settingsError
                    ? "Please try again later."
                    : "Please go back and try again.",
                })}
              </AppLink>
            )}
          </div>
        </Row>
      </CenteredContainer>
    </>
  );
};

const mapStateToProps = (state: ApplicationState): StateProps => {
  const settingsError = getError(state, "SETTINGS");
  return {
    firstPage: getFirstPage(state),
    settingsError,
    shouldReload: Boolean(
      settingsError || getError(state, "SSO_CUSTOMER_DETAILS")
    ),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => {
  return {
    clearAll: () => {
      dispatch(clearAllAction());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ErrorLayoutBase));
