import { ConfirmationQuestions } from "../../shared";
import { TypeKeys } from "./actionTypes";
import { Attachment, Attachments, SelectedFile } from "./types";
export interface AddAttachment {
  questionId: string;
  files: Attachments;
  reservationId: string | string[];
  type: TypeKeys.ADD_ATTACHMENT;
  isConfirmationPage: boolean;
}

export const addAttachment = (
  questionId: string,
  files: Attachments,
  reservationId: string | string[],
  isConfirmationPage: boolean
): AddAttachment => ({
  questionId,
  files,
  reservationId,
  type: TypeKeys.ADD_ATTACHMENT,
  isConfirmationPage,
});

export interface DeleteAttachment {
  questionId: string;
  file: Attachment;
  index: number;
  reservationId: string | string[];
  type: TypeKeys.DELETE_ATTACHMENT;
  isConfirmationPage: boolean;
}

export const deleteAttachment = (
  questionId: string,
  file: Attachment,
  index: number,
  reservationId: string | string[],
  isConfirmationPage: boolean
): DeleteAttachment => ({
  questionId,
  file,
  index,
  reservationId,
  isConfirmationPage,
  type: TypeKeys.DELETE_ATTACHMENT,
});

export interface DeleteAttachmentSuccess {
  questionId: string;
  index: number;
  type: TypeKeys.DELETE_ATTACHMENT_SUCCESS;
}

export const deleteAttachmentSuccess = (questionId: string, index: number) => ({
  questionId,
  index,
  type: TypeKeys.DELETE_ATTACHMENT_SUCCESS,
});

export interface DeleteAttachmentFail {
  questionId: string;
  index: number;
  type: TypeKeys.DELETE_ATTACHMENT_FAIL;
}

export const deleteAttachmentFail = (questionId: string, index: number) => ({
  questionId,
  index,
  type: TypeKeys.DELETE_ATTACHMENT_FAIL,
});
export interface PreSignedUrlRequestCompleted {
  payload: SelectedFile[];
  type: TypeKeys.PRESIGNED_URL_REQUEST_COMPLETED;
}

export const preSignedUrlRequestCompleted = (
  payload: SelectedFile[]
): PreSignedUrlRequestCompleted => ({
  payload,
  type: TypeKeys.PRESIGNED_URL_REQUEST_COMPLETED,
});

export interface UploadAttachmentsCompleted {
  payload: SelectedFile[];
  type: TypeKeys.UPLOAD_ATTACHMENTS_COMPLETED;
}

export const uploadAttachmentsCompleted = (
  payload: SelectedFile[]
): UploadAttachmentsCompleted => ({
  payload,
  type: TypeKeys.UPLOAD_ATTACHMENTS_COMPLETED,
});

export interface RemoveFile {
  questionId: string;
  file: Attachment;
  index: number;
  type: TypeKeys.REMOVE_FILE;
}

export const removeFile = (
  questionId: string,
  file: Attachment,
  index: number
): RemoveFile => ({
  questionId,
  file,
  index,
  type: TypeKeys.REMOVE_FILE,
});

export interface ClearAll {
  type: TypeKeys.CLEAR_ALL;
}

export interface InitUploadState {
  type: TypeKeys.INIT_UPLOAD_STATE;
}

export const initUploadState = (): InitUploadState => ({
  type: TypeKeys.INIT_UPLOAD_STATE,
});

export interface TransformAnswerFilesOfConfirmedQuestions {
  type: TypeKeys.TRANSFORM_ANSWER_FILES_OF_CONFIRMED_QUESTIONS;
  questions: ConfirmationQuestions[];
}

export const transformAnswerFilesOfConfirmedQuestions = (
  questions: ConfirmationQuestions[]
): TransformAnswerFilesOfConfirmedQuestions => ({
  type: TypeKeys.TRANSFORM_ANSWER_FILES_OF_CONFIRMED_QUESTIONS,
  questions,
});

export type AttachmentAction =
  | AddAttachment
  | PreSignedUrlRequestCompleted
  | UploadAttachmentsCompleted
  | DeleteAttachment
  | DeleteAttachmentSuccess
  | DeleteAttachmentFail
  | RemoveFile
  | ClearAll
  | InitUploadState
  | TransformAnswerFilesOfConfirmedQuestions;
