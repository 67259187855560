import { Map } from "immutable";
import { Advisor, PersonLanguageLabel } from "../../shared";
import { ApplicationState } from "../../store";

const getAdvisors = (state: ApplicationState): Map<string, any> => {
  return state.api.get("ADVISORS");
};

export const isLoadingAdvisors = (state: ApplicationState): boolean => {
  if (!getAdvisors(state)) {
    return true;
  }

  return getAdvisors(state).get("isLoading");
};

export const getAdvisorsResponse = (
  state: ApplicationState
): Advisor[] | void => {
  if (!getAdvisors(state) || !getAdvisors(state).get("payload")) {
    return;
  }

  return getAdvisors(state).getIn(["payload", "response", "staff"]);
};

const getSlots = (state: ApplicationState): Map<string, any> => {
  return state.slots;
};

export const getBookingDate = (state: ApplicationState): string | void => {
  if (!getSlots(state) || !getSlots(state).get("date")) {
    return;
  }

  return getSlots(state).get("date");
};

export const getCaptcha = (state: ApplicationState): string | void => {
  if (!getSlots(state) || !getSlots(state).get("captcha")) {
    return;
  }

  return getSlots(state).get("captcha");
};

const getSingleAdvisor = (state: ApplicationState): Map<string, any> => {
  return state.api.get("SINGLE_ADVISOR");
};

export const isLoadingAdvisor = (state: ApplicationState): boolean => {
  if (!getSingleAdvisor(state)) {
    return true;
  }

  return getSingleAdvisor(state).get("isLoading");
};

export const getSingleAdvisorResponse = (
  state: ApplicationState
): Advisor | void => {
  if (!getSingleAdvisor(state) || !getSingleAdvisor(state).get("payload")) {
    return;
  }

  return getSingleAdvisor(state).getIn(["payload", "response"]);
};

export const getStaffMultiLanguageInfo = (
  advisor: Advisor,
  language: string
): Omit<PersonLanguageLabel, "languageCode"> => {
  const staffIno =
    advisor.personLanguageLabels &&
    advisor.personLanguageLabels.find(
      personLanguageLabel => personLanguageLabel.languageCode === language
    );

  return {
    description:
      (staffIno && staffIno.description) || advisor.description || "",
    externalLink:
      (staffIno && staffIno.externalLink) || advisor.externalLink || "",
    externalName:
      (staffIno && staffIno.externalName) || advisor.externalName || "",
    role: (staffIno && staffIno.role) || advisor.role || ""
  };
};

export const getAdvisorsExternalNameByLanguage = (
  advisors: Advisor[],
  search: string,
  language: string
): Advisor[] => {
  return advisors.reduce(
    (acc: Advisor[], cur: Advisor) => {
      const advisorsFilteredByLanguage =
        cur.personLanguageLabels &&
        cur.personLanguageLabels.filter(lng => lng.languageCode === language);

      const matchedAdvisors =
        (advisorsFilteredByLanguage &&
          advisorsFilteredByLanguage.filter(
            advisor =>
              advisor.externalName &&
              advisor.externalName
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1
          )) ||
        [];

      if (matchedAdvisors && matchedAdvisors.length > 0) {
        return [...acc, cur];
      } else {
        return acc;
      }
    },
    [] as Advisor[]
  );
};
