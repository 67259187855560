import { AnyAction } from "redux";
import { ofType } from "redux-observable";
import { of, throwError } from "rxjs";
import { ajax } from "rxjs/ajax";
import { v4 as uuid } from "uuid";
import {
  catchError,
  concatMap,
  delay,
  mergeMap,
  retryWhen,
} from "rxjs/operators";
import { clearReservation } from "../../../components/layout/actions";
import { getResponse, requestEnds } from "../actions";
import { API_REQUEST_INIT, HTTP_ERROR } from "../constants";

const BOOKER_IDENTIFIER = uuid();

export interface RequestData {
  url: string;
  method: string;
  headers?: object;
  body?: object;
  timeout?: number;
  withCredentials?: boolean;
}

export const makeRequestEpic = (action$: any) => {
  return action$.pipe(
    ofType(API_REQUEST_INIT),
    mergeMap((action: any) => {
      // Add default properties to the request
      const headers = {
        "Content-Type": "application/json",
        "X-Booker-Identifier": BOOKER_IDENTIFIER,
        ...action.requestData.headers,
      };
      const requestData = {
        timeout: 30000,
        withCredentials: true,
        ...action.requestData,
        headers,
      };
      return ajax(requestData).pipe(
        concatMap((response: object) => {
          const actionMap: AnyAction[] = [
            getResponse(
              response,
              action.name,
              action.paginationType,
              action.resetData
            ),
            requestEnds(action.name),
          ];

          // Dispatch CLEAR_RESERVATION action to clear reservation data when booking is created
          if (
            action.requestData.method === "PUT" &&
            action.name === "BOOKING"
          ) {
            actionMap.push(clearReservation());
          }

          return actionMap;
        }),
        retryWhen((errors) =>
          errors.pipe(
            concatMap((e, i) => {
              return i >= 2 ||
                e.status === 400 ||
                e.status === 404 ||
                e.status === 410
                ? // If the condition is true we throw the error (we passed the max requests)
                  throwError(e)
                : // Otherwise we pipe this back into our stream and delay the retry
                  of(e).pipe(delay(5000));
            })
          )
        ),
        catchError((error) =>
          of({
            error,
            isLoading: false,
            name: `${action.name}`,
            type: HTTP_ERROR,
          })
        )
      );
    })
  );
};
