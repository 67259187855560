import * as React from "react";
import { FunctionComponent } from "react";
import styled, { css } from "styled-components";
import { Theme } from "..";
import fonts from "../../styles/theme/fonts";
import { customStyle } from "../../styles/theme/themeHelper";

export const AppParagraphStyle = (props: Props) => css`
  opacity: ${props.theme.logo ? 1 : 0};
  transition: opacity 0.5s ease-in-out;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  margin: 0;
  font-size: ${props.fontSize + "px"};
  line-height: ${props.lineHeight + "px"};
  color: ${props.color};
  font-weight: ${props.fontWeight};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${customStyle(props.theme)}
`;

export const allowedOverflow: React.CSSProperties = {
  overflow: "unset",
  overflowWrap: "break-word",
  textOverflow: "unset",
  whiteSpace: "pre-wrap",
};
/* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */

export interface AppParagraphProps extends React.ParamHTMLAttributes<any> {
  color?: string;
  fontSize?: number;
  fontWeight?: number;
  lineHeight?: number;
}

type Props = AppParagraphProps & { theme: Theme };

export const AppParagraph1: FunctionComponent<AppParagraphProps> = styled.p<Props>`
  ${(props) =>
    AppParagraphStyle({
      color: props.color || props.theme.body.font.secondaryTextColor,
      fontSize: fonts.paragraph1.size,
      fontWeight: fonts.paragraph1.weight,
      lineHeight: fonts.paragraph1.height,
      ...props,
    })};
`;

export const AppParagraph2: FunctionComponent<AppParagraphProps> = styled.p<Props>`
  ${(props) =>
    AppParagraphStyle({
      color: props.color || props.theme.body.font.secondaryTextColor,
      fontSize: fonts.paragraph2.size,
      fontWeight: fonts.paragraph2.weight,
      lineHeight: fonts.paragraph2.height,
      ...props,
    })}
`;

export const AppSpan2: FunctionComponent<AppParagraphProps> = styled.span<Props>`
  ${(props) =>
    AppParagraphStyle({
      color: props.color || props.theme.body.font.secondaryTextColor,
      fontSize: fonts.paragraph2.size,
      fontWeight: fonts.paragraph2.weight,
      lineHeight: fonts.paragraph2.height,
      ...props,
    })}
`;

export const AppParagraph3: FunctionComponent<AppParagraphProps> = styled.p<Props>`
  ${(props) =>
    AppParagraphStyle({
      color: props.color || props.theme.body.font.secondaryTextColor,
      fontSize: fonts.paragraph3.size,
      fontWeight: fonts.paragraph3.weight,
      lineHeight: fonts.paragraph3.height,
      ...props,
    })}
`;

export const AppParagraph4: FunctionComponent<AppParagraphProps> = styled.p<Props>`
  ${(props) =>
    AppParagraphStyle({
      color: props.color || props.theme.body.font.secondaryTextColor,
      fontSize: fonts.paragraph4.size,
      fontWeight: fonts.paragraph4.weight,
      lineHeight: fonts.paragraph4.height,
      ...props,
    })}
`;

export const AppPreFooterStyled = styled(AppParagraph3)`
  a {
    color: ${(props) => props.theme.body.buttons.secondaryBackgroundColor};
  }
`;
