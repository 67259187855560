import React from "react";
import ReactSVG from "react-svg";
import Icons from "../../styles/theme/icons";
import { styled } from "../../styles/theme/themeHelper";

const AppIconStyled = styled(
  ({
    size,
    color,
    rotate,
    hasFill,
    hasStroke,
    title,
    titleId,
    role,
    ariaLabelledBy,
    ariaHiddenValue,
    ariaLabelValue,
    ...props
  }) =>
    title ? (
      <ReactSVG
        beforeInjection={(svg: Element) => {
          if (svg) {
            const titleInside = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "title"
            );
            titleInside.innerHTML = title;
            if (titleId) {
              titleInside.id = titleId;
            }
            if (role) {
              svg.setAttribute("role", "img");
            }
            if (ariaLabelledBy) {
              svg.setAttribute("aria-labelledby", ariaLabelledBy);
            }
            if (ariaHiddenValue) {
              svg.setAttribute("aria-hidden", ariaHiddenValue);
            }
            if (ariaLabelValue) {
              svg.setAttribute("aria-label", ariaLabelValue);
            }

            svg.insertBefore(titleInside, svg.childNodes[0]);
          }
        }}
        {...props}
        wrapper="span"
      />
    ) : (
      <ReactSVG
        beforeInjection={(svg) => {
          if (ariaHiddenValue) {
            svg.setAttribute("aria-hidden", ariaHiddenValue);
          }
          if (ariaLabelValue) {
            svg.setAttribute("aria-label", ariaLabelValue);
          }
          if (role) {
            svg.setAttribute("role", role);
          }
        }}
        {...props}
        wrapper="span"
      />
    )
)<AppIconProps>`
  display: ${(props) => (props.hidden ? "none" : "inline-flex")};

  // div size
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};

  & > span {
    display: inline-flex;
  }

  svg {
    // svg size
    height: ${(props) => `${props.size}px`};
    width: ${(props) => `${props.size}px`};

    ${({ hasFill, color, theme }) =>
      hasFill &&
      (color || (theme && theme.body)) &&
      `
        > path, > g, > g > path, > g > g > path, > g > circle, > use {
          fill: ${color || theme.body.font.secondaryTextColor} !important;
        }
      `}

    ${({ hasStroke, color, theme }) =>
      hasStroke &&
      (color || (theme && theme.body)) &&
      `
        > path, > g, > g > path {
          stroke: ${color || theme.body.font.secondaryTextColor} !important;
        }
      `}
    
    ${({ rotate }) =>
      rotate &&
      `
        transform: rotate(${rotate}deg);
      `}
  }
`;

const AppTickIconStyled = styled(
  ({ size, fillColor, strokeColor, ...props }) => (
    <ReactSVG {...props} wrapper={"span"} />
  )
)<AppTickIconProps>`
  display: inline-flex;
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};

  & > span {
    display: inline-flex;
  }

  svg {
    height: ${(props) => `${props.size}px`};
    width: ${(props) => `${props.size}px`};

    > g > circle {
      fill: ${(props) => props.fillColor} !important;
    }

    > g > path {
      stroke: ${(props) => props.strokeColor} !important;
    }
  }
`;

export interface AppIconProps {
  size?: number;
  color?: string;
  src?: string;
  rotate?: number;
  hidden?: boolean;
  title?: string;
  titleId?: string;
  role?: string;
  ariaLabelledBy?: string;
  ariaHiddenValue?: boolean;
  ariaLabelValue?: string;
}

export interface AppTickIconProps {
  fillColor?: string;
  strokeColor?: string;
}

interface AppStyleProps {
  hasFill?: boolean;
  hasStroke?: boolean;
}

type Props = React.HTMLAttributes<React.ReactNode> &
  AppIconProps &
  AppTickIconProps;

export const AppIcon: React.FunctionComponent<Props & AppStyleProps> = ({
  size = 18,
  ...props
}: Props) => {
  const mergedProps = {
    ...props,
    size,
  };

  return <AppIconStyled {...mergedProps} />;
};

const AppTickIcon: React.FunctionComponent<Props> = ({
  size = 18,
  ...props
}: Props) => {
  const mergedProps = {
    ...props,
    size,
  };

  return <AppTickIconStyled {...mergedProps} />;
};

export const AddIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.add} hasFill={true} />;
};

export const AddressIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return (
    <AppIcon
      {...props}
      src={Icons.address}
      hasFill={true}
      title={props.title}
    />
  );
};

export const AdvisorIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.advisor} hasFill={true} />;
};

export const SummaryAdvisorIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.summaryAdvisor} hasFill={true} />;
};

export const AlertIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.alert} hasFill={true} />;
};

export const AnyAdvisorIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.anyAdvisor} />;
};

export const ArrowDownIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.arrowDown} rotate={0} hasFill={true} />;
};

export const ArrowLeftIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return (
    <AppIcon {...props} src={Icons.arrowDown} rotate={90} hasFill={true} />
  );
};

export const ArrowRightIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return (
    <AppIcon {...props} src={Icons.arrowDown} rotate={-90} hasFill={true} />
  );
};

export const ArrowUpIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return (
    <AppIcon {...props} src={Icons.arrowDown} rotate={180} hasFill={true} />
  );
};

export const ArrowHideContentIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.hideContent} hasStroke={true} />;
};

export const ArrowShowContentIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return (
    <AppIcon {...props} src={Icons.hideContent} rotate={180} hasStroke={true} />
  );
};

export const BackArrowIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.backArrow} hasStroke={true} />;
};

export const CalendarIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.calendar} hasFill={true} />;
};

export const CancellationIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.cancellation} hasFill={true} />;
};

export const CartIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.cart} hasStroke={true} />;
};

export const CloseIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.close} hasStroke={true} />;
};

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 15px;
  right: 15px;

  &:hover {
    cursor: pointer;
  }
`;

export const CyclingIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.cycling} hasFill={true} />;
};

export const DrivingIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.driving} hasFill={true} />;
};

export const EmailIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.email} hasFill={true} />;
};

export const ErrorIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.error} hasFill={false} />;
};

export const GoogleIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.googleIcon} hasStroke={true} />;
};

export const GroupIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.group} hasFill={true} />;
};

export const InfoCircleIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.infoCircle} hasFill={true} />;
};

export const LanguageIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.language} hasFill={true} />;
};

export const NextIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return (
    <AppIcon {...props} src={Icons.next} hasFill={true} color={props.color} />
  );
};

export const PinIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.pin} hasFill={true} />;
};

export const ProductIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.product} hasFill={true} />;
};

export const RemoveIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.remove} hasFill={true} />;
};

export const SelectedIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.selected} hasFill={true} />;
};

export const TickIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppTickIcon {...props} src={Icons.selected} />;
};

export const SelectedMarkerIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.selectedMarker} hasFill={true} />;
};

export const ShapeIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.shape} hasFill={true} />;
};

export const StarEmpty: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.starEmpty} />;
};

export const StarFull: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.starFull} />;
};

export const TelephoneIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.telephone} hasFill={true} />;
};

export const TimeIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.time} hasFill={true} />;
};

export const TransportIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.transport} hasFill={true} />;
};

export const UserLocationIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.userLocation} hasFill={true} />;
};

export const WalkingIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.walking} hasFill={true} />;
};

export const UploadIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.upload} hasFill />;
};

export const PlusIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.plus} hasFill={true} />;
};

export const DeleteIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.delete} hasFill={true} />;
};

export const FailIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.fail} hasFill={false} />;
};

export const EditIcon: React.FunctionComponent<Props> = ({
  ...props
}: Props) => {
  return <AppIcon {...props} src={Icons.edit} hasFill={true} />;
};
