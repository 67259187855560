import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DataMessage } from "amazon-chime-sdk-js";
import { useAudioVideo } from "amazon-chime-sdk-component-library-react";
import { VideoMeetingMessage } from "./types";
import { CHAT_DATA_MESSAGE_TYPE } from "./constants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getIsChatActive, getQudiniVideoMeetingDetails } from "./selectors";

export interface VideoMeetingChatStateValue {
  chatData: VideoMeetingMessage[];
  sendChatData: (data: string) => void;
  readAllMessages: () => void;
  countUnreadMessages: () => string | undefined;
}

const VideoMeetingChatStateContext =
  React.createContext<VideoMeetingChatStateValue | null>(null);

export const useVideoMeetingChatState = (): VideoMeetingChatStateValue => {
  const state = useContext(VideoMeetingChatStateContext);
  if (!state) {
    throw new Error("Error using Video Meeting in context!");
  }
  return state;
};

export const VideoMeetingChatProvider: React.FC = ({ children }) => {
  const audioVideo = useAudioVideo();
  const { t } = useTranslation();
  const qudiniVideoMeetingDetails = useSelector(getQudiniVideoMeetingDetails);
  const isChatActive = useSelector(getIsChatActive);

  const [chatData, setChatData] = useState<VideoMeetingMessage[]>(
    [] as VideoMeetingMessage[]
  );

  const countUnreadMessages = useCallback(() => {
    const unreadMsgs = chatData.filter((msg) => !msg.read).length;
    if (unreadMsgs === 0) {
      return undefined;
    }
    if (unreadMsgs > 9) {
      return "9+";
    }
    return unreadMsgs.toString();
  }, [chatData]);

  const sendChatData = useCallback(
    (data: string) => {
      const timestampMs = new Date().getTime();
      audioVideo?.realtimeSendDataMessage(CHAT_DATA_MESSAGE_TYPE, data);
      const sentVideoMessage: VideoMeetingMessage = {
        data,
        timestampMs,
        senderExternalUserId: t("qudiniVideoServiceSelfNamePlate", {
          defaultValue: "Me",
        }),
        senderAttendeeId: qudiniVideoMeetingDetails?.attendees?.[0].attendeeId,
        read: true,
      };
      setChatData((oldChatData) => [...oldChatData, sentVideoMessage]);
    },
    [audioVideo, qudiniVideoMeetingDetails?.attendees, t]
  );

  const receiveChatData = (dataMessage: DataMessage) => {
    const parsedDataMsg = {
      ...dataMessage,
      data: new TextDecoder().decode(dataMessage.data),
      read: isChatActive,
    };
    setChatData((oldChatData) => [
      ...oldChatData,
      parsedDataMsg as VideoMeetingMessage,
    ]);
  };

  const readAllMessages = useCallback(() => {
    chatData.forEach((msg) => (msg.read = true));
  }, [chatData]);

  useEffect(() => {
    audioVideo?.realtimeSubscribeToReceiveDataMessage(
      CHAT_DATA_MESSAGE_TYPE,
      receiveChatData
    );
    return () => {
      audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(
        CHAT_DATA_MESSAGE_TYPE
      );
    };
  });

  const providerValue = useMemo(
    () => ({ chatData, sendChatData, readAllMessages, countUnreadMessages }),
    [chatData, sendChatData, readAllMessages, countUnreadMessages]
  );

  return (
    <VideoMeetingChatStateContext.Provider value={providerValue}>
      {children}
    </VideoMeetingChatStateContext.Provider>
  );
};
