import { useRouter } from "next/router";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { UserLanguageContext } from "../../components/layout/LayoutContainer";
import { styled } from "../../styles/theme/themeHelper";
import { ArrowDownIcon, LanguageIcon } from "./appIcon";

const StyledDropdownToggle = styled(
  ({ backgroundColor, fontColor, outline, ...props }) => (
    <DropdownToggle {...props} />
  )
)<AppLanguageSelectorStyleProps>`
  align-items: center;
  border-color: transparent;
  display: flex;
  justify-content: space-around;
  float: right;
  font-size: 14px;
  padding: 0.2rem;
  min-width: 75px;
  text-transform: uppercase;

  &,
  &:hover,
  &:active {
    background-color: ${(props) => props.backgroundColor} !important;
    border-color: ${(props) =>
      props.outline ? props.fontColor : "transparent"} !important;
    color: ${(props) => props.fontColor} !important;
  }

  & > div {
    top: -5px;
    margin-right: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 40px;
    ${(props) => (props.theme.isMobile ? "width: 40px;" : "")}
  }
`;

const StyledDropdownMenu = styled(DropdownMenu)<{ backgroundColor: string }>`
  min-width: 75px;
  overflow: hidden;
  background: ${(props) => props.backgroundColor};
  padding: 0px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26);
  border: none;
`;

const StyledDropdownItem = styled(
  ({ outline, backgroundColor, fontColor, ...props }) => (
    <DropdownItem {...props} />
  )
)<AppLanguageSelectorStyleProps>`
  text-align: center;
  padding: 0.25rem 1.2rem;
  text-transform: uppercase;
  color: ${(props) => props.fontColor};

  &:active,
  &.active,
  &:hover,
  &.hover {
    // Invert colors when showing with outline
    background-color: ${(props) =>
      props.outline ? props.fontColor : props.backgroundColor} !important;
    color: ${(props) =>
      props.outline
        ? props.backgroundColor
        : props.theme.body.font.secondaryTextColor};
  }
`;

export interface AppLanguageSelectorStyleProps {
  backgroundColor: string;
  fontColor: string;
  outline?: boolean;
  hide?: boolean;
}

export interface AppLanguageSelectorProps {
  availableLanguages?: string[] | void;
  currentLanguage?: string;
  onLanguageSelection?: (language: string) => void;
}

type Props = AppLanguageSelectorProps & AppLanguageSelectorStyleProps;

export const AppLanguageSelector: React.FunctionComponent<Props> = ({
  availableLanguages,
  currentLanguage,
  onLanguageSelection,
  backgroundColor,
  fontColor,
  outline,
  hide = false,
}: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [showDropdown, setShowDropdown] = React.useState(!hide);

  const getLanguageFromIso2 = (lang: string) =>
    new Intl.DisplayNames([lang.toUpperCase()], { type: "language" }).of(
      lang.toUpperCase()
    );

  const { language, setLanguage, setPreferredLanguage, forceLanguage } =
    useContext(UserLanguageContext);

  React.useEffect(() => {
    setShowDropdown(forceLanguage ? false : !hide);
  }, [hide, forceLanguage]);

  const selectLanguage = (lang: string) => {
    if (onLanguageSelection && setLanguage) {
      onLanguageSelection(lang);
      setLanguage(lang);
    }
  };

  const onCallBack = (lang?: string, show?: boolean) => {
    if (onLanguageSelection && lang) {
      onLanguageSelection(lang);
    }
    if (show !== undefined) {
      setShowDropdown(show);
    }
  };

  React.useEffect(() => {
    if (availableLanguages && !language && setPreferredLanguage) {
      setPreferredLanguage(
        availableLanguages,
        currentLanguage,
        router && String(router.query.lang),
        onCallBack
      );
    }
  }, [availableLanguages && !availableLanguages.length]);

  return showDropdown &&
    (availableLanguages || []).length > 1 &&
    onLanguageSelection ? (
    <UncontrolledDropdown
      id="language-selector"
      aria-label={t("ariaLabelLanguageSelector", {
        defaultValue: "Language selector dropdown",
      })}
    >
      <StyledDropdownToggle
        backgroundColor={backgroundColor}
        fontColor={fontColor}
        outline={!!outline}
      >
        <LanguageIcon color={fontColor} size={18} />
        <span>&nbsp;{currentLanguage}&nbsp;</span>
        <ArrowDownIcon color={fontColor} size={12} />
      </StyledDropdownToggle>

      <StyledDropdownMenu
        backgroundColor={backgroundColor}
        role="listbox"
        right={true}
      >
        {currentLanguage &&
          !!availableLanguages &&
          availableLanguages.map((lang, index) =>
            currentLanguage.toLowerCase() !== lang.toLowerCase() ? (
              <StyledDropdownItem
                id={`language-${lang}`}
                outline={!!outline}
                onClick={() => selectLanguage(lang)}
                backgroundColor={backgroundColor}
                fontColor={fontColor}
                key={`${index}-${lang}`}
              >
                {getLanguageFromIso2(lang)}
              </StyledDropdownItem>
            ) : (
              <></>
            )
          )}
      </StyledDropdownMenu>
    </UncontrolledDropdown>
  ) : (
    <></>
  );
};
