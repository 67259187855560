import { BookingTypeRoutings } from "./types";

export const SET_BOOKING_TYPE = "SET_BOOKING_TYPE";

export const BOOKING_TYPE_ROUTING: BookingTypeRoutings = {
  inStoreOrVirtual: [
    {
      subtype: "VENUE_FINDER",
      route: "/type/venues",
    },
    {
      subtype: "SERVICE_SELECT",
      route: "/type/products",
    },
  ],
  virtualAggregated: [{ route: "/type/virtual/products" }],
  liveChat: [{ route: "/type/live" }],
};
