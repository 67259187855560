export enum KeyboardKeys {
  SPACE = 0,
  TAB = 9,
  ESCAPE = 27,
  LEFT = 37,
  RIGHT = 39
}

export const isKey = (e: KeyboardEvent, key: KeyboardKeys): boolean =>
  e.keyCode === key;
