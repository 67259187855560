import { Booking, CustomerDetails, Reservation } from "../../shared";
import { ApplicationState } from "../../store";

const getReservation = (state: ApplicationState) => {
  return state.api.get("RESERVATION");
};

export const isLoadingReservation = (state: ApplicationState): boolean => {
  if (!getReservation(state)) {
    return false;
  }

  return getReservation(state).get("isLoading");
};

export const getReservationResponse = (
  state: ApplicationState
): Reservation | void => {
  if (!getReservation(state) || !getReservation(state).get("payload")) {
    return;
  }

  return getReservation(state).getIn(["payload", "response"]);
};

export const getReservationErrorStatus = (
  state: ApplicationState
): number | void => {
  if (!getReservation(state) || !getReservation(state).get("error")) {
    return;
  }

  return getReservation(state).getIn(["error", "status"]);
};

export const getCustomerDetails = (
  state: ApplicationState
): CustomerDetails | void => {
  if (!state.customerDetails.get("details")) {
    return;
  }

  return state.customerDetails.get("details");
};

const getBooking = (state: ApplicationState) => {
  return state.api.get("BOOKING");
};

export const isLoadingBooking = (state: ApplicationState): boolean => {
  if (!getBooking(state)) {
    return false;
  }

  return getBooking(state).get("isLoading");
};

export const getBookingResponse = (
  state: ApplicationState
): Booking | undefined => {
  if (!getBooking(state) || !getBooking(state).get("payload")) {
    return;
  }

  return getBooking(state).getIn(["payload", "response"]);
};
