import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, concatMap, mergeMap } from "rxjs/operators";
// tslint:disable-next-line: ordered-imports
import { SSO_REDIRECT } from "../types";
import { v4 as uuid } from "uuid";
import { ajax } from "rxjs/ajax";
import config from "../../../../config";
import { getBWID } from "../../../utils/getBWID";
import { HTTP_ERROR } from "../../../services/api/constants";
import { goToError } from "../../../utils/pageRouter";
const BOOKER_IDENTIFIER = uuid();

// Key part of the SSO journey, this epic will call the /saml/sso/redirect endpoint
export const ssoRedirectEpic = (action$: any) => {
  return action$.pipe(
    ofType(SSO_REDIRECT),
    mergeMap(() => {
      const headers = {
        "Content-Type": "application/json",
        "X-Booker-Identifier": BOOKER_IDENTIFIER,
      };

      // Build returnUrl
      const BWID = getBWID() || "";
      const urlParts = location.href.split(BWID);
      // The returnUrl need to be encoded as it may contain query parameters
      const returnUrl = urlParts[1] ? encodeURIComponent(urlParts[1]) : "";

      const requestData = {
        withCredentials: true,
        headers,
        method: "GET",
        // The returnUrl value allow us to maintain any deep linking even when using the SSO login
        url: `${config.baseApiUrl}/${BWID}/saml/sso/redirect?returnUrl=${returnUrl}`,
      };

      // Calling the /saml/sso/redirect endpoint to retrieve the SSO sign in url to redirect to
      return ajax(requestData).pipe(
        concatMap((data) => {
          window.location.href = data.response;
          return of({ type: "NONE" });
        }),
        catchError((error) => {
          goToError(true);
          return of({
            error,
            isLoading: false,
            name: `${action$.name}`,
            type: HTTP_ERROR,
          });
        })
      );
    })
  );
};
