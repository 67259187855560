import moment from "moment";
import { getDateTimeLocale } from "./locale";
import humanizeDuration from "humanize-duration";

export const displayTimezone = (timezone: string) => timezone.replace("_", " ");

export const humanizeTime = (timeInMinutes: number) => {
  const locale = getDateTimeLocale().split("_")[0];

  // check if humanizeDuration is supported by current locale
  const supportedLanguage = humanizeDuration
    .getSupportedLanguages()
    .find((lang: string) => lang.startsWith(locale.toLowerCase()));

  if (supportedLanguage) {
    const durationInMilliseconds = timeInMinutes * 60000;
    return humanizeDuration(durationInMilliseconds, {
      language: supportedLanguage
    });
  } else {
    // Ability to round up when using moment.humanize()
    const roundUpDigits = 2;
    moment.relativeTimeRounding(
      (time: number) =>
        Math.round(time * Math.pow(10, roundUpDigits)) /
        Math.pow(10, roundUpDigits)
    );
    moment.relativeTimeThreshold("s", 60);
    moment.relativeTimeThreshold("m", 60);
    moment.relativeTimeThreshold("h", 24);
    moment.relativeTimeThreshold("d", 31);
    moment.relativeTimeThreshold("M", 12);
    moment.relativeTimeThreshold("y", 365);

    return moment.duration(timeInMinutes, "m").humanize();
  }
};
