import { getDateTimeLocale } from "./locale";

export function localizeCurrency(
  value: number,
  currency: string,
  locale?: string
) {
  const currentLocale = locale || getDateTimeLocale();

  const formatter = new Intl.NumberFormat(currentLocale, {
    currency,
    style: "currency"
  });

  return formatter.format(value);
}
