import Router from "next/router";
import { Settings, STEP_NAMES } from "../shared";

export const getParamFromRouter = () => {
  if (process && process.browser) {
    const match = window.location.search.match(/.*[?&]from=(.*)[?&]?/i);

    const param = match && match[1];

    return param || "";
  }

  return "";
};

export const getIdsFromRouter = () => {
  const parts = getParamFromRouter().match(
    /\/?(venues)?\/?(\w*)\/?(queues)?\/?(\w*)\/?(products)?\/?(\w*)\/?(slots)?/
  ) as string[];

  return { venueId: parts[2], queueId: parts[4], productId: parts[6] };
};

export const getFirstPageFromRouter = (
  settings?: Settings,
  hasAddons?: boolean
) => {
  const { venueId, queueId, productId } = getIdsFromRouter();

  const isLiveChatProducts =
    Router.router?.query.from === "/live" ||
    window.location.pathname.split("/")[2] === "live";

  const isProductsBeforeStore =
    Router.router?.query.from === "/products" ||
    window.location.pathname.split("/")[2] === "products";

  const isVirtualProductsBeforeStore =
    Router.router?.query.from === "/virtual/products" ||
    (window.location.pathname.split("/")[2] === "virtual" &&
      window.location.pathname.split("/")[3] === "products");

  const isVenuesJourney =
    Router.router?.query.from === "/venues" ||
    window.location.pathname.split("/")[2] === "venues";

  const isChooseTypePage =
    Router.router?.query.from?.includes("/type") ||
    window.location.pathname.split("/")[2] === "type";

  if (isProductsBeforeStore) {
    return STEP_NAMES.PRODUCTS_BEFORE_STORE;
  }

  if (isVirtualProductsBeforeStore) {
    return STEP_NAMES.VIRTUAL_PRODUCTS_BEFORE_STORE;
  }

  if (isLiveChatProducts) {
    return STEP_NAMES.LIVE_CHAT_PRODUCTS;
  }

  if (isChooseTypePage) {
    return STEP_NAMES.TYPE;
  }

  if (productId) {
    if (
      settings?.staffOptions.showStaffSelection &&
      !settings?.staffOptions.showStaffSelectionAfterCalendar
    ) {
      return hasAddons ? STEP_NAMES.ADDONS : STEP_NAMES.ADVISORS;
    } else {
      return hasAddons ? STEP_NAMES.ADDONS : STEP_NAMES.SLOTS;
    }
  }

  if (venueId || queueId) {
    return STEP_NAMES.PRODUCTS;
  }

  if (isVenuesJourney) {
    return STEP_NAMES.VENUES;
  }

  return "";
};
