export default {
  background1: "#f3f6f7",
  brand: "#007AFF",
  danger: "#ED020E",
  disabled: "rgba(0,0,0,0.05)",
  modal: "rgba(52,58,64, 0.8)",
  primary: "#6a5eff",
  secondary: "#C5CAD4",
  selected: "#345C90",
  text1: "#0A1433",
  text2: "#748AA6",
  text3: "#C5CAD4",
  warning: "#FFBB3A",
  placeholder: "#777772",
  inputBorder: "#979494",
};
