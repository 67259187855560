import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export const handleRouterChange = () => {
  const router = useRouter();

  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    const handleChangeStart = () => {
      setIsChanging(true);
    };

    const handleChangeComplete = () => {
      setIsChanging(false);
    };

    const handleChangeError = () => {
      setIsChanging(false);
    };

    router.events.on("routeChangeStart", handleChangeStart);
    router.events.on("routeChangeComplete", handleChangeComplete);
    router.events.on("routeChangeError", handleChangeError);

    return () => {
      router.events.off("routeChangeStart", handleChangeStart);
      router.events.off("routeChangeComplete", handleChangeComplete);
      router.events.off("routeChangeError", handleChangeError);
    };
  }, []);

  return isChanging;
};
