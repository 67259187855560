import { Coordinates } from "../../shared";

export const getCoordinatesQueryParams = (coordinates?: Coordinates) => {
  return (
    coordinates &&
    `latitude=${coordinates.latitude}&longitude=${coordinates.longitude}`
  );
};

export const getSingleVenueQueryParams = (by?: string | string[]) => {
  if (by) {
    return `?by=${by}`;
  }
  return "";
};
