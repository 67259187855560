import { ApiLanguages, StepNames } from "../../shared";
import {
  CHANGE_LANGUAGE,
  CLEAR_ALL,
  CLEAR_ADDON,
  CLEAR_ADVISOR,
  CLEAR_PRODUCT,
  SET_FIRST_PAGE,
  SET_LANGUAGES,
  CLEAR_RESERVATION,
  CLEAR_BOOKING,
  CLEAR_COORDINATES,
  CLEAR_VENUE,
  CLEAR_DATEPICKER_SLOTS,
  CLEAR_FIRST_AVAILABLE_SLOT,
  CLEAR_NEARBY_VENUES_SEARCH,
  SSO_REDIRECT,
} from "./types";

export const setLanguages = (apiLanguagesObj: ApiLanguages) => ({
  apiLanguagesObj,
  type: SET_LANGUAGES,
});

export const changeLanguage = (language: string) => ({
  language,
  type: CHANGE_LANGUAGE,
});

export const setFirstPage = (firstPage: StepNames) => ({
  firstPage,
  type: SET_FIRST_PAGE,
});

export const clearAll = () => ({
  type: CLEAR_ALL,
});

export const clearProduct = () => ({
  type: CLEAR_PRODUCT,
});

export const clearAddOn = () => ({
  type: CLEAR_ADDON,
});

export const clearAdvisor = () => ({
  type: CLEAR_ADVISOR,
});

export const clearReservation = () => ({
  type: CLEAR_RESERVATION,
});

export const clearBooking = () => ({
  type: CLEAR_BOOKING,
});

export const clearCoordinates = () => ({
  type: CLEAR_COORDINATES,
});

export const clearVenue = () => ({
  type: CLEAR_VENUE,
});

export const clearDatepickerSlots = () => ({
  type: CLEAR_DATEPICKER_SLOTS,
});

export const clearFirstAvailableSlot = () => ({
  type: CLEAR_FIRST_AVAILABLE_SLOT,
});

export const clearNearbyVenuesSearch = () => ({
  type: CLEAR_NEARBY_VENUES_SEARCH,
});

export const ssoRedirect = () => ({
  type: SSO_REDIRECT,
});
