import { Theme } from "../../shared";

// tslint:disable:object-literal-sort-keys
export class ThemeFixture {
  private settings: Theme = {
    logo: "/static/images/verint-logo-white.png",
    header: {
      backgroundColor: "#007AFF",
    },
    body: {
      backgroundColor: "#ffffff",
      font: {
        family: "Ubuntu",
        mainTextColor: "#333333",
        secondaryTextColor: "#666666",
        tertiaryTextColor: "#DBDBDB",
      },
      buttons: {
        mainBackgroundColor: "#007AFF",
        mainTextColor: "#ffffff",
        secondaryBackgroundColor: "#85CBFF",
        secondaryTextColor: "#ffffff",
        productButtonColor: "#333333",
        productIconTextColor: "#666666",
        productSecondaryTextColor: "#9b9b9b",
      },
    },
  };

  private settingsNotAvailable: Theme = {
    logo: undefined,
    header: {
      backgroundColor: "#ecf0f1",
    },
    body: {
      backgroundColor: "#ffffff",
      font: {
        family: "Ubuntu",
        mainTextColor: "#333333",
        secondaryTextColor: "#666666",
        tertiaryTextColor: "#DBDBDB",
      },
      buttons: {
        mainBackgroundColor: "#007AFF",
        mainTextColor: "#ffffff",
        secondaryBackgroundColor: "#85CBFF",
        secondaryTextColor: "#ffffff",
        productButtonColor: "#ffffff",
        productIconTextColor: "#333333",
        productSecondaryTextColor: "#666666",
      },
    },
  };

  public get withDefaultSettings() {
    return this.settings;
  }

  public get withSettingsNotAvailable() {
    return this.settingsNotAvailable;
  }
}
