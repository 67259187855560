import ButterToast from "butter-toast";
import { ofType } from "redux-observable";
import { EMPTY } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { getToastId } from "./selectors";
import { DISMISS_TOAST } from "./types";

export const dismissToastEpic = (action$: any, store: any) => {
  return action$.pipe(
    ofType(DISMISS_TOAST),
    mergeMap(() => {
      const toastId = getToastId(store.value);
      if (toastId) {
        ButterToast.dismiss(toastId);
      }
      return EMPTY;
    })
  );
};
