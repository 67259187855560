import React, { useMemo } from "react";

import { AppParagraph2, TelephoneIcon, Theme } from "../../../shared";
import colors from "../../../styles/theme/colors";
import CountryData from "react-intl-tel-input/dist/components/AllCountries";
import { Country } from "../../customerDetails/components/countryDropdown";
import styled from "styled-components";
import { withTheme } from "../../../styles/theme/themeHelper";

interface Props {
  phoneNumber: string;
  defaultCountryCode: string;
}

const StyledLink = styled.a`
  color: inherit;
`;

const VenuePhoneNumberBase: React.FC<Props & { theme: Theme }> = ({
  phoneNumber,
  defaultCountryCode,
}) => {
  const countries: Country[] = CountryData.getCountries();
  const venueCountry = useMemo(
    () => countries.find((country) => country.iso2 === defaultCountryCode),
    [countries, defaultCountryCode]
  );

  const completePhoneNumber = `${
    venueCountry ? "+" + venueCountry.dialCode : ""
  }${phoneNumber}`;

  return (
    <>
      <hr />
      <div className="d-flex mt-3 d-inline-block">
        <div className="mr-2 mt-1 flex-grow-0 flex-shrink-0">
          <TelephoneIcon size={18} color={colors.text2} />
        </div>
        <div className="mr-2 flex-grow-1 flex-shrink-1 w-100">
          <AppParagraph2 className="color-text1" style={{ lineHeight: "24px" }}>
            <StyledLink href={`tel:${completePhoneNumber}`} id="phone-number">
              {phoneNumber}
            </StyledLink>
          </AppParagraph2>
        </div>
      </div>
    </>
  );
};

export const VenuePhoneNumber = withTheme(VenuePhoneNumberBase);
