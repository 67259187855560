import { Coordinates, DistanceUnit } from "../shared";

const R = 6371 * 1000; // Radius of earth in meters (rounded to match backend implementation)

const squared = (x: number) => x * x;
const toRad = (x: number) => (x * Math.PI) / 180.0;
const hav = (x: number) => squared(Math.sin(x / 2));

// Return distance using Haversine formula
export const getDistance = (
  a: Coordinates,
  b: Coordinates,
  unit: DistanceUnit
): number => {
  const aLat = toRad(a.latitude);
  const bLat = toRad(b.latitude);
  const aLng = toRad(a.longitude);
  const bLng = toRad(b.longitude);
  const ht =
    hav(bLat - aLat) + Math.cos(aLat) * Math.cos(bLat) * hav(bLng - aLng);
  const distanceInMeters = 2 * R * Math.asin(Math.sqrt(ht));

  switch (unit) {
    case DistanceUnit.MILES:
      return distanceInMeters / 1609;
    case DistanceUnit.KMS:
      return distanceInMeters / 1000;
    default:
      return distanceInMeters;
  }
};
